import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    width: "80%",
    noWrap: true,
    backgroundColor: "#ffffff",
    fontFamily: themes.fonts.primaryFontRegular,
    "@media (max-width:1024px)": {
      width: "80%",
    },

    "@media (max-width:700px)": {
      margin: "20px 30px",
      width: "auto",
      textAlign: "left",
    },
  },
  image: {
    width: 200,
    height: 200,
    paddingLeft: "20px",
    paddingTop: "4px",
    "@media (max-width:700px)": {
      height: "unset",
      maxHeight: 200,
    },
  },
  img: {
    display: "block",
    maxWidth: "100%",
    maxHeight: 200,
    borderRadius: "2.5%",
    justify: "center",
    margin: "auto",
    "@media (max-width:700px)": {
      margin: 0,
    },
  },
  list: {
    listStyleType: "none",
    marginRight: "5px",
    fontSize: "16px",
  },
  button: {
    marginTop: "1rem",
    fontSize: "14px",
    backgroundColor: COLORS.BTN_GREEN,
    textAlign: "left",
    "&:hover": { backgroundColor: COLORS.BTN_GREEN },
  },
  name: {
    fontFamily: "openSans-SemiBold",
    src: "../../assets/fonts/OpenSans-SemiBold.ttf",
    padding: "5px",
    paddingLeft: "0",
    fontSize: "25px",
    color: "#293461",
    "@media (max-width:700px)": {
      fontSize: "18px",
    },
  },
  padder: {
    paddingTop: "5px",
  },
  lastPosition: {
    color: "gray",
    paddingTop: "5px",
  },
  container: {
    margin: "25px",
  },
}));

function ProfileCard(props) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <div className={classes.image}>
            <img
              className={classes.img}
              // src={require("../../../../public/profileImages/" + props.image)}
              src={props.image}
              alt={props.name}
            ></img>
          </div>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <div className={classes.container}>
              <Grid item xs>
                <h2 className={classes.name}> {props.name}</h2>
                <div className={classes.list}>
                  <div className={classes.lastPosition}>
                  {props.lastHeldPosition ? props.lastHeldPosition.length < 150
                      ? props.lastHeldPosition
                      : props.lastHeldPosition.substring(0, 150) + "...":""}
                  </div>
                  <div className={classes.padder}>
                    <b>Qualification:</b>{" "}
                    {props.qualification ? props.qualification.length < 65
                      ? props.qualification
                      : props.qualification.substring(0, 65) + "..." : ""}{" "}
                  </div>
                  <div className={classes.padder}>
                    <b>Experience:</b> {props.yearsOfExperience} years
                  </div>
                  <div className={classes.padder}>
                    <b>Languages Known: </b>
                    {props.languagesKnown && typeof props.languagesKnown !="string" ? props.languagesKnown.join(", ") : props.languagesKnown}
                    {/* {props.languagesKnown.join(", ")} */}
                  </div>
                  {props.location && (
                    <div className={classes.padder}>
                      <b>Location: </b>
                      {props.location}
                    </div>
                  )}
                  <div className={classes.padder}>
                    <b>Affiliated Organization: </b>
                    {props.affiliatedOrganization}
                  </div>
                  {props.areasOfExpertise && (
                    <div className={classes.padder}>
                      <b>Specialities: </b>
                      {props.areasOfExpertise ? props.areasOfExpertise.length < 150
                        ? props.areasOfExpertise
                        : props.areasOfExpertise.substring(0, 150) + "..." : ""}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  href={"/neutral/" + props.neutralType + "/" + props._id}
                  name={props._id}
                >
                  See More
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProfileCard;
