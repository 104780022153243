import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddDocBundleDrawer from "./AddDocumentBundle";
import useNotification from "../../../hooks/useNotification";
import { Tooltip } from "@material-ui/core";

const Index = ({ id, setRefreshDetails, refreshDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bundleName, setBundleName] = useState();
  const caseId = id;
  const { triggerNotify, setTriggerNotify } = useNotification();

  useEffect(() => {
    if (triggerNotify) {
      setRefreshDetails(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  useEffect(() => {
    async function getBundleName() {
      try {
        const response_data = await CaseService.getBundleName(caseId);
        if (response_data) {
          setBundleName(response_data?.bundleDetails);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
    if (refreshDetails) {
      setRefreshDetails(false);
      getBundleName();
    }
  }, [refreshDetails]);

  return (
    <Table {...{ caseId, bundleName, setRefreshDetails, refreshDetails }} />
  );
};

export default Index;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Table = ({ caseId, bundleName, setRefreshDetails, refreshDetails }) => {
  return (
    <div>
      {bundleName
        ? bundleName.map((item, index) => (
            <TableWithAccordion
              key={index}
              title={item?.bundleName}
              id={item?.id}
              caseId={caseId}
              bundleDocs={item?.bundleDocuments}
              setRefreshDetails={setRefreshDetails}
              refreshDetails={refreshDetails}
            />
          ))
        : ""}
    </div>
  );
};

function TableWithAccordion({
  MTRef,
  id,
  title,
  bundleDocs,
  caseId,
  refreshDetails,
  setRefreshDetails
}) {
  const classes = useStyles();
  const [openBundleModal, setOpenBundleModal] = useState(false);
  const [docName, setDocName] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    async function getBundleName() {
      try {
        const response_data = await CaseService.getDocumentList(caseId, id);
        if (response_data) {
          setDocName(response_data?.documentList);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
      }
    }
    getBundleName();
  }, []);
  return (
    <>
      <TableWrapper>
        <Accordion className={classes.root} expanded={expanded}>
          <AccordionSummary
            style={{ marginRight: "20px" }}
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setExpanded(e => !e)}
                expanded={expanded}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordionHeading
              style={{ marginLeft: 24 }}
              onClick={() => setExpanded(e => !e)}
            >
              {title}
            </AccordionHeading>
            {docName?.length ? (
              <Actions>
                <HyperLink onClick={() => setOpenBundleModal(true)}>
                  Add Document
                </HyperLink>
              </Actions>
            ) : (
              ""
            )}
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                paddingLeft: "25px",
                paddingBottom: "10px",
                paddingTop: "auto",
                overflow: "auto"
              }}
            >
              {bundleDocs?.length
                ? bundleDocs?.map((data, index) => (
                    <div key={index}>
                      <Tooltip
                        title={decodeURI(
                          data?.url?.url?.split("/")[
                            data?.url?.url?.split("/").length - 1
                          ]
                        )}
                        placement="top"
                      >
                        <H2
                          style={{
                            marginBottom: "15px",
                            color: COLORS.BTN_GREEN
                          }}
                          onClick={() => window.open(data?.url?.url)}
                        >
                          {data?.url?.url?.split("/").length > 0
                            ? decodeURI(
                                data?.url?.url?.split("/")[
                                  data?.url?.url?.split("/").length - 1
                                ]
                              )
                            : "-"}{" "}
                        </H2>
                      </Tooltip>
                    </div>
                  ))
                : ""}
            </div>
          </AccordionDetails>
        </Accordion>
      </TableWrapper>
      <AddDocBundleDrawer
        id={id}
        caseId={caseId}
        bundleName={title}
        docName={docName}
        bundleDocs={bundleDocs}
        {...{
          openBundleModal,
          setOpenBundleModal,
          setRefreshDetails,
          refreshDetails
        }}
        heading="Enter Bundle Name"
        btnText="Enter Bundle Name"
      />
    </>
  );
}

export const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionHeadingMobile = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  @media (min-width: 780px) {
    //width: 200px;
    width: auto;
    min-width: 100px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const TableWrapper = styled.div`
  // border: 2px solid black;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
  &:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0 21px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 6px 0 7px 0;
    padding: 0 21px 0 21px;
    &:nth-child(2) {
      margin-top: 9px;
    }
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
