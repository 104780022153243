import React from "react";
import {
  FooterWrapper,
  LogoWrapper,
  Copyrights,
} from "./Styles";
import { Link } from "react-scroll";

const Footer = () => {

  // const Links = [
  //   {
  //     label: "About Us",
  //     url: "aboutus",
  //     isWithinLanding: true,
  //   },
  //   {
  //     label: "Legal",
  //     url: "/",
  //     isWithinLanding: false,
  //   },
  //   {
  //     label: "Terms & Conditions",
  //     url: "T&C",
  //     isWithinLanding: false,
  //   },
  //   {
  //     label: "Privacy Policy",
  //     url: "/",
  //     isWithinLanding: false,
  //   },
  //   {
  //     label: "Partnerships",
  //     url: "/",
  //     isWithinLanding: false,
  //   },
  //   {
  //     label: "Contact Us",
  //     url: "contact_us",
  //     isWithinLanding: true,
  //   },
  // ];

  // const navigateToLink = ({ url, isWithinLanding }) => {
  //   if (isWithinLanding) {
  //     scroller.scrollTo(url, {
  //       duration: 500,
  //       offset: -70,
  //       smooth: true,
  //     });
  //   } else {
  //     setState(url);
  //   }
  // };

  return (
    <FooterWrapper>
      <Link to="hero" spy={true} smooth={true} offset={-70} duration={500}>
        <LogoWrapper>
          {/* <CompanyLogoWhite src={Images.companyLogoWhite} /> */}
        </LogoWrapper>
      </Link>
      {/* <NavLinks>
        {Links.map((link, index) => {
          return (
            <NavLink key={index} onClick={() => navigateToLink(link)}>
              {link.label}
            </NavLink>
          );
        })}
      </NavLinks> */}
      <Copyrights>
        Powered by JustAct<br/>
        Copyright © {new Date().getFullYear()} Lex Carta Private Limited <br/>
        All Rights Reserved.<br/>
        <a style={{color:"white"}} href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a><br/>
        <a style={{color:"white"}} href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </Copyrights>
      
    </FooterWrapper>
  );
};

export default Footer;
