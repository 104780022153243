import React, { Fragment, useState } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import { navigate } from "@reach/router";
import {
  Container,
  Heading,
  Subheading,
  FormContainer,
  FormControl,
  StyledLabel,
  Row,
  SuperContainer
} from "./styles";
import { Formik } from "formik";
import { CustomRadioGroup, CustomFileUpload } from "../../common/FormInputs";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton
} from "../../common/Buttons";
import AddressFormFields from "./common/AddressFormFields";
import BillingAddressFormFields from "./common/BillingAddressFormFields";
import { createPartyValidationSchema } from "../../../helpers/validationSchema";
import { getFileName } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { draftParty, createCaseParty, getPartyOrDraft } from "./API";
import AWSService from "../../../services/AWSService";
import useLoader from "../../../hooks/useLoader";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useUser from "../../../hooks/useUser";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";

const typeInputs = [
  { label: labels["party.self"], value: "individual" },
  { label: labels["party.organisation"], value: "organization" },
  { label: labels["party.others"], value: "others" }
];

const documentRadio = [
  { label: labels["party.pan"], value: "pan" },
  { label: labels["party.aadhaar"], value: "aadhaar" },
  { label: labels["party.voter"], value: "voter" },
  { label: labels["party.passport"], value: "passport" },
  { label: labels["party.cin"], value: "cin" }
];

const validFormat = /(?:image|pdf)/;

export default function CreateParty({ draftId, resolutionKind }) {
  const breadcrumbs = [_.startCase(labels.my_parties), labels.create_party];
  const { enqueueSnackbar } = useSnackbar();
  const [draft, setDraft] = useState({});
  const { setLoader } = useLoader();
  const { userObject } = useUser();
  const [data, setData] = useState("");

  const filterRadio = (doc, values) => {
    const isCin = values.kind === "organization";
    if (isCin) {
      if (doc.value === "pan" || doc.value === "cin") {
        return true;
      }
    } else if (doc.value !== "cin") {
      return true;
    }
  };

  useEffect(() => {
    if (draftId) {
      getPartyOrDraft(draftId, setDraft, enqueueSnackbar, setLoader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_parties:
        navigate("/dashboard/parties");
        break;
      default:
        break;
    }
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        const errorMessage = "Invalid File Type";
        if (!validFormat.test(file?.type)) throw errorMessage;
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Upload was successful", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  function prefillValues(e, setFieldValue) {
    setFieldValue("kind", e.target.value);
    setFieldValue("document_kind", "pan");
    if (e.target.value === "individual" && !draftId) {
      setFieldValue("name", userObject.name || "");
      setFieldValue("document_house_no", userObject.houseNumber || "");
      setFieldValue("document_landmark", userObject.landmark || "");
      setFieldValue("document_locality", userObject.locality || "");
      setFieldValue("document_state", userObject.state || "");
      setFieldValue("document_city", userObject.city || "");
      setFieldValue("document_pin", userObject.pin || "");
    } else if (!draftId) {
      setFieldValue("name", "");
      setFieldValue("document_house_no", "");
      setFieldValue("document_landmark", "");
      setFieldValue("document_locality", "");
      setFieldValue("document_state", "");
      setFieldValue("document_city", "");
      setFieldValue("document_pin", "");
    }
  }

  function billingprefillValues(e, setFieldValue, values) {
    setFieldValue("billingAddressType", e.target.value);
    if (e.target.value === "same" && !draftId) {
      setFieldValue("document_billing_house_no", data?.document_house_no || "");
      setFieldValue("document_billing_landmark", data?.document_landmark || "");
      setFieldValue("document_billing_locality", data?.document_locality || "");
      setFieldValue("document_billing_state", data?.document_state || "");
      setFieldValue("document_billing_city", data?.document_city || "");
      setFieldValue("document_billing_pin", data?.document_pin || "");
    } else if (!draftId) {
      setFieldValue("document_billing_house_no", "");
      setFieldValue("document_billing_landmark", "");
      setFieldValue("document_billing_locality", "");
      setFieldValue("document_billing_state", "");
      setFieldValue("document_billing_city", "");
      setFieldValue("document_billing_pin", "");
    } else if (e.target.value === "same" && draftId) {
      setFieldValue("document_billing_house_no", data?.document_house_no || "");
      setFieldValue("document_billing_landmark", data?.document_landmark || "");
      setFieldValue("document_billing_locality", data?.document_locality || "");
      setFieldValue("document_billing_state", data?.document_state || "");
      setFieldValue("document_billing_city", data?.document_city || "");
      setFieldValue("document_billing_pin", data?.document_pin || "");
    } else if (draftId) {
      setFieldValue("document_billing_house_no", "");
      setFieldValue("document_billing_landmark", "");
      setFieldValue("document_billing_locality", "");
      setFieldValue("document_billing_state", "");
      setFieldValue("document_billing_city", "");
      setFieldValue("document_billing_pin", "");
    }
  }

  function onFormSubmit(values, isDraft = false) {
    const {
      name,
      kind,
      billingAddressType,
      document_kind,
      doc_number,
      document_uri,
      document_house_no,
      document_locality,
      document_city,
      document_state,
      document_pin,
      document_landmark,
      gstNumber,
      gstCertUrl,
      authorizationLetterUrl,
      document_billing_house_no,
      document_billing_locality,
      document_billing_city,
      document_billing_state,
      document_billing_pin,
      document_billing_landmark
    } = values;

    let payload = {
      name,
      kind,
      billingAddressType,
      documentKind: document_kind === "aadhaar" ? "aadhar" : document_kind,
      houseNumber: document_house_no,
      locality: document_locality,
      city: document_city,
      state: document_state,
      pin: document_pin,
      landmark: document_landmark,
      documentNumber: doc_number,
      documentUrl: document_uri,
      gstNumber: gstNumber,
      gstCertUrl: gstCertUrl,
      authorizationLetterUrl: authorizationLetterUrl,
      billingHouseNumber: document_billing_house_no,
      billingLocality: document_billing_locality,
      billingCity: document_billing_city,
      billingState: document_billing_state,
      billingPin: document_billing_pin,
      billingLandmark: document_billing_landmark
    };
    if (isDraft && typeof isDraft === "boolean") {
      draftParty(payload, enqueueSnackbar, draftId, setLoader);
    } else {
      createCaseParty(payload, enqueueSnackbar, draftId, resolutionKind, setLoader);
    }
  }

  return (
    <SuperContainer>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container>
        <Heading> {draftId ? "Edit Draft" : labels.create_party}</Heading>
        <Subheading
          dangerouslySetInnerHTML={{ __html: labels["party.subheading"] }}
        />
        <Formik
          initialValues={{
            kind: draft.kind || "individual",
            party_for: "myself",
            name: draft.name || userObject.name || "",
            document_kind: draft.documentKind || "pan",
            billingAddressType: draft.billingAddressType || "new",
            doc_number: draft.documentNumber,
            document_house_no:
              draft.houseNumber || userObject.houseNumber || "",
            document_locality: draft.locality || userObject.locality || "",
            document_city: draft.city || userObject.city || "",
            document_state: draft.state
              ? draft.state
              : userObject.state
              ? userObject.state
              : "AN",
            document_pin: draft.pin || userObject.pin || "",
            document_landmark: draft.landmark || userObject.landmark || "",
            document_uri: draft.documentUrl,
            gstNumber: draft.gstNumber || "",
            gstCertUrl: draft.gstCertUrl || "",
            authorizationLetterUrl: draft.authorizationLetterUrl || "",
            document_billing_house_no: draft.billingHouseNumber || "",
            document_billing_locality: draft.billingLocality || "",
            document_billing_city: draft.billingCity || "",
            document_billing_state: draft.billingState || "",
            document_billing_pin: draft.billingPin || "",
            document_billing_landmark: draft.billingLandmark || ""
          }}
          enableReinitialize
          validationSchema={createPartyValidationSchema}
          validateOnBlur
          validateOnChange
          onSubmit={onFormSubmit}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldValue,
            setFieldError,
            resetForm,
            handleBlur,
            setFieldTouched
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormControl>
                <CustomRadioGroup
                  name={"kind"}
                  radioInputs={typeInputs}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    resetForm();
                    setFieldValue("document_uri", "");
                    setFieldValue("doc_number", "");
                    prefillValues(e, setFieldValue);
                  }}
                  {...{ values }}
                />
              </FormControl>
              <FormControl>
                <StyledLabel tooltip="Name of the Party">
                  {labels["party.name_label"]}{" "}
                  <span className="required-star"> *</span>
                </StyledLabel>
                <FormField
                  white
                  input={{ type: "text", name: "name", label: null }}
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur
                  }}
                />
              </FormControl>
              <div
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  color: COLORS.COLOR_DARK,
                  fontFamily: theme.fonts.primaryFontBold,
                  marginBottom: 10
                }}
              >
                {labels["user.address"]}
              </div>
              <AddressFormFields
                type={"document"}
                {...{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }}
              />
              <div
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  color: COLORS.COLOR_DARK,
                  fontFamily: theme.fonts.primaryFontBold,
                  marginBottom: 10
                }}
              >
                {labels["user.billing_address"]}
              </div>
              <FormControl>
                <CustomRadioGroup
                  radioInputs={[
                    {
                      label: "Billing address is same as residential address",
                      value: "same"
                    },
                    {
                      label:
                        "Billing address is different from residential address",
                      value: "new"
                    }
                  ]}
                  name={"billingAddressType"}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    billingprefillValues(e, setFieldValue);
                  }}
                  {...{ values }}
                />
                <BillingAddressFormFields
                  type={"document"}
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur
                  }}
                />
              </FormControl>
              <div>{setData(values)}</div>
              <FormControl className="radio-group">
                <StyledLabel spacing tooltip="Type of document">
                  {labels["party.typeOfDocument"]}
                </StyledLabel>
                <CustomRadioGroup
                  radioInputs={documentRadio.filter((doc) =>
                    filterRadio(doc, values)
                  )}
                  handleChange={(e) => {
                    setFieldTouched("doc_number", false);
                    setFieldValue("document_uri", "");
                    setFieldValue("doc_number", "");
                    setFieldValue("document_kind", e.target.value);
                  }}
                  name={"document_kind"}
                  {...{ values }}
                />
              </FormControl>

              <FormControl className="disabledArrows" style={{ marginTop: 10 }}>
                <FormField
                  white
                  input={{
                    type: "text",
                    name: `doc_number`,
                    label: `${
                      values?.document_kind === "voter"
                        ? labels["party.voter"]
                        : values?.document_kind === "pan" ||
                          values?.document_kind === "cin"
                        ? _.upperCase(values?.document_kind)
                        : _.capitalize(values?.document_kind)
                    }`,
                    required: true
                  }}
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur
                  }}
                />
              </FormControl>

              <FormControl>
                <StyledLabel spacing>
                  {labels.upload}{" "}
                  {values?.document_kind === "voter"
                    ? labels["party.voter"]
                    : values?.document_kind === "pan" ||
                      values?.document_kind === "cin"
                    ? _.upperCase(values?.document_kind)
                    : _.capitalize(values?.document_kind)}{" "}
                  {labels.pdf_image_only}
                  <span className="required-star"> *</span>
                </StyledLabel>
                <CustomFileUpload
                  style={{ width: "100%" }}
                  key={`${values.document_kind} ${values.kind}`}
                  error={touched.document_uri && errors.document_uri}
                  file={{ name: getFileName(values?.document_uri) }}
                  setFile={(f) =>
                    s3Upload(f, "document_uri", setFieldValue, setFieldError)
                  }
                  accept=".pdf, image/*"
                />
              </FormControl>
              <FormControl>
                <StyledLabel spacing>
                  {labels.upload} {labels["party.authorisation_letter"]}{" "}
                  {" (optional)"}
                </StyledLabel>
                <CustomFileUpload
                  style={{ width: "100%" }}
                  key={`${values.authorizationLetterUrl}`}
                  file={{ name: getFileName(values?.authorizationLetterUrl) }}
                  setFile={(f) =>
                    s3Upload(
                      f,
                      "authorizationLetterUrl",
                      setFieldValue,
                      setFieldError
                    )
                  }
                  accept=".pdf, image/*"
                />
              </FormControl>
              <FormControl className="disabledArrows" style={{ marginTop: 10 }}>
                <FormField
                  white
                  input={{
                    type: "text",
                    name: `gstNumber`,
                    label: `${labels["party.gstNumber"]} (Mandatory, if you need your GST number to be mentioned in the invoice)`
                  }}
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur
                  }}
                />
              </FormControl>
              {values.gstNumber.length ? (
                <FormControl>
                  <StyledLabel spacing>
                    {labels.upload} {labels["party.gstcertification"]}{" "}
                    {labels.pdf_image_only} {" (Mandatory)"}
                    <span className="required-star"> *</span>
                  </StyledLabel>
                  <CustomFileUpload
                    style={{ width: "100%" }}
                    key={`${values.gstCertUrl}`}
                    error={touched.gstCertUrl && errors.gstCertUrl}
                    file={{ name: getFileName(values?.gstCertUrl) }}
                    setFile={(f) =>
                      s3Upload(f, "gstCertUrl", setFieldValue, setFieldError)
                    }
                    accept=".pdf, image/*"
                  />
                </FormControl>
              ) : (
                <FormControl>
                  <StyledLabel spacing>
                    {labels.upload} {labels["party.gstcertification"]}{" "}
                    {labels.pdf_image_only} {" (optional)"}
                  </StyledLabel>
                  <CustomFileUpload
                    style={{ width: "100%" }}
                    key={`${values.gstCertUrl}`}
                    file={{ name: getFileName(values?.gstCertUrl) }}
                    setFile={(f) =>
                      s3Upload(f, "gstCertUrl", setFieldValue, setFieldError)
                    }
                    accept=".pdf, image/*"
                  />
                </FormControl>
              )}
              <FormControl>
                <Row>
                  <PrimaryOutlinedCTAButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      onFormSubmit(values, true);
                    }}
                    style={{ width: "47%" }}
                  >
                    {labels["buttons.save_draft"]}
                  </PrimaryOutlinedCTAButton>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "47%" }}
                  >
                    {labels.proceed}
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </SuperContainer>
  );
}
