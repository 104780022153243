import React from "react";
import { CustomRadioGroup } from "../../common/FormInputs";
import {
  StyledFormElementsWrapper,
  StyledFormElementsWrapper1,
  StyledLabel
} from "../../internal/CreateCase/Styles";

const CaseTypeRadioButton = ({ values, handleChange, resolutionKind }) => {
  return (
    <>
      <StyledFormElementsWrapper1 style={{ marginBottom: 15, marginTop: 15 }}>
        <StyledLabel>Select a ResolutionKind</StyledLabel>
        <CustomRadioGroup
          radioInputs={[
            { label: "Mediation", value: "mediation" },
            { label: "Arbitration", value: "arbitration" }
          ]}
          name={"resolutionKind"}
          handleChange={e => handleChange(e, "resolutionKind")}
          {...{ values }}
        />
      </StyledFormElementsWrapper1>
    </>
  );
};

export default CaseTypeRadioButton;
