import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CustomCheckbox, CustomFileUpload } from "../FormInputs";
import { getFileName } from "../../../helpers/functions";
import { CheckboxContainer } from "../../internal/CreateCase2/Styles";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import { caseDocumentType } from "../../../helpers/constants";
import CustomSelect from "../CustomSelect/CustomSelect";

export default function DocumentUploadModal({
  modal,
  setModal,
  handleUpload = () => {},
  url = "",
  setUrl = () => {},
  handleSuccess = () => {},
  file,
  fileRef,
  shareDefault = false,
  shareDefaultMed = false
}) {
  const [checked, setChecked] = useState();
  const [checkedme, setCheckedme] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    if (modal) {
      setChecked(shareDefault);
      setCheckedme(shareDefaultMed);
      setUrl("");
      setType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, shareDefault, shareDefaultMed]);
  return (
    <Modal open={modal}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Add Document</Heading>
            <CloseModal
              onClick={() => setModal(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            {shareDefault && (
              <div style={{ marginBottom: 10, width: "100%" }}>
                <Label>Document Type</Label>
                <CustomSelect
                  white={true}
                  id={"uploadtype"}
                  menuItemValues={caseDocumentType}
                  name={"uploadtype"}
                  value={type}
                  onChange={e => setType(e?.target?.value)}
                  style={{ marginTop: 6 }}
                />
              </div>
            )}
            <div>
              <Label>Document</Label>
              <CustomFileUpload
                error={false}
                setFile={file => handleUpload(file)}
                style={{ marginTop: 6, width: "100%" }}
                file={{ name: getFileName(url) }}
                value={file}
                ref={fileRef}
                type="file"
                accept=".pdf, .mp3, .mp4, .docx, .mov, image/*"
                // accept=".pdf"
              />
            </div>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checked}
                onChange={e =>
                  setChecked(e?.target?.checked) || setCheckedme(false)
                }
              />
              <CheckBoxLabel for="secretDoc">
                {"Share with other parties, CM and Neutral"}
              </CheckBoxLabel>
            </CheckboxContainer>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checkedme}
                onChange={e =>
                  setCheckedme(e?.target?.checked) || setChecked(false)
                }
              />
              <CheckBoxLabel for="secretDoc">
                {"Share with CM and Neutral"}
              </CheckBoxLabel>
            </CheckboxContainer>
            {/* {checkedme ? (
              <CheckboxContainer style={{ marginTop: 6 }}>
                <CustomCheckbox
                  checked={checked}
                  disabled="true"
                  onChange={e => setChecked(e?.target?.checked)}
                />
                <CheckBoxLabel for="secretDoc">Make Public</CheckBoxLabel>
              </CheckboxContainer>
            ) : (
              <CheckboxContainer style={{ marginTop: 6 }}>
                <CustomCheckbox
                  // id="secretDoc"
                  checked={checked}
                  onChange={e => setChecked(e?.target?.checked)}
                />
                <CheckBoxLabel for="secretDoc">Make Public</CheckBoxLabel>
              </CheckboxContainer>
            )} */}
            {/* {checked ? (
              <CheckboxContainer style={{ marginTop: 6 }}>
                <CustomCheckbox
                  // id="secretDoc"
                  disabled="true"
                  checked={checkedme}
                  onChange={e => setCheckedme(e?.target?.checked)}
                />
                <CheckBoxLabel for="secretDoc">
                  Share with CM and Neutral
                </CheckBoxLabel>
              </CheckboxContainer>
            ) : (
              <CheckboxContainer style={{ marginTop: 6 }}>
                <CustomCheckbox
                  // id="secretDoc"
                  checked={checkedme}
                  onChange={e => setCheckedme(e?.target?.checked)}
                />
                <CheckBoxLabel for="secretDoc">
                  Share with CM and Neutral
                </CheckBoxLabel>
              </CheckboxContainer>
            )} */}
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={e => {
                  e.preventDefault();
                  setModal(false);
                  setUrl("");
                }}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                disabled={!url || (shareDefault && !type)}
                style={{ width: "45%" }}
                onClick={e => {
                  e.preventDefault();
                  handleSuccess(checked, checkedme, type);
                }}
              >
                Upload Document
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.COLOR_DARK};
`;

const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;
