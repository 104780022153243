import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";
import theme from "../../../assets/theme";
import labels from "../../../helpers/labels.json";
import { useState } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import PaymentHistory from "../PaymentHistory";
import ProfomaHistory from "../ProformaHistory";
import { useLocation } from "@reach/router";
import { useMediaQuery } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledText style={{ height: "100%" }}>{children}</StyledText>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledText = styled.article`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  overflow: auto;
`;

const useStyles = makeStyles((themes) => ({
  root: {
    margin: 15,
    [themes.breakpoints.up(640)]: {
      margin: "30px 46px",
    },
    "& .MuiTab-root": {
      padding: "5px 0",
      [themes.breakpoints.up(640)]: {
        padding: "6px 12px",
      },
    },
    "& .MuiTab-wrapper": {
      fontSize: 12,
      [themes.breakpoints.up(640)]: {
        fontSize: 16,
      },
    },
  },

  appBar: {
    color: COLORS.COLOR_DARK,
    boxShadow: "none",
    background: "transparent",
    fontFamily: theme.fonts.primaryFontBold,
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      [themes.breakpoints.up(640)]: {
        justifyContent: "flex-start",
      },
    },
  },

  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    [themes.breakpoints.up(400)]: {
      width: "auto",
      maxWidth: 230,
    },
  },
}));

const PaymentHistoryPage = () => {
  const breadcrumbs = [_.startCase(labels.payments)];
  const classes = useStyles();
  const [value, setValue] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));

  useEffect(() => {
    if (searchParams.get("type") === "proforma") {
      setValue(1);
    } else if (searchParams.get("kind") === "refund") {
      setValue(2);
    } else {
      setValue(0);
    }
  }, []);

  return (
    <div className="paymentHistory">
      <ActionBar {...{ breadcrumbs }} paymentPage={true} />
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(_, newValue) => setValue(newValue)}
            aria-label="pending-action-tab"
          >
            <Tab
              label={<div className={classes.label}>{labels.payments_tab}</div>}
              icon={
                !isBigScreen ? (
                  value === 0 ? (
                    <Icon
                      src={require("../../../assets/images/credit-card-active.svg")}
                    />
                  ) : (
                    <Icon
                      src={require("../../../assets/images/credit-card-inactive.svg")}
                    />
                  )
                ) : (
                  ""
                )
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <div className={classes.label}>{labels.proforma_invoice}</div>
              }
              icon={
                !isBigScreen ? (
                  value === 1 ? (
                    <Icon
                      src={require("../../../assets/images/invoice-active.svg")}
                    />
                  ) : (
                    <Icon
                      src={require("../../../assets/images/invoice-inactive.svg")}
                    />
                  )
                ) : (
                  ""
                )
              }
              {...a11yProps(1)}
            />
            {/* <Tab
              label={
                <div className={classes.label}>{labels.payment_refund}</div>
              }
              icon={
                !isBigScreen ? (
                  value === 2 ? (
                    <Icon
                      src={require("../../../assets/images/refund-active.svg")}
                    />
                  ) : (
                    <Icon
                      src={require("../../../assets/images/refund-inactive.svg")}
                    />
                  )
                ) : (
                  ""
                )
              }
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <PaymentHistory />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProfomaHistory />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <PaymentRefund />
        </TabPanel> */}
      </div>
    </div>
  );
};

export default PaymentHistoryPage;

export const Icon = styled.img``;
