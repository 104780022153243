import React, { Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const ActionBar = ({
  actions = [],
  breadcrumbs = [],
  onBreadcrumbClick = () => {},
  paymentPage = false,
}) => {
  return (
    <ActionBarContainer actions={actions}>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={`breadcrumb-${breadcrumb}-${index}`}>
            <Breadcrumb
              paymentPage={paymentPage}
              onClick={() => onBreadcrumbClick(breadcrumbs[index])}
            >
              {breadcrumb}
            </Breadcrumb>
            {index < breadcrumbs.length - 1 && <Breadcrumb>{">"}</Breadcrumb>}
          </Fragment>
        ))}
      </Breadcrumbs>
      <ActionButtonContainer>
        {actions.map((action, index) => (
          <ActionButton
            key={`action-${action.text}-${index}`}
            {...action.props}
            actions={actions}
            breadcrumbs={breadcrumbs}
          >
            {action.text}
          </ActionButton>
        ))}
      </ActionButtonContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;

const ActionBarContainer = styled.div`
  margin: 24px 15px 0px 15px;
  display: flex;
  flex-direction: column-reverse;
  min-height: ${({ actions }) => (actions.length === 0 ? "0" : "55px")};
  @media ${theme?.breakpoints?.sm_up} {
    height: 55px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 53px 0 35px;
    margin: 0;
    border-bottom: 1px solid ${COLORS.BORDER_GREY};
  }
`;

const ActionButton = styled.button`
  border-radius: 4px;
  border: 0;
  background-color: ${COLORS.BTN_GREEN};
  width: ${({ actions }) => (actions.length === 1 ? "100%" : "47%")};
  height: 40px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  margin-right: ${({ actions }) => (actions.length === 1 ? "0" : "6%")};
  color: ${COLORS.PRIMARY_WHITE};
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:nth-child(2) {
    margin-right: 0;
  }
  margin-bottom: ${({ breadcrumbs }) =>
    breadcrumbs.length === 0 ? "0" : "30px"};
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    width: 171px;
    margin-left: 20px;
    border-bottom: 1px solid ${COLORS.BORDER_GREY};
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;

const Breadcrumbs = styled.div``;

const Breadcrumb = styled.span`
  margin-right: 4px;
  cursor: pointer;
  color: ${COLORS.COLOR_DARK};
  user-select: none;
  font-size: ${({ paymentPage }) => (paymentPage ? "18px" : "14px")};
  font-family: ${({ paymentPage }) =>
    paymentPage
      ? `${theme.fonts.primaryFontExtraBold}`
      : `${theme.fonts.primaryFontSemiBold}`};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 14px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;
