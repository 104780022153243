import React, { useState } from "react";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../../common/CustomTable/CustomTable";
import Drawer from "../../common/Drawer";
import { Header, ImageWrapper } from "../DrawerTable";
import labels from "../../../helpers/labels.json";
import { PAYMENT_STATUS } from "../../../helpers/constants";
import _ from "lodash";
import moment from "moment";
import {
  getErrorMessage,
  parseTimeStamp,
  renderStatus,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import theme from "../../../assets/theme";
import styled, { css } from "styled-components";

async function getPayments(query = "") {
  try {
    const response = await CaseService.getPayments(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const PaymentDrawer = ({
  setOpenPaymentModal,
  openPaymentModal,
  id,
  MTRef,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({});

  const columns = [
    {
      field: "paymentId",
      title: labels["table.order_id"],
      sorting: false,
      render: (rowData) => <Title>{rowData?.paymentId}</Title>,
      headerStyle: {
        display: "flex",
      },
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) => <>INR {rowData?.cartItem?.amount?.toFixed(2)}</>,
    },
    {
      field: "created_at",
      title: labels["table.date"],
      tooltip: "Date of transaction",
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData?.created_at)).format("DD/MM/YYYY")}</>
      ),
    },
    {
      field: "description",
      title: labels["table.description"],
      sorting: false,
      render: (rowData) =>
        rowData?.cartItem?.feeKind ? (
          <div>
            {rowData?.case?.subscriptionKind === "adhoc"
              ? "Platform Fee"
              : renderStatus(
                  PAYMENT_STATUS[rowData?.cartItem?.feeKind],
                  rowData?.case
                )}
          </div>
        ) : (
          <div style={{ marginLeft: 35 }}>-</div>
        ),
      headerStyle: {
        width: "150px !important",
      },
    },
    {
      field: "status",
      title: labels["table.status"],
      sorting: false,
      render: (rowData) => (
        <div
          style={{
            fontFamily: theme.fonts.primaryFontBold,
            textAlign: "center",
          }}
        >
          {rowData?.refundedAt ? (
            <>
              <div>Refunded</div>
              <div
                style={{
                  fontSize: 10,
                  fontStyle: "italic",
                }}
              >
                ( INR {Number(rowData?.refundAmount).toFixed(2)})
              </div>
            </>
          ) : (
            <div>Completed</div>
          )}
        </div>
      ),
      headerStyle: {
        width: "150px !important",
        textAlign: "center",
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: "caseId",
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      const filterSting = queryString.stringify(
        { filterValues: [id] },
        { arrayFormat: "index" }
      );
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}&${filterSting}`;
      }
      getPayments(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });
  return (
    <Drawer
      state={openPaymentModal}
      setState={setOpenPaymentModal}
      label="Drawer"
    >
      <div>
        <Header>
          <Heading style={{ marginBottom: "unset" }}>Payments</Heading>
          <ImageWrapper>
            <img
              src={require("../../../assets/images/closeModal.svg")}
              onClick={() => {
                setOpenPaymentModal(false);
              }}
              alt="close"
            />
          </ImageWrapper>
        </Header>
        <div style={{ padding: 20 }}>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={"Payments"}
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
            }}
            noToolbar
            state={state}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentDrawer;

const Title = styled.div`
  margin-left: 35px;
  @media (min-width: 780px) {
    margin-left: 0;
    text-decoration: none;
    cursor: inherit;
    width: 90px;
  }
`;
