import React, { useEffect } from "react";
import {
  Content,
  HeadWrap,
  SubHeadWrap,
  BlogPage,
  BlogImage,
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function OnlineDisputeBlog(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Blog</HeadWrap>
          <br />
          <SubHeadWrap>The basics of Online Dispute Resolution</SubHeadWrap>
          <br />
          <Content>
            <p>
              Before and through the pandemic, the world changed radically. One
              would observe that there is a massive concentration of wealth and
              intellectual property. As a consequence, there are a select few
              people holding power in one hand, and many trying to oppose them
              on the other.
            </p>
            <br />
            <p>
              Services economy as compared to the manufacturing economy is
              dominant. This means more and more businesses are touching people
              on an individual basis. The country’s largest respondent is the
              government. And it’s only getting bigger in the world. More is
              spent on infrastructure, and balance sheet tallies are higher and
              higher. Catalyzed by elements such as social media, privacy issues
              are also at the center of attention in a lot of discussions and
              narrative in the world, thanks to cloud based services and so on.
            </p>
            <BlogImage
              src={require("../../../assets/images/BlogImg1.png")}
              alt="Independent"
            />
            <p>
              Quite simply, we are coming into a polarized world economically,
              socially, politically and from a business perspective. And the
              capacity of courts to deal with this kind of polarization is very
              limited. This means we need an alternate way to resolve disputes.
              And that’s exactly where online dispute resolution comes in.
            </p>
            <br />
            <p>
              Essentially this field is a branch of dispute resolution that
              utilizes technology and trending fields under it to aid resolving
              disputes between parties. Tech fields involved usually include and
              are not limited to Artificial Intelligence and Machine Learning!
              Under such fields the dispute resolution methods of negotiation,
              mediation or arbitration, or all three depending on the nature of
              the case are included.
            </p>
            <br />
            <p>
              So in layman’s terms, it may be seen as the online parallel of
              alternative dispute resolution. However, ODR can also augment the
              traditional means of dispute resolution by innovating on their
              techniques by involving technologies in its various processes.
            </p>
            <br />
            <p>
              Increasing the efficiency of the mechanisms to resolve online
              disputes will have a substantial impact, not just for disputes but
              even in the e-commerce sector as well. While ODR is not limited to
              disputes arising out of business to consumer online transactions,
              it would clearly be the logical choice, even in these cases to
              resort to ODR, since both use the same medium (the internet) when
              the concerned parties are in distance.
            </p>
            <br />
            <p>
              Multiple methods of dispute resolution being brought online means
              that ODR is quite a wide field. It can be applied to a variety of
              disputes. Interpersonal disputes, Consumer-to-Consumer disputes,
              Marital separation, other court disputes, you name it! This
              creates opportunities and challenges, which we talk about in our
              next article!
            </p>
          </Content>
          {/* <SubHeadWrap>What are the opportunities and challenges of Online Dispute Resolution?</SubHeadWrap>
        <Content>
            <p>
                Embracing an idea means embracing its methods. Embracing ODR needs to be done rapidly but not at the cost 
                of sacrificing the principles that underly our justice system. This includes aspects that safeguard justice 
                such as fair trials. This therefore begs the question: Why? 
            </p><br/>
            <b style={{ textAlign: "center"}} >Why do ODR methodologies need to be embraced?</b>
            <BlogImage
              src={require("../../../assets/images/BlogImg2.png")} 
              alt="Independent"/><br/>
            <p>
                Let’s take this from the perspective of India’s justice system, which itself faces several challenges. 
                The key issue in question is how it stands as a system that is not delivering justice in time to give relief to 
                people whose rights are violated. The numbers speak for this. 
            </p><br/>
            <b style={{ textAlign: "center" }}>5 Years</b>
            <p>
                That’s what it takes on an average, at every stage of our judicial system - district judiciary, high courts and supreme 
                court. Bottom line, the case life cycle basically is 15 years long! Simply put, we have a justice system that is overwhelmed and 
                therefore severely crippled by the volume of cases. 
            </p><br/>
            <p>
                But a deeper look reveals that it is not about having too many cases in India. It’s about an exclusion of people being able to access 
                the judicial system in India. Over 3 crore cases remain pending and we are daunted by it! 
            </p><br/>
            <p>
            It’s human nature to want a thorough closure to an issue faced. So when these cases are pending for 15 years or more, the resolution that 
            comes later has no meaning to it. If there is a property dispute between two people, how can the case be not resolved for 35 years? More importantly, 
            what kind of impact does it have on them?
            </p><br/>
            <p>
                The answer is we need to do something about getting our judicial system function better. And if you look forward, it presents a challenging 
                situation considering the number of courts that are built and the number of cases that come in. The capacity to deal with these cases just doesn’t 
                exist at this time. This calls for new methodologies to resolve disputes.
            </p><br/>
            <p>
                One of the silver linings of the COVID pandemic is the adoption of online hearings. Even so, two problems still manifest. The first is solving the 
                capacity problem, and then there’s the problem of accessibility to the judicial system. The only solution is that the reach of dispute resolution mechanism 
                has to go beyond what it is today.  
            </p><br/>
            <p>
                An immediate solution to this would be to further empower key personnel involved in the system: judges, arbitrators, mediators, to be able to work more efficiently, 
                interact with the stakeholders more efficiently without the problems of travel, availability, coordinating accessibility. Considering how it’s booming and at our fingertips, 
                technology becomes an appropriate way. It is critical to adopt newer technologies to solve the problems of dispute. ODR is not a different mechanism from delivering justice. 
                It might not solve all problems but it certainly is a significant step forward if we enable our judiciary and our legal system to adapt technology much quicker and make them digitally 
                native to enable access to justice.  In the process we’ll be better poised to resolve the challenges our judicial system is facing. 
            </p><br/>
            <p>
                With the opportunities under technologies come the challenges. Knowing the challenges means probing the following:
            </p>
            <TextLines>
                <ul>
                    <li>Access to justice.</li>
                    <li>What the ODR design really is, and</li>
                    <li>How it raises questions on equality, equity fairness, transparency.
                        <TextLines>
                            <li style={{ listStyleType: "circle" }} >How is the scheduling of cases? </li>
                            <li style={{ listStyleType: "circle" }} >How are documents circulated?</li>
                            <li style={{ listStyleType: "circle" }} >How does the video conferencing work?</li>
                        </TextLines>
                    </li>
                </ul>
            </TextLines>
            <p>
                ODR is not just a technology integration, but where technology is used to solve disputes. This means, ODR is not just a virtual scheduling of cases, not video conferencing, 
                not document circulation but it can include automated decision making with the use of artificial intelligence and machine learning. All of these raises questions on access, 
                particularly about the very platform of ODR.
            </p><br/>
            <BlogImage
              src={require("../../../assets/images/Picture3.png")} 
              alt="Independent"/>
        </Content> */}
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlog;
