import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useSettings from "../../../hooks/useSettings";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function PrivacyPolicy(props) {
  const { setting, settingsLoading } = useSettings();
  return (
    <Header>
      {settingsLoading ? (
        <Loader>
          <CircularProgress size={60} thickness={3} />
        </Loader>
      ) : (
        <Container>
          <H2>Privacy Policy</H2>
          {setting?.privacyPolicy?.map((policy, index) => (
            <div className="ql-editor" key={index}>
              <Padding>
                <SubHeading>{policy?.question}</SubHeading>
                <div dangerouslySetInnerHTML={{ __html: policy?.answer }}></div>
              </Padding>
            </div>
          ))}
        </Container>
      )}
      <Footer />
    </Header>
  );
}

export default PrivacyPolicy;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin: 10px 0;
  cursor: default;
  padding: 10px 20px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;

const SubHeading = styled.div`
  font-size: 20px;
  margin: 10px 0;
  color: ${COLORS.BTN_GREEN};
`;

const Padding = styled.div`
  margin: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  text-align: justify;
  margin-bottom: 20px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    margin: auto;
  }
`;
export const UnorderedList = styled.ol`
  padding-left: 20px;
  margin: 10px 0;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 500px;
`;
