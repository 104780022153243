import API from "../api/manager";
import config from "../api/config";

const createCase = (data, partyId) => {
  return API({
    method: "POST",
    url: config.urls.party.party + `/${partyId}/cases/update`,
    data,
  });
};

const createCaseItem = (data, id, partyId) => {
  return API({
    method: "POST",
    url: config.urls.party.party + `/${partyId}/cases/${id}/case-items`,
    data,
  });
};

const updateCaseItem = (caseId, id, data, partyId) => {
  return API({
    method: "PUT",
    url:
      config.urls.party.party + `/${partyId}/cases/${caseId}/case-items/${id}`,
    data,
  });
};
const deleteCaseItem = (caseId, id, partyId) => {
  return API({
    method: "DELETE",
    url:
      config.urls.party.party + `/${partyId}/cases/${caseId}/case-items/${id}`,
  });
};

const submitCase = (data, id, partyId) => {
  return API({
    method: "PUT",
    url: config.urls.party.party + `/${partyId}/cases/create/${id}`,
    data,
  });
};

const getCase = (id, query = "") => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}${query}`,
  });
};

const updateDraft = (data, id, partyId) => {
  return API({
    method: "PUT",
    url: config.urls.party.party + `/${partyId}/cases/update/${id}`,
    data,
  });
};

const getCases = (query) => {
  return API({ method: "GET", url: config.urls.case.case + query });
};

const caseStats = () => {
  return API({ method: "GET", url: config.urls.case.stats });
};

const caseStatsByCaseType = (type) => {
  return API({ method: "GET", url: config.urls.case.stats + type });
};

const caseBulkCreate = (data, partyId, urlKind) => {
  return API({
    method: "POST",
    url: `${config.urls.party.uploads}/${partyId}/${urlKind}`,
    data,
  });
};

const getPayments = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const negotiations = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}/negotiations`,
  });
};
const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};
const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const getRespondent = () => {
  return API({
    method: "GET",
    url: config.urls.case.respondent,
  });
};

/**
 * @description Service to accept the cliament offer
 * @param {*} id
 * @param {*} data
 */
const postClaims = (id, data) => {
  return API({
    method: "POST",
    url: config.urls.case.case + `/${id}/negotiations`,
    data,
  });
};

const quitCase = (id, data) => {
  return API({
    method: "DELETE",
    url: config.urls.case.case + `/${id}/negotiations`,
    data,
  });
};

const caseRequests = (query) => {
  return API({
    method: "GET",
    url:
      config.urls.case.invites +
      `${query}&filter=respondentStatus&filterValues[]=pending`,
  });
};
const caseInvitesSent = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.sent_invites + query,
  });
};

const acceptCase = (caseId, partyid) => {
  return API({
    method: "POST",
    url: config.urls.case.invite_action + `/${partyid}/${caseId}`,
  });
};

const declineCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.invite_action + `/${id}`,
  });
};

const dashboardCount = () => {
  return API({
    method: "GET",
    url: config.urls.case.count,
  });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const caseUpdateDocument = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadCase + `/${caseId}/dealAgreement/${docId}`,
    data,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement${query}`,
  });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const caseRating = (id, values) => {
  return API({
    method: "POST",
    url: config.urls.case.rating + `/${id}`,
    data: values,
  });
};

const scheduleMeeting = (values) => {
  return API(
    {
      method: "POST",
      url: config.urls.meetingRequest,
      data: values,
    },
    false
  );
};

const getChatDetails = (id) => {
  return API({
    method: "GET",
    url: config.urls.chat.index + `/${id}`,
  });
};

const postRestoreId = (id, externalId, restoreId) => {
  return API({
    method: "POST",
    url: config.urls.chat.index + `/${id}/${externalId}`,
    data: {
      restoreId,
    },
  });
};

const addAllCases = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.select + query,
    data: {},
  });
};

const removeAllCases = (query) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + query,
    data: {},
  });
};

const selectCaseItem = (id) => {
  return API({
    method: "POST",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const removeCaseItem = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getSelectedCase = () => {
  return API({
    method: "GET",
    url: config.urls.case.select,
  });
};

const submitDraftCase = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.submitAllDraft + query,
    data: {},
  });
};

const getOrders = (query) => {
  return API({ method: "GET", url: config.urls.cart.orders + query });
};

const getRefundHistory = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const getNotificationCount = () => {
  return API({ method: "GET", url: config.urls.notificationCount });
};

const clearNotification = () => {
  return API({ method: "POST", url: config.urls.notificationCount, data: {} });
};

const deleteProforma = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.cart.order + `/${id}`,
    data: {},
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const deleteDraftCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.draft + `/${id}`,
    data: {},
  });
};

const deleteSelectedCases = () => {
  return API({
    method: "DELETE",
    url: config.urls.case.selectDraft,
    data: {},
  });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${caseId}/parties${param}`,
  });
};

const addNotes = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.notes}/${caseId}`,
    data,
  });
};

const getNotes = (caseId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.notes}/${caseId}${query}`,
  });
};

const validatePaymentCode = (caseId, paymentCode) => {
  return API({
    method: "GET",
    url: `${config.urls.case.validatePaymentCode}/${caseId}?paymentCode=${paymentCode}`,
  });
};

const getGuestUserMeeting = (data, code) => {
  return API({
    method: "POST",
    url: config.urls.meeting.guestUserMeeting + `/${code}`,
    data
  },false)
};

const endMeeting = (caseId) => {
  console.log(config.urls.meeting.endMeeting)
  return API({
    method: "PUT",
    url: config.urls.meeting.endMeeting + `/${caseId}`,
  },false)
}

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const changeDocType = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadCase + `/${caseId}/${docId}`,
    data,
  });
};

const addBundle = (data, id) => {
  return API({
    method: "POST",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data,
  });
};

const getBundleName = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.customBundle}/${id}`,
  });
};

const getDocumentList = (caseId, id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.bundleDocList}/${caseId}/${id}`,
  });
};

const updateBundle = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.customBundle}/${caseId}/${id}`,
    data,
  });
};

const refreshAwards = caseId => {
  return API({
    method: "POST",
    url: `${config.urls.case.docWriter}/${caseId}`
  });
};

export default {
  createCase,
  getCase,
  updateDraft,
  createCaseItem,
  submitCase,
  deleteCaseItem,
  updateCaseItem,
  getCases,
  caseStats,
  getMeeting,
  caseBulkCreate,
  getPayments,
  negotiations,
  postClaims,
  caseRequests,
  acceptCase,
  declineCase,
  quitCase,
  caseInvitesSent,
  dashboardCount,
  caseAddDocument,
  getcaseDocuments,
  caseUpdateDocument,
  downloadCaseAgreement,
  caseRating,
  scheduleMeeting,
  getRespondent,
  getChatDetails,
  postRestoreId,
  caseStatsByCaseType,
  mediations,
  sendComment,
  addAllCases,
  removeAllCases,
  selectCaseItem,
  removeCaseItem,
  getSelectedCase,
  submitDraftCase,
  getOrders,
  getNotificationCount,
  clearNotification,
  deleteProforma,
  getRefundHistory,
  deleteDraftCase,
  deleteSelectedCases,
  getPartyListByCaseId,
  addNotes,
  getNotes,
  validatePaymentCode,
  getGuestUserMeeting,
  endMeeting,
  getMeetingVideo,
  changeDocType,
  addBundle,
  getBundleName,
  getDocumentList,
  updateBundle,
  refreshAwards,
};
