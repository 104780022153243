import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import _ from "lodash";

/**
 * @description Function to call the accept claim Service
 * @param {id} id
 * @param {*rounds} rounds
 * @param {*isRespondent} isRespondent
 */
const callAcceptCliam = async (id, rounds, isRespondent) => {
  const lastIndex = Object.keys(rounds).length - 1;
  const lastRound = rounds[lastIndex];
  const roundForSubmit = isRespondent
    ? lastRound.claimantClaims
    : lastRound.respondentClaims;
  const acceptedItems = roundForSubmit
    .filter(
      (el) => _.isEmpty(el?.acceptedOffer) && el?.offer?.status === "offered"
    )
    .map((el) => el.offer.id);
  if (acceptedItems.length > 0) {
    const postData = {
      acceptedCounterOfferIds: acceptedItems,
    };
    await postClaimItems(id, postData).then((res) => {
      return res;
    });
  }
};

/**
 * @description Function to Call the Send offer Services
 * @param {*id} id
 * @param {*formikValue} formikValue
 */
const callSendOffer = async (id, formikValue) => {
  const postData = {};
  const roundForSubmit =
    formikValue?.current[formikValue?.current?.length - 1]?.values?.items;
  const acceptedItems = roundForSubmit
    .filter(
      (el) =>
        (_.isEmpty(el?.acceptedOffer) && el?.offer?.status === "accepted") ||
        (el?.offer?.status === "offered" &&
          Number(el?.offer?.claimValue) === Number(el.previousOffer))
    )
    .map((el) => el.offer.id);
  const offeredItems = roundForSubmit
    .filter(
      (el) =>
        el?.offer?.status === "offered" &&
        Number(el?.offer?.claimValue) !== Number(el.previousOffer)
    )
    .map((el) => {
      const { id, offer } = el;
      return {
        claimValue: Number(offer.claimValue),
        caseItemId: id,
        reason: offer.reason || "",
        suggestedOffer: {
          claimValue: Number(offer.suggestedClaimValue),
        },
      };
    });
  if (offeredItems.length > 0) {
    postData.suggestedCounterOffers = offeredItems;
  }
  if (acceptedItems.length > 0) {
    postData.acceptedCounterOfferIds = acceptedItems;
  }
  await postClaimItems(id, postData).then((result) => {
    return result;
  });
};

/**
 * @description Function to call the Accept offer services
 * @param {id} id
 * @param {items} items
 */
const postClaimItems = async (id, postData) => {
  try {
    const res = await CaseService.postClaims(id, postData);
    console.log(res);
  } catch (error) {
    const message = getErrorMessage(error);
    return { message, loader: false };
  } finally {
    return { message: "success", loader: false };
  }
};

/**
 * @description Function to call the Quit case Services
 * @param {id} id
 */
const callQuitCase = async (id) => {
  try {
    const res = await CaseService.quitCase(id);
    console.log(res);
  } catch (error) {
    const message = getErrorMessage(error);
    return { message, loader: false };
  } finally {
    return { message: "success", loader: false };
  }
};

export { callAcceptCliam, callSendOffer, callQuitCase };
