import React, { useEffect } from "react";
import {
  Content,
  HeadWrap,
  SubHeadWrap,
  BlogPage,
  BlogImage,
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";


function OnlineDisputeBlogTen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Blog</HeadWrap>
          <br />
          <SubHeadWrap>Tackling pending cases in Indian Courts</SubHeadWrap>
          <br />
          <Content>
            <p>
              Every year, a minimum of five crore cases are filed across various
              courts of India. Even as some of these cases stay pending for over
              ten years, judges dispose of only two crores. This is inefficient
              and ineffective as well. But recognizing a problem requires
              recognition of its causes. Here are the primary causes that you
              need to know.
            </p>
            <br />
            <SubHeadWrap>Low awareness of rights</SubHeadWrap>
            <br />
            <p>Two of the ten founding principles of economics are that:</p>
            <br />
            <ul style={{ paddingLeft: "40px" }}>
              <li>People respond to incentives, and</li>
              <li>The government can sometimes improve market outcomes.</li>
            </ul>
            <br />
            <p>
              As India advanced socio-economically over the decades, one of the
              resulting effects was an increased awareness of legal rights
              entitled to the common individual, allowing many to muster courage
              to approach courts of law for justice.
            </p>
            <br />
            <p>
              This was further catalysed by legislation set by governments for
              new rights such as ‘Right to Information’ and ‘Right to
              Education’. Devices such as Public Interest Litigation further
              accelerated the ever-developing volume of cases. Consequently,
              more parties with grievances approached the courts. However, there
              is still scope of improving awareness in the masses of the several
              laws that exist.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Tackling_1.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Not enough judges and courts.</SubHeadWrap>
            <br />
            <p>
              India has 15 judges for every million people. This is one of the
              world's lowest ratios. Against the high volume of cases, there are
              not enough judges to take them on efficiently. The Indian
              judiciary additionally has insufficient resources. With
              modernization and digitization not having reached pan-India, India
              needs more courts and more benches.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Tackling_2.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Archaic / Vaguely drafted laws</SubHeadWrap>
            <br />
            <p>
              Laws typically are subjected to amendments subject to the
              developments, as well as relying on precedent of cases pertinent
              to the same. However, there still are certain archaic laws under
              myriad statutes; some even dating back to the 1880s!
            </p>
            <br />
            <p>
              In addition, some are also vulnerable to faulty or vague drafting,
              as well as multiple interpretations by various courts are also
              reasons for prolonged litigation. This in turn can create further
              delays for redressal in an already overburdened justice system.
            </p>
            <br />
            <p>As a result of such causes, two questions are raised:</p>
            <br />
            <ul style={{ paddingLeft: "40px" }}>
              <li>What are the ramifications?</li>
              <li>What can be done?</li>
            </ul>
            <br />
            <BlogImage
              src={require("../../../assets/images/Tackling_3.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Ramifications</SubHeadWrap><br/>
            <p>
              A continually ineffective and inefficient justice system can risk
              losing the common folk’s faith in it, if reforms aren’t set up to
              speed the process. But this isn’t just a concern nationally.
            </p>
            <br />
            <p>
              Even foreign investors are also sceptical about the timely
              delivery of justice in the country. This in turn can be
              economically detrimental as it would mean affecting the success of
              economy-building programs such as ‘Make in India’.
            </p>
            <br />
            <p>
              With a judiciary that can’t handle the avalanche of cases, the
              justice system is overworked and loses its efficiency. As the
              adage goes, justice delayed is justice denied, and justice hurried
              is justice buried. But the thing is, it doesn’t have to be this
              way.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Tackling_4.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Online Dispute Resolution</SubHeadWrap>
            <br />
            <p>
              While courts move toward digitization, swift, effective and
              scalable mechanisms of dispute resolution can be implemented. This
              is where Online Dispute Resolution steps in.
            </p>
            <br />
            <p>
              This is a branch of dispute resolution that utilizes technology to
              aid resolving disputes between parties. Under such fields, the
              dispute resolution methods of negotiation, mediation or
              arbitration, or all three depending on the nature of the case are
              included. So, in layperson’s terms, it may be seen as the online
              parallel of alternative dispute resolution.
            </p>
            <br />
            <p>
              JustAct is a platform dedicated to this; to augment the
              traditional means of dispute resolution by innovating on their
              techniques by involving technologies in its various processes.
              This allows it to be applied to a variety of disputes. You can
              know more about our offered services{" "}
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://justact.co.in/aboutus#how-it-works")
                }
              >
                here{" "}
              </div>
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTen;
