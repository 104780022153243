import React, { useState, useEffect } from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import CustomSelect from "../../common/CustomSelect/CustomSelectVisibility";
import useLoader from "../../../hooks/useLoader";
import { caseVisibilityType } from "../../../helpers/constants";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import { Modal } from "@material-ui/core";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton
} from "../../common/Buttons";

const DocChangeType = ({ rowData, modal, setModal, setState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  let docId = rowData?.id;
  let caseId = rowData?.entityId;
  let visibilityData = rowData?.visibilityType;
  let resolutionKind = rowData?.entity?.resolutionKind;
  const [visibility, setVisibility] = useState();

  useEffect(() => {
    if (modal) {
      setVisibility("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const changeDocType = async value => {
    try {
      setLoader({ state: true, message: "Change Visibility..." });
      const payload = {
        visibilityType: value
      };
      const res = await CaseService.changeDocType(payload, caseId, docId);
      if (res) {
        enqueueSnackbar("Visibility Type Changed Successfully", {
          variant: "success"
        });
      }
      setVisibility(value);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setModal(false);
      setState(false);
    }
  };

  return (
    <Modal open={modal}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Change Visibility Type</Heading>
            <CloseModal
              onClick={() => setModal(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <CustomSelect
              menuItemValues={caseVisibilityType}
              value={visibility ? visibility : visibilityData}
              onChange={e => setVisibility(e.target.value)}
            />
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={e => {
                  e.preventDefault();
                  setModal(false);
                }}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                onClick={e => {
                  e.preventDefault();
                  changeDocType(visibility);
                }}
              >
                Update
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default DocChangeType;

// export const Container = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   @media ${theme?.breakpoints?.sm_up} {
//     width: auto;
//   }
// `;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 450px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;
