/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { states } from "./constants";

function validatePinCodeByState(state, pin) {
  if (state && pin) {
    const found = states.find(st => st.value === state);
    const pinStart = pin?.toString().substring(0, 2);
    if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const PAN_CARD_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const VOTER_REGEX = /^([a-zA-Z]){3}([0-9]){7}?$/;
const AADHAAR_REGEX_TWELVE = /^\d{4}\d{4}\d{4}$/;
const AADHAAR_REGEX_SIXTEEN = /^\d{4}\d{4}\d{4}\d{4}$/;
const CIN_REGEX = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
const MOBILE_REGEX = /^\s*(?:\+?(\d{2}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const PASSWORD_RULE = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
const GST_VALIDATION = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

export const signupValidationSchema = Yup.object({
  fullname: Yup.string()
    .trim()
    .required("Full Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
  password: Yup.string()
    .matches(
      PASSWORD_RULE,
      "Password should have a minimum of 1 digit and 1 letter"
    )
    .min(6, "Password must be minimum 6 characters")
    .required("Password is required")
});

export const signupWithGoogle = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
});

export const profileCompletionValidationSchema = Yup.object({
  dob: Yup.date()
    .nullable()
    .required("Date of Birth is required"),
  address_line: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function(value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  landmark: Yup.string()
    .trim()
    // .max(30, "Landmark is too long")
    .required("Landmark is required")
});

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email")
    .required(),
  password: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Password is required")
});

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
});

export const createPartyValidationSchema = Yup.object({
  document_house_no: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  document_locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  document_city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  document_state: Yup.string()
    .trim()
    .required("State is required"),
  document_pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function(value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.document_state, value);
      } else {
        return false;
      }
    }),
  document_landmark: Yup.string()
    .trim()
    .nullable()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
  document_uri: Yup.string()
    .trim()
    .nullable()
    .required("Document is required"),
  doc_number: Yup.string()
    .trim()
    .test("document-number", "", function(value) {
      if (this?.parent?.document_kind === "pan") {
        const matched = value?.match(PAN_CARD_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "PAN Number is required",
              path: "doc_number"
            });
          return this.createError({
            message: "Enter a valid PAN Number",
            path: "doc_number"
          });
        }
      } else if (this?.parent?.document_kind === "aadhaar") {
        const matched =
          value?.match(AADHAAR_REGEX_TWELVE) ||
          value?.match(AADHAAR_REGEX_SIXTEEN);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Aadhaar Number is required",
              path: "doc_number"
            });
          return this.createError({
            message: "Enter a valid Aadhaar Number",
            path: "doc_number"
          });
        }
      } else if (this?.parent?.document_kind === "cin") {
        const matched = value?.match(CIN_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "CIN Number is required",
              path: "doc_number"
            });
          return this.createError({
            message: "Enter a valid CIN Number",
            path: "doc_number"
          });
        }
      } else if (this?.parent?.document_kind === "voter") {
        const matched = value?.match(VOTER_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Voter ID Number is required",
              path: "doc_number"
            });
          return this.createError({
            message: "Enter a valid Voter ID Number",
            path: "doc_number"
          });
        }
      } else if (this?.parent?.document_kind === "passport") {
        if (value) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Passport Number is required",
              path: "doc_number"
            });
          return this.createError({
            message: "Passport number is required",
            path: "doc_number"
          });
        }
      }
    })
    .nullable(),
  name: Yup.string()
    .trim()
    .required("Party Name is required"),
  gstNumber: Yup.string()
    .trim()
    .nullable()
    .matches(GST_VALIDATION, "Please enter valid GST number"),
  gstCertUrl: Yup.string()
    .trim()
    .test("gstCertUrl", "", function(value) {
      if (this?.parent?.gstNumber) {
        const matched = value?.gstNumber;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Document is required",
              path: "gstCertUrl"
            });
        }
      }
    }),
  document_billing_house_no: Yup.string()
    .trim()
    .required("Billing Address is required"),
  document_billing_locality: Yup.string()
    .trim()
    .required("Billing Locality is required"),
  document_billing_city: Yup.string()
    .trim()
    .required("Billing City is required"),
  document_billing_state: Yup.string()
    .trim()
    .required("Billing State is required"),
  document_billing_pin: Yup.string()
    .trim()
    .required("Billing Pincode is required"),
  document_billing_landmark: Yup.string()
    .trim()
    .required("Billing Landmark is required")
  // gstCertUrl: Yup.string().trim().nullable(),
});

export const AddAgentValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Agent email is required"),
  pan: Yup.string().matches(PAN_CARD_REGEX, "Enter a valid PAN number")
  // .required("Agent PAN Number is required"),
  // uri: Yup.string().trim().required("Authorisation Letter is required"),
});

export const CreateCaseStep1Schema = Yup.object({
  claimantPartyId: Yup.string().required("Party is required"),
  resolutionKind: Yup.string(),
  description: Yup.string().required("Case Description is required"),
  totalClaimValue: Yup.string()
    .test("not-zero", "Total Claim value is required", function(value) {
      if (this?.parent?.resolutionKind === "negotiation") {
        return true;
      } else if (value) {
        return true;
      }
      return false;
    })
    .typeError("Claim value cannot be empty"),
  agreement_urls: Yup.array().of(
    Yup.object().shape({
      uploadType: Yup.string()
        .trim()
        .test("documenttype_required", "Document Type is required", function(
          value
        ) {
          if (
            this?.options?.values?.resolutionKind === "arbitration" &&
            !value &&
            this.parent.url
          ) {
            return false;
          }
          return true;
        }),
      url: Yup.string()
        .trim()
        .test("document_required", "Document is required", function(value) {
          if (
            this?.options?.values?.resolutionKind === "arbitration" &&
            !value &&
            this.parent.uploadType
          ) {
            return false;
          }
          return true;
        })
    })
  ),
  claim_items_data: Yup.array().of(
    Yup.object({
      title: Yup.string()
        .trim()
        .test("title", "Title is required", function(value) {
          console.log(this?.options?.values);
          if (this?.options?.values?.resolutionKind === "mediation" && !value) {
            return false;
          }
          return true;
        }),
      claimValue: Yup.string()
        .trim()
        .test("not-zero", "Claim value cannot be empty", function(value) {
          if (this?.options?.values?.resolutionKind === "mediation" && !value) {
            return value;
          }
          return true;
        })
        .typeError("Claim value cannot be empty")
    })
  ),
  subscriptionKind: Yup.string(),
  requestedMediators: Yup.array().of(
    Yup.object().shape({
      mediatorName: Yup.string()
        .trim()
        .test("name", `Name is required`, function(value) {
          return value || this?.options?.values?.subscriptionKind !== "adhoc";
        }),
      mediatorEmail: Yup.string()
        .email("Please enter a valid email")
        .test("email", "Email is required", function(value) {
          return value || this?.options?.values?.subscriptionKind !== "adhoc";
        }),
      mediatorMobile: Yup.string().test(
        "phone",
        "Enter a valid mobile number",
        function(value) {
          if (value) {
            return value?.length === 10;
          }
          return true;
        }
      )
    })
  )
});

export const CaseRequestSchema = Yup.object({
  partyId: Yup.string()
    .trim()
    .required("Party is Required")
});

export const CreateCaseStep2Schema = Yup.object({
  respondentParties: Yup.array().of(
    Yup.object().shape({
      respondentKind: Yup.string()
        .trim()
        .required("Respondent kind is required"),
      respondentName: Yup.string()
        .trim()
        .required("Respondent name is required"),
      respondentEmail: Yup.string()
        .email("Please enter a valid email")
        .required("Respondent email is required"),
      respondentMobile: Yup.string()
        .max(10, "Enter a valid mobile number")
        .min(10, "Enter a valid mobile number")
    })
  )
});

export const CreateCaseStep3Schema = Yup.object({
  claim_items: Yup.array(
    Yup.object({
      title: Yup.string()
        .trim()
        .nullable()
        .required("Title is required"),
      claimValue: Yup.string()
        .test("not-zero", "Claim value must be greater than zero", function(
          value
        ) {
          return value;
        })
        .typeError("Claim value cannot be empty"),
      unit: Yup.string()
        .trim()
        .nullable()
        .required("Unit is required"),
      description_document_url: Yup.string()
        .trim()
        .nullable(),
      description: Yup.string()
        .trim()
        .nullable()
    })
  )
});

export const EditProfileSchema = Yup.object({
  houseNumber: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  landmark: Yup.string()
    .trim()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
  state: Yup.string()
    .trim()
    .required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function(value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  panNumber: Yup.string()
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .matches(
      PASSWORD_RULE,
      "Password should have a minimum of 1 digit and 1 letter"
    )
    .min(6, "Please enter 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords do not match", function(value) {
      return this.parent.password === value;
    })
});

export const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Current Password is required"),
  newPassword: Yup.string()
    .matches(
      PASSWORD_RULE,
      "Password should have a minimum of 1 digit and 1 letter"
    )
    .min(6, "Please enter 6 characters")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .min(6, "Password must be minimum 6 characters")
    .test("passwords-match", "Passwords do not match", function(value) {
      return this.parent.newPassword === value;
    })
});

export const BulkUploadSchema = Yup.object({
  partyId: Yup.string()
    .trim()
    .required("Party is required"),
  url: Yup.string().required("Case file is required")
});

export const DiscussFormValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
});
