import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Heading,
  Subheading,
  FormContainer,
  FormControl,
  StyledLabel,
  CheckBoxLabel,
  CheckboxContainer,
  CheckboxFormControl,
  Row,
  BackArrow,
  HyperLink,
  OuterContainer,
} from "./Styles";
import { CustomRadioGroup, CustomCheckbox } from "../../common/FormInputs";
import ActionBar from "../../common/ActionBar";
import { navigate } from "@reach/router";
import _ from "lodash";
import { FieldArray, Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import { CreateCaseStep2Schema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import useAlert from "../../../hooks/useAlert";
import theme from "../../../assets/theme";
import useCartCount from "../../../hooks/useCartCount";
import useSettings from "../../../hooks/useSettings";
import AlertDialog from "../../common/Alert";
import COLORS from "../../../assets/Colors";

const inputs = [
  {
    name: "respondentName",
    type: "text",
    label: "Name of the Respondent",
    required: true,
    textTransform: "Capitalize",
  },
  {
    name: "respondentEmail",
    type: "email",
    label: "Email of the Respondent",
    required: true,
  },
  {
    name: "respondentMobile",
    type: "text",
    label: "Phone Number of the Respondent",
  },
  {
    name: "respondentAddress",
    type: "text",
    label: "Address of the Respondent",
  },
];

const Index = ({ caseId }) => {
  const [caseObj, setCaseObj] = useState({});
  const breadcrumbs = ["My Cases", "Create New Case"];
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { hideAlert, showAlert } = useAlert();
  const { setTriggerCount } = useCartCount();
  const { setting } = useSettings();
  const [dialogData, setDialogData] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getCase(caseId) {
      try {
        setLoader({ state: true });
        const response = await CaseService.getCase(caseId);
        if (response) {
          setCaseObj(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (caseId) {
      getCase(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "my_cases":
        navigate("/dashboard/cases");
        break;
      default:
        break;
    }
  };

  async function validatePayment(payload){
    if(payload?.paymentCode){
      const paymentCodeValidate = await CaseService.validatePaymentCode(
        caseId,
        payload?.paymentCode
      );
      return paymentCodeValidate['paymentCodeValidation']
      }
      else return false
  }

  async function addToCart(payload) {
    setLoader({ state: true, message: "Submitting form..." });
    try{
      const paymentCodeValidate=await validatePayment(payload)    
      console.log(paymentCodeValidate)
      if(paymentCodeValidate){
        const response = await CaseService.submitCase(
          payload,
          caseId,
          caseObj.claimantParty.id
        );
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          navigate('/dashboard/cases')
        }        
      }
      else if(!paymentCodeValidate) { 
      let negotiationSuccessFee;
      const registrationFee =
        (await CaseService.getCase(caseId, "?estimateFeeKind=registrationFee"))
          ?.fee || {};
      if (caseObj?.subscriptionKind !== "adhoc") {
        negotiationSuccessFee =
          (await CaseService.getCase(caseId, "?estimateFeeKind=successFee"))
            ?.fee || {};
      }
      showAlert({
        heading: "Payment Information",
        desc: (
          <PaymentAlert
            {...{ registrationFee, negotiationSuccessFee, caseObj, payload }}
          />
        ),

        primaryBtnText: "Add to Cart",
        secondaryBtnText: "Cancel",
        clickSecondarybtn: () => {
          hideAlert();
        },
        clickPrimaryBtn: () => submitMediationCase(payload),
        isOpen: true,
        onDialogClose: () => {
          hideAlert();
        },
        descriptionTextStyle: {
          textAlign: "left",
        },
      });}
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function submitMediationCase(payload) {
    try {
      setLoader({ state: true, message: "Submitting form..." });
      const response = await CaseService.submitCase(
        payload,
        caseId,
        caseObj.claimantParty.id
      );
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        navigate("/dashboard/cart");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      hideAlert();
    } finally {
      setTriggerCount(true);
      setLoader({ state: false });
      hideAlert();
    }
  }

  async function saveAsDraftOrSubmit(values, isDraft) {
    const postData = JSON.parse(JSON.stringify(values));
    try {
      setLoader({ state: true, message: "Submitting form..." });
      const response = await CaseService.updateDraft(
        postData,
        caseId,
        caseObj.claimantParty?.id
      );
      if (response?.case?.id) {
        if (isDraft) {
          enqueueSnackbar("Case added to draft successfully", {
            variant: "success",
          });
          navigate(
            `/dashboard/cases?caseType=${response?.case?.resolutionKind}`
          );
        } else {
          navigate(
            `/dashboard/cases/create/${response.case.id}/step-3?caseType=${response?.case?.resolutionKind}`
          );
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  function onFormSubmit(values, props, isDraft = false) {
    try {
      const postData = JSON.parse(JSON.stringify(values));
      postData.respondentParties = postData?.respondentParties?.map((el) => {
        return {
          respondentKind: el?.respondentKind,
          name: el?.respondentName,
          email: el?.respondentEmail.toString().toLowerCase(),
          mobile: el?.respondentMobile,
          address: el?.respondentAddress,
        };
      });
      const hasDuplication =
        postData?.respondentParties?.length &&
        postData?.respondentParties?.filter(
          (mediator, index) =>
            postData?.respondentParties?.findIndex(
              (el) => el?.email === mediator?.email
            ) !== index
        )?.length;
      if (hasDuplication) {
        // eslint-disable-next-line no-throw-literal
        throw "Duplicate email IDs found in respondent parties";
      }
      if (caseObj?.resolutionKind !== "negotiation" && !isDraft) {
        addToCart(postData);
      } else {
        saveAsDraftOrSubmit(postData, isDraft);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        if (caseObj?.resolutionKind !== "negotiation") {
          navigate(`/dashboard/cases?caseType=${caseObj?.resolutionKind}`);
        } else {
          navigate("/dashboard/cases?caseType=negotiation");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  /**
   * @description Function to show the delete warning
   * @param {*} id
   */
  const deleteWaring = (id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () => deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        textAlign: "center",
      },
    });
  };

  function setInitialValues(caseObj) {
    if (
      caseObj?.respondentParties &&
      caseObj?.respondentParties[0]?.respondentKind
    ) {
      return caseObj?.respondentParties?.map((el) => {
        return {
          respondentKind: el.respondentKind,
          respondentName: el?.name,
          respondentEmail: el?.email,
          respondentMobile: el?.mobile || "",
          respondentAddress: el?.address || "",
        };
      });
    } else {
      return [
        {
          respondentKind: caseObj?.respondentKind || "individual",
          respondentName: caseObj?.respondentName || "",
          respondentEmail: caseObj?.respondentEmail || "",
          respondentMobile: caseObj?.respondentMobile || "",
          respondentAddress: caseObj?.respondentAddress || "",
        },
      ];
    }
  }

  const dummyParty = {
    respondentKind: "individual",
    respondentName: "",
    respondentEmail: "",
    respondentMobile: "",
    respondentAddress: "",
  };

  return (
    <OuterContainer>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="CreateCase">
        <Heading>
          <div style={{ display: "flex", flex: 1 }}>
            <BackArrow
              onClick={() =>
                navigate(
                  `/dashboard/cases/create/${caseId}?caseType=${caseObj?.resolutionKind}`
                )
              }
              src={require("../../../assets/images/backArrow.svg")}
            />
            Create New Case (2/{" "}
            {caseObj?.resolutionKind !== "negotiation" ? 2 : 3})
          </div>
          <div className="delete" onClick={() => deleteWaring(caseId)}>
            <img
              src={require("../../../assets/images/delete.svg")}
              alt="delete Icon"
            />
          </div>
        </Heading>
        <div>
          <Subheading style={{ marginBottom: "unset" }}>
            Enter Respondent Details
          </Subheading>
        </div>
        <Formik
          onSubmit={onFormSubmit}
          initialValues={{
            respondentParties: setInitialValues(caseObj),
            agreement: false,
          }}
          enableReinitialize
          validationSchema={CreateCaseStep2Schema}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FieldArray
                name="respondentParties"
                render={({ push, remove }) => (
                  <>
                    {values?.respondentParties?.map((party, index) => (
                      <Fragment key={index}>
                        {values?.respondentParties?.length > 1 ? (
                          <div className="header-container">
                            <Subheading
                              style={{
                                marginBottom: 15,
                                display: "flex",
                                flex: 1,
                              }}
                            >
                              Respondent {index + 1}
                            </Subheading>
                            <div
                              className="removeIcon"
                              onClick={() => remove(index)}
                            >
                              <img
                                src={require("../../../assets/images/removeIcon.svg")}
                                alt="delete Icon"
                              />
                            </div>
                          </div>
                        ) : null}
                        <RespondentInputs
                          values={party}
                          errors={
                            errors && errors?.respondentParties
                              ? errors?.respondentParties[index]
                              : {}
                          }
                          touched={
                            touched && touched?.respondentParties
                              ? touched?.respondentParties[index]
                              : {}
                          }
                          {...{
                            index,
                            party,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      </Fragment>
                    ))}
                    {caseObj?.resolutionKind !== "negotiation" ? (
                      <SecondaryCTAButton
                        onClick={() => {
                          push({ ...dummyParty });
                        }}
                        style={{
                          marginTop: "24px",
                          margin: "unset",
                        }}
                      >
                        + Add Another Respondent
                      </SecondaryCTAButton>
                    ) : null}
                  </>
                )}
              />
              {caseObj.resolutionKind !== "negotiation" ? (
                <FormControl style={{ marginTop: "8px" }}>
                  <FormField
                    white
                    input={{
                      name: "paymentCode",
                      label: <TextColor>Payment Code (If any)</TextColor>,
                      type: "text",
                      required: false,
                    }}
                    {...{
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </FormControl>
              ) : null}
              {caseObj?.subscriptionKind !== "adhoc" ? (
                <CheckboxFormControl>
                  <CheckboxContainer>
                    <CustomCheckbox
                      name="agreement"
                      checked={values.agreement}
                      onChange={handleChange}
                    />
                    <CheckBoxLabel>
                      I Agree to JustAct being my ADR provider and agree to
                      JustAct{" "}
                      <HyperLink
                        onClick={() => {
                          window.open(
                            setting?.rulesDocumentUrl ||
                              "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/13/1620883614227-f4dbb5a2-4d9c-4a80-8645-b3500816dead/JustAct_Rules.pdf"
                          );
                        }}
                      >
                        rules
                      </HyperLink>{" "}
                      of Negotiation, Mediation, Arbitration, as applicable.
                    </CheckBoxLabel>
                  </CheckboxContainer>
                </CheckboxFormControl>
              ) : null}
              <FormControl>
                <Row>
                  <PrimaryOutlinedCTAButton
                    type="button"
                    onClick={() => onFormSubmit(values, null, true)}
                    style={{ width: "47%" }}
                  >
                    Save as Draft
                  </PrimaryOutlinedCTAButton>
                  <PrimaryCTAButton
                    disabled={
                      !values.agreement && caseObj?.subscriptionKind !== "adhoc"
                    }
                    onClick={handleSubmit}
                    style={{ width: "47%" }}
                  >
                    {caseObj?.resolutionKind !== "negotiation"
                      ? "Submit"
                      : "Proceed"}
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
      <AlertDialog isOpen={open} {...{ ...dialogData }} />
    </OuterContainer>
  );
};

export default Index;

const RespondentInputs = ({
  index,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <div key={index}>
      <FormControl style={{ marginBottom: 32 }}>
        <StyledLabel>Respondent Is</StyledLabel>
        <CustomRadioGroup
          radioInputs={[
            { label: "Individual", value: "individual" },
            { label: "Organization", value: "organization" },
          ]}
          name={`respondentKind`}
          values={values}
          handleChange={(e) =>
            setFieldValue(
              `respondentParties[${index}].respondentKind`,
              e?.target?.value
            )
          }
        />
      </FormControl>
      {inputs.map((input, key) => (
        <FormControl
          key={key}
          className={input.type === "number" ? "disabledArrows" : null}
        >
          <FormField
            white
            errors={errors || {}}
            touched={touched || {}}
            {...{
              input,
              values,
              handleBlur,
            }}
            handleChange={(e) => {
              if (input.name === "respondentMobile") {
                if (e?.target?.value?.match(/^[0-9]*$/)) {
                  setFieldValue(
                    `respondentParties[${index}].${input?.name}`,
                    e?.target?.value
                  );
                }
              } else
                setFieldValue(
                  `respondentParties[${index}].${input?.name}`,
                  e?.target?.value
                );
            }}
          />
        </FormControl>
      ))}
    </div>
  );
};

const PaymentAlert = ({
  registrationFee,
  negotiationSuccessFee,
  caseObj,
  payload,
}) => {
  return (
    <UnorderList>
      <li>
        <div>Respondent(s)</div>
        {payload?.respondentParties?.map((el, index) => (
          <div key={index}>
            <div className="data-row">
              <div className="data-field">
                <div className="label">Name</div>
                <div className="value">{el?.name}</div>
              </div>
              <div className="data-field">
                <div className="label">Email</div>
                <div className="value">{el?.email}</div>
              </div>
            </div>
          </div>
        ))}
      </li>
      {caseObj?.subscriptionKind === "adhoc" ? (
        <li>
          <b>
            You will be charged a non-refundable license fee of INR{" "}
            {parseFloat(registrationFee?.total).toFixed(2) || 0.0} for using
            JustAct. The license validity is {registrationFee?.roomValidity}{" "}
            months.
          </b>
        </li>
      ) : (
        <>
          <li>
            {/* {caseObj?.resolutionKind === "arbitration"
              ? "Arbitration"
              : "Registration"}{" "} */}
            Registration Fee -{" "}
            <b
              style={{
                fontFamily: `${theme.fonts.primaryFontExtraBold}`,
              }}
            >
              INR {parseFloat(registrationFee?.total).toFixed(2) || 0.0}
            </b>
          </li>
          <TextAlign>
            (The Registration{" "}
            {/* {caseObj?.resolutionKind === "arbitration"
              ? "Arbitration"
              : "Registration"}{" "} */}
            Fee will be added to the cart)
          </TextAlign>
          {/* {caseObj?.resolutionKind !== "arbitration" && (
            <li>
              Administration Fee -{" "}
              <b
                style={{
                  fontFamily: `${theme.fonts.primaryFontExtraBold}`,
                }}
              >
                INR {parseFloat(negotiationSuccessFee?.total).toFixed(2)}
              </b>
            </li>
          )}
          <TextAlign>
            {caseObj?.resolutionKind !== "arbitration" && (
              <>
                (If the case is{" "}
                {caseObj?.resolutionKind === "negotiation"
                  ? "negotiated"
                  : caseObj?.resolutionKind === "mediation"
                  ? "mediated"
                  : "arbitrated"}{" "}
                successfully you need to pay the Success Fee for getting the
                settlement agreement)
                <br />
              </>
            )}
            (Claim amount, once submitted, may be changed only if feasible. Any
            difference in fee will need to be paid in such a case)
          </TextAlign> */}
        </>
      )}
      {caseObj?.subscriptionKind === "adhoc" ? (
        <li>
          <b>
            You will also need to pay the{" "}
            {caseObj?.resolutionKind === "mediation"
              ? "mediator"
              : "arbitrator"}{" "}
            as per the terms agreed between the parties and the{" "}
            {caseObj?.resolutionKind === "mediation"
              ? "mediator"
              : "arbitrator"}
            . JustAct will not be responsible for any non-payment in this
            regard.
          </b>
        </li>
      ) : (
        ""
      )}
      {caseObj?.subscriptionKind === "adhoc" ? (
        <TextAlign>
          (Claim amount, once submitted, may be changed only if feasible. Any
          difference in fee will need to be paid in such a case)
        </TextAlign>
      ) : (
        ""
      )}
    </UnorderList>
  );
};

const TextAlign = styled.text`
  text-align: left;
`;

const TextColor = styled.text`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
`;

const UnorderList = styled.ul`
  padding: 0px 10px;
  & .data-row {
    display: flex;
    & .data-field {
      padding: 10px 0px;
      font-family: ${theme.fonts.primaryFontSemiBold};
      & .label {
        color: ${COLORS.INPUT_LABEL};
        font-size: 8px;
      }
      & .value {
        font-size: 10px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    @media ${theme?.breakpoints?.sm_up} {
      & .data-field {
        & .label {
          color: ${COLORS.INPUT_LABEL};
          font-size: 10px;
        }
        & .value {
          font-size: 14px;
        }
        &:nth-child(2) {
          margin-left: 30px;
        }
      }
    }
  }
  & li {
    padding: 5px;
  }

  & .adhoc-pricing {
    text-align: center;
  }
`;
