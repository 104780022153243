import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../../common/Drawer";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import queryString from "query-string";
import {
  getErrorMessage,
  parseTimeStamp
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles, Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ArbitrationDrawer from "./ArbitrationDrawer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HttpsIcon from "@material-ui/icons/Https";
import PublicIcon from "@material-ui/icons/Public";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DrawerRecording from "../ViewRecording/ViewRecording";
import HearingBundles from "../HearingBundles/index";
import DocTypeChnage from "../DocumentTypeChange/DocTypeChange";
import HttpsLockOpen from "@material-ui/icons/LockOpen";
import DocVisibilityTypeChange from "../../internal/DocVisibilityTypeChange/index";

const docTypes = [
  { ownerType: "claimant", title: "Claimant Documents" },
  { ownerType: "respondent", title: "Respondent Documents" },
  { ownerType: "common", title: "Neutral's Documents" }
];

async function getCaseDocument(id, query) {
  try {
    const response = await CaseService.getcaseDocuments(id, query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const Index = ({ state, setState, id, type, agentRole }) => {
  return (
    <Drawer {...{ state }} setState={() => {}} label="Drawer">
      <Table {...{ setState, id, type, agentRole }} />
    </Drawer>
  );
};

export default Index;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Table = ({ setState, id, type, agentRole }) => {
  return (
    <div>
      <Header>
        <Heading style={{ marginBottom: "unset" }}>Case Documents</Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => {
              setState(false);
            }}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        {docTypes
          .filter(item =>
            type === "negotiation" ? item.ownerType !== "mediator" : true
          )
          .map((item, index) => (
            <div key={index}>
              <TableWithAccordion {...item} {...{ id, setState, agentRole }} />
            </div>
          ))}
        {type === "arbitration" ? (
          <ArbitrationDrawer
            title="Template Agreements and Awards"
            {...{ id, setState }}
          />
        ) : (
          ""
        )}
        {id ? <HearingBundles title="Hearing Bundles" {...{ id }} /> : ""}
        {type !== "negotiation" ? (
          <DrawerRecording title="View Recordings" {...{ id, setState }} />
        ) : (
          ""
        )}
      </AccordionContainer>
    </div>
  );
};

const TableWithAccordion = ({ id, title, ownerType, setState, agentRole }) => {
  const classes = useStyles();
  const [result, setResult] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(780));
  const [rowData, setDatas] = useState();
  const [modal, setModal] = useState(false);

  const columns = [
    {
      field: "documentNo",
      title: "Doc ID",
      sorting: false,
      render: rowData => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{ width: 20 }}>
            {rowData?.visibilityType === "me" ? (
              ownerType === agentRole ? (
                <HttpsIcon
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2,
                    cursor: "pointer"
                  }}
                  onClick={() => setModal(true) || setDatas(rowData)}
                />
              ) : (
                <HttpsIcon
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2
                    // cursor: "pointer"
                  }}
                />
              )
            ) : rowData?.visibilityType === "others" ? (
              ownerType === agentRole ? (
                <HttpsLockOpen
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2,
                    cursor: "pointer"
                  }}
                  onClick={() => setModal(true) || setDatas(rowData)}
                />
              ) : (
                <HttpsLockOpen
                  style={{
                    fontSize: 18,
                    fill: COLORS.COLOR_DARK,
                    position: "relative",
                    top: 2
                    // cursor: "pointer"
                  }}
                />
              )
            ) : ownerType === agentRole ? (
              <PublicIcon
                style={{
                  fontSize: 18,
                  fill: COLORS.COLOR_DARK,
                  position: "relative",
                  top: 2,
                  cursor: "pointer"
                }}
                onClick={() => setModal(true) || setDatas(rowData)}
              />
            ) : (
              <PublicIcon
                style={{
                  fontSize: 18,
                  fill: COLORS.COLOR_DARK,
                  position: "relative",
                  top: 2
                  // cursor: "pointer"
                }}
              />
            )}
          </div>
          <div style={{ marginLeft: 10 }}>
            <HyperLink
              onClick={() => window.open(rowData.url?.url, rowData?.name)}
            >
              {rowData.documentNo}
            </HyperLink>
          </div>
        </div>
      ),
      headerStyle: {
        padding: `12px 16px 12px 45px`
      },
      cellStyle: {
        padding: `12px 16px 12px 16px`
      }
    },
    {
      field: "url",
      title: "Document",
      sorting: false,
      render: rowData => (
        <Tooltip
          title={
            rowData?.url?.url?.split("/")[
              rowData?.url?.url?.split("/").length - 1
            ]
          }
          placement="top"
        >
          <HyperLink
            onClick={() => window.open(rowData.url?.url, rowData?.name)}
            style={{
              maxWidth: isBigScreen ? "150px" : "auto"
            }}
          >
            {rowData?.name || "-"}
          </HyperLink>
        </Tooltip>
      ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "owner.name",
      title: "Submitted By",
      sorting: false,
      render: rowData => <StyledTitle>{rowData.owner.name}</StyledTitle>,
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "date",
      title: "Date",
      sorting: false,
      render: rowData => (
        <StyledTitle>
          <div>
            {rowData.created_at &&
              moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          <div>
            {rowData.created_at &&
              moment(parseTimeStamp(rowData.created_at)).format("hh:mm A")}
          </div>
        </StyledTitle>
      ),
      headerStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      },
      cellStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      }
    },
    {
      field: "time",
      title: "Document Type",
      sorting: false,
      render: rowData =>
        rowData?.entity?.resolutionKind === "arbitration" ? (
          rowData?.url?.uploadType === "Arbitration Notice" ? (
            <StyledTitle
              style={{ paddingLeft: "25px", color: COLORS.BOX_OLIVE }}
            >
              {rowData?.url?.uploadType || "other"}
            </StyledTitle>
          ) : (
            <DocTypeChnage
              {...{ rowData, setState, ownerType, agentRole }}
              docName={rowData?.url?.uploadType}
            />
          )
        ) : (
          <StyledTitle style={{ paddingLeft: "25px" }}>
            {rowData?.url?.uploadType || "other"}
          </StyledTitle>
        ),
      headerStyle: {
        maxWidth: 200,
        width: 200,
        padding: `12px 16px`
      },
      cellStyle: {
        maxWidth: 200,
        width: 200,
        padding: `12px 16px`
      }
    }
  ];

  const data = query =>
    new Promise(resolve => {
      let payload = {
        page: query.page + 1,
        perPage: 10
      };
      if (ownerType) {
        if (ownerType === "claimant") {
          payload.claimantView = true;
        } else if (ownerType === "respondent") {
          payload.respondentView = true;
        } else {
          payload.commonView = true;
        }
      }
      const stringParams = `?${queryString.stringify(payload)}`;
      getCaseDocument(id, stringParams)
        .then(async result => {
          if (result) {
            resolve({
              data: result.data
            });
            setResult(result);
          }
        })
        .catch(error => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error"
          });
          setResult({
            data: [],
            lastPage: 1,
            page: 1,
            perPage: 10,
            total: 0
          });
          resolve({
            data: [],
            page: 0,
            total: 0
          });
        });
    });

  return (
    <>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading style={{ marginLeft: 24 }}>
            {title} ({result?.total})
          </AccordionHeading>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <CustomTable
              pluralTitle="Documents"
              singularTitle=""
              {...{
                columns,
                data
              }}
              noToolbar
              pageSize={10}
              hidePagination={result.lastPage === 1}
              state={result}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <DocVisibilityTypeChange
        {...{
          modal,
          setModal,
          rowData,
          ownerType,
          agentRole,
          id,
          setState
        }}
      />
    </>
  );
};

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  @media (min-width: 780px) {
    //width: 200px;
    width: auto;
    min-width: 100px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
