import React, { useRef } from "react";
import ReadMoreAndLess from "react-read-more-less";

export default function MoreOrLess({ Paragraph, ...props }) {
  const ref = useRef();
  return (
    <div ref={ref} {...props} >
      {Paragraph}
    </div>
    // <ReadMoreAndLess
    //   ref={ref}
    //   {...props}
    //   charLimit={250}
    //   readMoreText=" see more"
    //   readLessText=" see less"
    // >
    //   {Paragraph}
    // </ReadMoreAndLess>
  );
}
