import React, { useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import PartyService from "../../../services/PartyService";
import { getErrorMessage } from "../../../helpers/functions";
import AgentListModal from "../../common/AgentListModal/AgentListDrawer"
import {
  BasicDetailsLabel,
  FieldWrapper,
  BasicDetailsWrapper,
  BasicDetailsValue,
  Row,
  HyperLink,
  Padding,
  BasicDetailsItem,
  Container,
  Address,
  PartyTable,
  Heading,
  MobileScreen,
  BigScreen,
} from "./styles";

import { StyledLabelAgent } from "../../internal/CreateParty/styles";
import { basicLabels, documentLabels } from "./constants";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import useUser from "../../../hooks/useUser";
import { states } from "../../../helpers/constants";
import { navigate } from "@reach/router";
import CasesTable from "../../common/CasesTable";


function getAgentName(agents, isPrimary) {
  const agentList = isPrimary
    ? agents?.filter((el) => el.role === "primary")
    : agents?.filter((el) => el.role !== "primary");
  if (agentList?.length) {
    let final = [];
    for (const agent of agentList) {
      if (agent?.user?.name) {
        final.push(agent.user.name);
      }
    }
    return final.join(", ");
  }
}

const Index = ({ party, setParty, partyId, createAgent = () => { } }) => {
  const initialState = [_.startCase(labels.my_parties)];
  const [breadcrumbs, setBreadcrumbs] = useState(initialState);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { userObject } = useUser();
  const [isPrimaryAgent, setIsPrimaryAgent] = useState();
  const [primaryAgentList, setPrimaryAgentList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const MTRef = useRef();

  const [opendrawer, setOpenDrawer] = useState(false);
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_parties:
        navigate("/dashboard/parties");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function getParty(partyId) {
      setLoader({ state: true, message: "Fetching party details..." });
      try {
        const party_response = await PartyService.getPartyOrDraft(partyId);
        if (party_response) {
          setParty(party_response);
          setPrimaryAgentList(getAgentName(party_response?.agents, true));
          setAgentList(getAgentName(party_response?.agents, false));
          const currentAgent = _.find(
            party_response?.agents,
            (agent) => agent.userId === userObject.id
          );
          if (currentAgent?.role === "primary") {
            setIsPrimaryAgent(true);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (partyId && !_.isEmpty(userObject)) {
      getParty(partyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId, userObject]);

  useEffect(() => {
    if (!_.isEmpty(party)) {
      setBreadcrumbs([...initialState, party.name]);
      setPrimaryAgentList(getAgentName(party?.agents, true));
      setAgentList(getAgentName(party?.agents, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [party]);

  const actions = [
    {
      text: labels["actions.add_agents"],
      props: {
        onClick: createAgent,
      },
    },
    {
      text: "Edit Details",
      props: {
        onClick: () => navigate(`/dashboard/parties/draft/${partyId}`)
      }
    },
  ];
  return (
    <div>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
        actions={isPrimaryAgent ? actions : []}
      />
      <Padding>
        <Container>
          <Heading>Party Details</Heading>
          <BasicDetailsWrapper>
            {_.keys(basicLabels).map((key) => (
              <BasicDetailsItem key={key}>
                <BasicDetailsLabel>
                  {key === "documentNumber"
                    ? documentLabels[
                    party?.documentKind === "aadhar"
                      ? "aadhaar"
                      : party?.documentKind
                    ]
                    : basicLabels[key]}
                </BasicDetailsLabel>
                <BasicDetailsValue
                  transform={
                    key === "kind" || key === "name"
                      ? "capitalize"
                      : "uppercase"
                  }
                >
                  {party[key]}
                </BasicDetailsValue>
              </BasicDetailsItem>
            ))}
          </BasicDetailsWrapper>

          <BasicDetailsWrapper>
            <MobileScreen>
              {primaryAgentList?.length > 0 && (
                <BasicDetailsItem>
                  <BasicDetailsLabel>
                    {party?.agents?.length}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>
                    {getAgentName(party?.agents, true)}
                  </BasicDetailsValue>
                </BasicDetailsItem>
              )}
              <BasicDetailsItem>
                <BasicDetailsLabel>
                  {labels["party.documents"]}
                </BasicDetailsLabel>
                <Row>
                  <HyperLink onClick={() => window.open(party.documentUrl)}>
                    {
                      documentLabels[
                      party?.documentKind === "aadhar"
                        ? "aadhaar"
                        : party?.documentKind
                      ]
                    }
                  </HyperLink>
                </Row>
                <Row>
                  {party.authorizationLetterUrl ?
                    <HyperLink onClick={() => window.open(party.authorizationLetterUrl)}>
                      {labels.authorisation_letter}
                    </HyperLink>
                    : ""}
                </Row>
              </BasicDetailsItem>
              {party.gstNumber && (
                <BasicDetailsItem>
                  <BasicDetailsLabel>
                    {labels["party.gstNumber"]}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{party.gstNumber}</BasicDetailsValue>
                </BasicDetailsItem>
              )}
            </MobileScreen>
          </BasicDetailsWrapper>

          <Heading>Address</Heading>
          {party.houseNumber && (
            <Row>
              <Address>
                <FieldWrapper>
                  <BasicDetailsLabel>{labels["user.house"]}</BasicDetailsLabel>
                  <BasicDetailsValue>{party.houseNumber}</BasicDetailsValue>
                </FieldWrapper>
                <FieldWrapper>
                  <BasicDetailsLabel>
                    {labels["user.locality"]}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{party.locality}</BasicDetailsValue>
                </FieldWrapper>
                <FieldWrapper>
                  <BasicDetailsLabel>
                    {labels["user.landmark"]}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{party.landmark}</BasicDetailsValue>
                </FieldWrapper>
                <FieldWrapper>
                  <BasicDetailsLabel>{labels["user.city"]}</BasicDetailsLabel>
                  <BasicDetailsValue>{party.city}</BasicDetailsValue>
                </FieldWrapper>
                <FieldWrapper>
                  <BasicDetailsLabel>{labels["user.state"]}</BasicDetailsLabel>
                  <BasicDetailsValue>
                    {
                      states.find((state) => state.value === party?.state)
                        ?.label
                    }
                  </BasicDetailsValue>
                </FieldWrapper>
                <FieldWrapper>
                  <BasicDetailsLabel>
                    {_.upperCase(labels["user.pin"])}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{party.pin}</BasicDetailsValue>
                </FieldWrapper>
              </Address>
            </Row>
          )}
          <BigScreen>
            <BasicDetailsWrapper>
              {primaryAgentList?.length > 0 && (
                <FieldWrapper style={{ marginTop: 30 }}>
                    <StyledLabelAgent tooltip="An agent with special rights who can create other Agents for their Party">
                      <BasicDetailsLabel>
                        {labels["party.primary_agents"]}
                      </BasicDetailsLabel>
                    </StyledLabelAgent>
                    {/* <BasicDetailsLabel>
                      {party?.agents?.length > 0 ? (
                        <HyperLink
                          style={{ display: "table" }}
                          onClick={() => setOpenDrawer((a) => ({ ...a, state: true }))}
                        >
                          Agent Details
                        </HyperLink>
                      ) : null}
                    </BasicDetailsLabel> */}
                  <BasicDetailsValue>
                    {getAgentName(party?.agents, true)}
                  </BasicDetailsValue>
                </FieldWrapper>
              )}
              <FieldWrapper style={{ marginTop: 50 }}>
                <BasicDetailsLabel>
                  {labels["party.documents"]}
                </BasicDetailsLabel>
                <Row>
                  <HyperLink onClick={() => window.open(party.documentUrl)}>
                    {
                      documentLabels[
                      party?.documentKind === "aadhar"
                        ? "aadhaar"
                        : party?.documentKind
                      ]
                    }
                  </HyperLink>
                </Row>
                <Row>
                  {/* {party.authorizationLetterUrl ?
                    <HyperLink onClick={() => window.open(party.authorizationLetterUrl)}>
                      {labels.authorisation_letter}
                    </HyperLink>
                    : ""} */}
                  {party?.agents?.length > 0 ? (
                      <HyperLink
                        style={{ display: "table" }}
                        onClick={() => setOpenDrawer((a) => ({ ...a, state: true }))}
                      >
                        Agent Details
                      </HyperLink>
                    ) : null}
                </Row>
              </FieldWrapper>
              {party.gstNumber && (
                <FieldWrapper>
                  <BasicDetailsLabel>
                    {labels["party.gstNumber"]}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{party.gstNumber}</BasicDetailsValue>
                </FieldWrapper>
              )}
            </BasicDetailsWrapper>

            <BasicDetailsWrapper>
              {agentList?.length > 0 && (
                <FieldWrapper>
                  <BasicDetailsLabel>
                    {labels["party.agents"]}
                  </BasicDetailsLabel>
                  <BasicDetailsValue>{agentList}</BasicDetailsValue>
                </FieldWrapper>
              )}
            </BasicDetailsWrapper>
          </BigScreen>
        </Container>
        <PartyTable>
          <BasicDetailsLabel style={{ marginBottom: 8 }}>
            {labels["party.cases_associated"]}
          </BasicDetailsLabel>
          <CasesTable partyId={partyId} />
        </PartyTable>
      </Padding>
      <AgentListModal {...{ opendrawer, setOpenDrawer, MTRef, partyList: [{ party }],displayAuthUrl:true }} />

    </div>

  );
};

export default Index;
