import { makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/Colors';
import themes from '../../../assets/theme';
import {H2, PrimaryStyledButton} from './Styles'
import { navigate } from '@reach/router';



const useStyles = makeStyles((theme)=>({

    subheading:{
        color:COLORS.BTN_GREEN,
        fontSize:"18px"
    },
    content:{
        fontFamily:themes.fonts.primaryFontBold,
        color:COLORS.COLOR_DARK,
        fontSize:"14px",
    }
}))
function TheManagementTeam(props) {
    const classes=useStyles();
    return (
        
        <ContainerPage>
            <H2 style={{textAlign:"center",paddingTop:"50px"}}>
                Management Team
            </H2>
                <ProfileContainer>
                <ProfileBox>
                <ProfileImage>
                <ManagementImg src={require("../../../assets/images/prasanth soni.jpg")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Prashant Soni</h4> <span className={classes.content}>Co-Founder & CEO</span>
                </ProfileName>
                </ProfileBox> 
                
                <ProfileBox>
                <ProfileImage>
                <ManagementImg src={require("../../../assets/images/Shivani Verma.jpg")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Shivani Verma</h4><span className={classes.content}>Legal Operations</span> 
                </ProfileName>
                </ProfileBox>

                <ProfileBox>
                <ProfileImage>
                <ManagementImg src={require("../../../assets/images/Venkatesh.png")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Venkatesh Guruprasad</h4> <span className={classes.content}>Software Development</span>
                </ProfileName>
                </ProfileBox> 

                <ProfileBox>
                <ProfileImage>
                <ManagementImg src={require("../../../assets/images/Madhav pudipeddi.jpg")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Madhav Pudipeddi</h4> <span className={classes.content}>Client Services</span>
                </ProfileName>
                </ProfileBox> 
                </ProfileContainer>  
                <PrimaryStyledButton style={{width:"100px", margin:"auto"}} onClick={()=> navigate("/management-team")}> See more</PrimaryStyledButton>
                </ContainerPage>

    );
}

export default TheManagementTeam;

const ManagementImg=styled.img`
height:200px;
border-radius:50%;
margin:auto;
`
const ProfileBox=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
width:300px;

@media(max-width:800px){
    flex-direction:column;
    text-align:center;
}
`
const ProfileImage=styled.div`
display:flex;
height:250px;
width:250px;
margin:auto;
align-items:center;
`
const ProfileName=styled.div`
display:flex;
flex-direction:column;
font-size:18px;
padding:20px;
text-align:center;
`
const ProfileContainer=styled.div`
display:flex;
flex-wrap:wrap;
justify-content:center;
`
const ContainerPage=styled.div`
display:flex;
flex-direction:column;

`