import { navigate } from "@reach/router";
import React from "react";
import FormField from "../../../common/FormField/FormField";
import { HyperLink } from "../../../common/styled-components";
import { StyledFormElementsWrapper } from "../Styles";

function PartySelect({
  values,
  handleChange,
  errors,
  handleSubmit,
  touched,
  handleBlur,
  setFieldValue,
  menuItems,
}) {
  return (
    <>
      <StyledFormElementsWrapper style={{ marginBottom: 15, marginTop: 15 }}>
        <div className="row">
          <FormField
            white
            input={{
              type: "select",
              name: "claimantPartyId",
              label: "Select a Claimant Party",
              required: true,
            }}
            showId
            menuItems={menuItems}
            {...{
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
            }}
          />

          <HyperLink
            className="link"
            onClick={() => navigate(`/dashboard/parties/create/${values.resolutionKind}`)}
          >
            If you do not have a party, please click here to create a party
          </HyperLink>
        </div>
      </StyledFormElementsWrapper>
    </>
  );
}

export default PartySelect;
