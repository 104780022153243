import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BundleName from "./BundleName";
import { ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core";
import BundleDrawer from "./BundleDrawer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function TableWithAccordion({ id, title }) {
  const classes = useStyles();
  const [openBundleModal, setOpenBundleModal] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(e => !e)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionHeading
            style={{ marginLeft: 24 }}
            onClick={() => setExpanded(e => !e)}
          >
            {title}
          </AccordionHeading>
          <Actions>
            <HyperLink onClick={() => setOpenBundleModal(true)}>
              Add Bundle
            </HyperLink>
          </Actions>
        </ExpansionPanelSummary>
        <div
          style={{
            paddingLeft: "25px",
            paddingBottom: "20px",
            overflow: "auto"
          }}
        >
          <BundleDrawer
            id={id}
            setRefreshDetails={setRefreshDetails}
            refreshDetails={refreshDetails}
          />
        </div>
      </ExpansionPanel>
      <BundleName
        id={id}
        setRefreshDetails={setRefreshDetails}
        refreshDetails={refreshDetails}
        {...{ openBundleModal, setOpenBundleModal }}
        heading="Enter Bundle Name"
        btnText="Enter Bundle Name"
      />
    </>
  );
}
export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;

const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;

const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  margin-bottom: 15px;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const Heading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  flex: 1;
  color: ${COLORS.COLOR_DARK};
  text-transform: capitalize;
`;
