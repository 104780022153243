import { makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import themes from "../../../assets/theme";
import Footer from "../LandingPage/Footer";
import { Header } from "../../internal/Header/Header";
import { H2 } from "./Styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "70%",
    textAlign: "center",
    padding: "10px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    "@media(max-width:700px)":{
      width:"90%"
    }
  },
  heading: {
    color: COLORS.BTN_GREEN,
    fontSize: "18px",
    margin: "auto",
  },
  subheading: {
    color: COLORS.COLOR_DARK,
    fontSize: "14px",
    margin: "auto",
  },
  content: {
    font: themes.fonts.primaryFontRegular,
    fontSize: "14px",
  },
}));
function ManagementTeam(props) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header>
        <H2 style={{ textAlign: "center", paddingTop: "30px" }}>
          Management Team
        </H2>
        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/prasanth soni.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Prashant Soni</h2>
              <h2 className={classes.subheading}>Co-Founder & CEO</h2>
              <br />
              <Typography className={classes.content}>
                An MBA from IIM Calcutta, Prashant did his Mechanical Engineering at SCRA Jamalpur (Railways) and IIT Bombay. He has worked in leadership roles as the CEO of Sundaram BNP Paribas Fund Services, the Chief Process Officer of CAMS and Executive Vice President, Operations for UTI Mutual Fund, before joining JustAct. 
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/Shivani Verma.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Shivani Verma</h2>
              <h2 className={classes.subheading}>
                {" "}
                Legal Operations
              </h2>
              <br />
              <Typography className={classes.content}>
                Shivani is a law graduate from Gujarat National Law University and has over a decade of rich and diverse post qualification experience that includes working as a Corporate Lawyer and a practising advocate at the High Court and District Courts. She has also worked with the Ministry of Petroleum & Natural Gas as a  legal consultant.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/Venkatesh.png")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Venkatesh Guruprasad</h2>
              <h2 className={classes.subheading}>
                 Software Development
              </h2>
              <br />
              <Typography className={classes.content}>
                 A double MS holder from the University of Waterloo, Canada and the State University of New York, Albany, USA, Venkatesh also holds a Bachelor's degree from BITS Pilani. He has over 6 years of work experience in various research labs and industries across the world prior to joining JustAct.
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>

        <Paper className={classes.paper}>
          <ProfileBox>
            <ProfileImage>
              <ManagementImg
                src={require("../../../assets/images/Madhav pudipeddi.jpg")}
                alt="Director"
              />
            </ProfileImage>
            <ProfileText>
              <h2 className={classes.heading}>Madhav Pudipeddi</h2>
              <h2 className={classes.subheading}>
                Client Services 
              </h2>
              <br />
              <Typography className={classes.content}>
                A Masters in Subsea Engineering from University of Strathclyde, Madhav has done his Bachelors in Marine Engineering from BITS Pilani. Madhav has over a decade of experience, having worked in various industries ranging from shipping, oil and gas, chemicals and petrochemicals, and Telecom before joining JustAct. 
              </Typography>
            </ProfileText>
          </ProfileBox>
        </Paper>
        <Footer/>
      </Header>
    </div>
  );
}

export default ManagementTeam;

// const HeadWrap = styled.div`
//   text-align: center;
//   font-size: 36px;
//   padding: 1%;
//   color: ${COLORS.COLOR_DARK};
//   font-family: ${themes.fonts.primaryFontBold};
// `;
const ManagementImg = styled.img`
  height: 200px;
  border-radius: 50%;
`;
const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ProfileImage = styled.div`
  display: flex;
  height: 250px;
  width: 250px;
  justify-content: center;
  align-items: center;
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0 20px 20px 20px;
  text-align: justify;
  align-items: flex-start;
`;
