import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../internal/Header/Header";
import ProfileContainer from "./ProfileContainer";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { capitalize } from "lodash";
import PaginationForItems from "../../common/PaginationForItems";
import _ from "lodash";
import { BigScreen } from "../../internal/Header/Styles";
import CloseIcon from "@material-ui/icons/Close";
import FilterBar from "./FilterBar";
import useSettings from "../../../hooks/useSettings";
import {
  CardsContainer,
  HeadWrap,
  HiddenDiv,
  HorizontalContainer,
  SearchBar,
  SearchBarContainer,
  SearchIcon,
  SubHead,
} from "./Styles";

const useStyles = makeStyles(() => ({
  link: {
    display: "flex",
    margin: "auto",
    fontFamily: theme.fonts.primaryFontRegular,
  },
  icon: {
    margin: "auto 5px",
    width: 20,
    height: 20,
  },
  displayType: {
    display: "flex",
  },
  selectBar: {
    padding: "12px 5px",
    width: "100%",
    border: "2px solid #acb1c2",
    borderRadius: "4px",
    marginTop: "10px",
  },
}));

function handleClick(event) {}
function ProfileScreen(props) {
  //states,styles and refs declaration
  let [currentData, setCurrentData] = useState([]);
  let filterRef = useRef({
    experience: "",
    location: "",
    language: [],
  });

  const [checkedLanguages, setCheckedLanguages] = useState([]);
  const classes = useStyles();
  const neutral = props.neutral;
  const [currentPage, setCurrentPage] = useState(1);
  const { setting, settingsLoading } = useSettings();
  const [profilesPerPage] = useState(10);
  const [filterBar, setFilterBar] = useState("none");
  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = currentData.slice(
    indexOfFirstProfile,
    indexOfLastProfile
  );
  const [inputText, setInputText] = useState("");
  const [locationSelections, setLocationSelections] = useState([]);
  const [languageSelections, setLanguageSelections] = useState([]);
  const experienceSelections = [
    "More than 10 years",
    "More than 20 years",
    "More than 30 years",
  ];
  const data =
    props.neutral === "arbitrators"
      ? setting?.arbitratorsInfo
      : props.neutral === "mediators"
      ? setting?.mediatorsInfo
      : null;

  //Sub function of filter to perform language check.
  const handleCheck = (value) => {
    if (checkedLanguages.includes(value)) {
      setCheckedLanguages((prev) =>
        prev.filter((language) => language !== value)
      );
    } else {
      let finalArray = [];
      // for (let i = 0; i < 2; i++) {
      //   finalArray.push("English");
      // }
      //finalArray.push("English");
      finalArray.push(value);
      setCheckedLanguages(finalArray);
    }
  };

  //Search function
  const searchData = (profile, input) => {
    return (
      _.lowerCase(profile.name).includes(_.lowerCase(input)) ||
      _.lowerCase(profile.qualification).includes(_.lowerCase(input)) ||
      _.lowerCase(profile.location).includes(_.lowerCase(input)) ||
      _.lowerCase(profile.areasOfExpertise).includes(_.lowerCase(input))
    );
  };

  const checker = (arr, target) => {
    return target.every((v) => arr.includes(v));
  };
  // Filter function
  const filters = (profile, filterRef) => {
    return (
      (filterRef.location
        ? _.lowerCase(profile.location).includes(
            _.lowerCase(filterRef.location)
          )
        : 1) &&
      (filterRef.experience
        ? filterRef.experience === "More than 10 years"
          ? profile.yearsOfExperience >= 10
          : filterRef.experience === "More than 20 years"
          ? profile.yearsOfExperience >= 20
          : filterRef.experience === "More than 30 years"
          ? profile.yearsOfExperience >= 30
          : filterRef.experience === "More than 40 years"
          ? profile.yearsOfExperience >= 40
          : 1
        : 1) &&
      (filterRef.language
        ? checker(profile.languagesKnown, filterRef.language)
        : 1)
    );
  };
  //combined filter and search function for final output
  const filterData = (inputText, filterRef) => {
    const filteredData = data?.length
      ? data.filter((profile) => {
          return searchData(profile, inputText) && filters(profile, filterRef);
        })
      : "";
    console.log(filteredData.length);
    inputText || filterRef
      ? setCurrentData(filteredData)
      : setCurrentData(data);
  };
  //To auto populate location options for the filter
  const locationHandler = (data) => {
    let finalArray = [];
    data.map((obj) =>
      finalArray.includes(obj.location)
        ? null
        : obj.location && finalArray.push(obj.location)
    );
    finalArray.sort();
    setLocationSelections(finalArray);
  };
  //To auto populate language options for filter
  const languageHandler = (data) => {
    let finalArray = [];
    data.map((obj) =>
      finalArray.includes(obj.languagesKnown)
        ? null
        : obj.languagesKnown && finalArray.push(obj.languagesKnown)
    );
    finalArray = _.uniqBy(_.flatten(finalArray));
    console.log("language", finalArray);
    finalArray = finalArray.filter(
      (value) => _.lowerCase(value) !== _.lowerCase("English")
    );
    
    setLanguageSelections(finalArray.sort());
  };

  //Necessary changes when data is changed
  useEffect(() => {
    window.scrollTo(0, 0);
    setInputText("");
    filterRef.current = {
      experience: "",
      location: "",
      language: [],
    };
    if (!_.isEmpty(setting)) {
      setCurrentData(data);
      locationHandler(data);
      languageHandler(data);
      setCheckedLanguages([]);
    }
  }, [data, settingsLoading]);

  // to update the language checkbox filter
  useEffect(() => {
    filterRef.current.language = checkedLanguages;
    filterData(inputText, filterRef.current);
    paginate(1);
  }, [checkedLanguages]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <Header>
        <HorizontalContainer>
          {/* SideBar filter for Mobile devices */}
          <FilterBar
            {...{
              classes,
              neutral,
              filterBar,
              filterRef,
              experienceSelections,
              languageSelections,
              locationSelections,
              inputText,
              checkedLanguages,
              handleCheck,
              handleClick,
              setInputText,
              setFilterBar,
              setCheckedLanguages,
              filterData,
              paginate,
            }}
          />
          <BigScreen>
            <FilterBar
              {...{
                classes,
                neutral,
                filterRef,
                experienceSelections,
                languageSelections,
                locationSelections,
                inputText,
                checkedLanguages,
                handleCheck,
                handleClick,
                setInputText,
                setCheckedLanguages,
                filterData,
                paginate,
              }}
            />
          </BigScreen>

          {/* Profiles screen display */}
          <CardsContainer>
            <HeadWrap>{capitalize(props.neutral)}</HeadWrap>
            <HiddenDiv>
              <SearchBarContainer style={{ margin: "10px 0" }}>
                <SearchIcon
                  src={require("../../../assets/images/searchBar.svg")}
                  alt="search_icon"
                />
                <SearchBar
                  placeholder="Name, Expertise or Qualification "
                  value={inputText}
                  onChange={(e) => {
                    setInputText(e.target.value);
                    filterData(e.target.value, filterRef.current);
                    paginate(1);
                  }}
                  autoFocus
                />
                <CloseIcon
                  style={{
                    fontSize: "large",
                    margin: "auto 8px",
                    color: "#acb1c2",
                  }}
                  onClick={() => {
                    setInputText("");
                    filterData("", filterRef.current);
                  }}
                />
              </SearchBarContainer>
              <Button
                onClick={() => {
                  setFilterBar("inline");
                }}
                style={{ marginLeft: "20px", color: COLORS.BTN_GREEN }}
              >
                Filters
              </Button>
            </HiddenDiv>
            {inputText && (
              <SubHead>Showing search results for "{inputText}"</SubHead>
            )}

            {/* <ProfileContainer
              data={currentProfiles}
              neutralType={props.neutral}
            /> */}
            {!settingsLoading ? (
              <ProfileContainer
                settingsLoading={settingsLoading}
                data={currentProfiles}
                neutralType={props.neutral}
              />
            ) : (
              ""
            )}
            {!settingsLoading ? (
              <PaginationForItems
                profilesPerPage={profilesPerPage}
                totalProfiles={currentData.length}
                paginate={paginate}
                page={currentPage}
              />
            ) : (
              ""
            )}
            {/* <PaginationForItems
              profilesPerPage={profilesPerPage}
              totalProfiles={currentData.length}
              paginate={paginate}
              page={currentPage}
            /> */}
          </CardsContainer>
        </HorizontalContainer>
      </Header>
    </div>
  );
}

export default ProfileScreen;
