import { Breadcrumbs, Grid, Paper, Link } from "@material-ui/core";
import React from "react";
import { Header } from "../../internal/Header/Header";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { makeStyles } from "@material-ui/core";
import { capitalize } from "lodash";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonOutlineSharpIcon from "@material-ui/icons/PersonOutlineSharp";
import Footer from "../LandingPage/Footer";
import useSettings from "../../../hooks/useSettings";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingBottom: "10px",
    margin: "auto",
    marginTop: "1%",
    width: "70%",
    backgroundColor: "#ffffff",

    "@media(max-width:700px)": {
      width: "90%",
    },
  },
  image: {
    width: 200,
    height: 200,
    justify: "flex-start",
  },
  img: {
    marginLeft: "20px",
    display: "block",
    marginTop: "30px",
    maxWidth: "100%",
    maxHeight: "120%",
    borderRadius: "5%",
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
    marginRight: "5px",
    fontFamily: "openSans-Regular",
    fontSize: "16px",
  },
  name: {
    fontFamily: "openSans-SemiBold",
    src: "../../assets/fonts/OpenSans-SemiBold.ttf",
    padding: "5px",
    paddingLeft: "0",
    fontSize: "25px",
    color: "#293461",
  },
  subHeading: {
    fontFamily: "openSans-SemiBold",
    src: "../../assets/fonts/OpenSans-SemiBold.ttf",
    padding: "5px",
    paddingLeft: "0",
    fontSize: "20px",
    color: "#293461",
  },
  qualification: {
    color: "#293461",
    paddingTop: "5px",
  },
  lastPosition: {
    color: "gray",
    paddingTop: "5px",
  },
  padAll: {
    paddingTop: "5px",
  },
  container: {
    margin: "20px",
  },
  link: {
    display: "flex",
    margin: "auto",
    fontFamily: theme.fonts.primaryFontRegular,
  },
  icon: {
    margin: "auto 5px auto 0",
    width: 20,
    height: 20,
  },
}));

function handleClick(event) {}
function ProfilePage(props) {
  const { setting } = useSettings();
  const data = props.neutral === "arbitrators" ? setting?.arbitratorsInfo : setting?.mediatorsInfo;
  let profile = data
    ? data.filter((x) => Number(x._id) === Number(props.id))
    : [];
  profile = Object.assign({}, ...profile);
  console.log(profile.image);
  const classes = useStyles();
  return (
    <div>
      <Header>
        <Grid container direction="column" spacing={2}>
          <Grid item justify="center">
            <HeadWrap>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="/"
                  onClick={handleClick}
                  className={classes.link}
                >
                  <HomeIcon className={classes.icon} />
                  JustAct
                </Link>
                <Link
                  color="inherit"
                  href={"/neutral/" + props.neutral}
                  onClick={handleClick}
                  className={classes.link}
                >
                  <PeopleAltIcon className={classes.icon} />
                  {capitalize(props.neutral)}
                </Link>

                <Link color="inherit" className={classes.link}>
                  <PersonOutlineSharpIcon className={classes.icon} />
                  {profile.name}
                </Link>
              </Breadcrumbs>
            </HeadWrap>
          </Grid>

          <Grid item>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  spacing={3}
                  justify="space-evenly"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <div className={classes.image}>
                      <img
                        className={classes.img}
                        // src={require("../../../../public/profileImages/" +
                        //   profile.image)}
                        src={profile.image}
                        alt={profile.name}
                      ></img>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                      <div className={classes.container}>
                        <Grid item xs>
                          <h2 className={classes.name}> {profile.name}</h2>
                          <div className={classes.list}>
                            <div className={classes.lastPosition}>
                              {profile.lastHeldPosition}
                            </div>

                            <div className={classes.padAll}>
                              <b>Qualification:</b> {profile.qualification}
                            </div>

                            <div className={classes.padAll}>
                              <b>Experience:</b> {profile.yearsOfExperience}{" "}
                              years
                            </div>

                            <div className={classes.padAll}>
                              <b>Affiliated Organisation: </b>
                              {profile.affiliatedOrganisation}
                            </div>

                            {/* <div className={classes.padAll}><b>Category: </b>{profile.category}</div> */}

                            {profile.languagesKnown && (
                              <div className={classes.padAll}>
                                <b>Languages Known:</b>{" "}
                                {profile.languagesKnown && typeof profile.languagesKnown !="string" ? profile.languagesKnown.join(", ") : profile.languagesKnown}
                                {/* {profile.languagesKnown.join(", ")} */}
                              </div>
                            )}

                            {profile.location && (
                              <div className={classes.padAll}>
                                <b>Location:</b> {" " + profile.location}
                              </div>
                            )}

                            {profile.areasOfExpertise && (
                              <div className={classes.padAll}>
                                <b>Specialities: </b>
                                {profile.areasOfExpertise}
                              </div>
                            )}

                            {profile.chronicles && (
                              <div className={classes.padAll}>
                                <b>Chronicles: </b>
                                {profile.chronicles}
                              </div>
                            )}

                            {profile.otherInterests && (
                              <div className={classes.padAll}>
                                <b>Other Interests: </b>
                                {profile.otherInterests}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>

          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Header>
    </div>
  );
}
export default ProfilePage;

const HeadWrap = styled.div`
  text-align: center;
  padding-top: 1%;
  padding-left: 2%;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
`;
