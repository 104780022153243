import {
  CircularProgress,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import DashboardSerivces from "../../../services/DashboardSerivces";
import { Count, Row } from "../Header/Styles";
import Items from "./items";
import _ from "lodash";
import queryString from "query-string";
import { navigate, useLocation } from "@reach/router";
import {
  NOTIFICATION_ACTIVITY,
  NOTIFICATION_INTERVAL,
  PAYMENT_PENDING,
} from "../../../helpers/constants";
import labels from "../../../helpers/labels.json";
import useNotification from "../../../hooks/useNotification";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { IconHolder } from "./styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 400,
    padding: "10px 0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    padding: "10px 20px",
  },
}))(MenuItem);

const Notification = ({ count, setCount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // This state is used for indicating the current page
  const [loading, setLoading] = useState(true); // This State for showing the loader for pending action
  const [activities, setActivities] = useState([]); //This state for Pending Action
  const [endPage, setEndPage] = useState();
  const location = useLocation();
  const [triggerCount, setTriggerCount] = useState(true);
  const { setTriggerNotify } = useNotification(); // hooks to refresh the page

  const handleClick = (event) => {
    setActivities([]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentPage(0);
    setEndPage(0);
    setActivities([]);
    setAnchorEl(null);
  };

  useEffect(() => {
    const getPendingAction = () => {
      let params = {
        page: currentPage + 1,
        perPage: 25,
      };
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      DashboardSerivces.getPendingAction(stringParams).then(
        (res) => {
          if (res?.activities) {
            setActivities([...activities, ...res.activities.data]);
            setEndPage(res.activities.lastPage);
          }
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    };
    if (anchorEl) {
      setLoading(true);
      getPendingAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, anchorEl]);

  /**
   * This use Effect for triggering the interval
   */
  useEffect(() => {
    const countInterval = setInterval(() => {
      setTriggerCount(true);
    }, NOTIFICATION_INTERVAL);
    return () => {
      clearInterval(countInterval);
    };
  }, []);

  useEffect(() => {
    async function getCount() {
      try {
        const res = await CaseService.getNotificationCount();
        if (res) {
          setCount(res?.notificationCount);
        }
      } catch (error) {
        console.log("failed");
      }
    }

    if (triggerCount) {
      getCount();
      setTriggerCount(false);
    }
  }, [setCount, triggerCount]);

  useEffect(() => {
    async function clearNotification() {
      try {
        const res = await CaseService.clearNotification();
        if (res?.success) {
          setCount(0);
        }
      } catch (error) {}
    }
    if (anchorEl && count) {
      clearNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  function loadMoreItems(event) {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (endPage && currentPage < endPage - 1) {
        //user is at the end of the list so load more items
        setCurrentPage(currentPage + 1);
      }
    }
  }

  const navigateToAction = ({ kind, entityId, meta }) => {
    setAnchorEl(null);
    if (kind && PAYMENT_PENDING.includes(kind)) {
      navigate("/dashboard/cart");
    } else {
      navigate(
        `/dashboard/cases/${entityId}?caseType=${
          meta?.resolutionKind ? meta?.resolutionKind : "negotiation"
        }`
      );
      if (location.pathname.includes("dashboard/cases")) {
        setTimeout(() => {
          setTriggerNotify(true);
        });
      }
    }
  };

  return (
    <div>
      <Row onClick={handleClick}>
        <IconHolder>
          <NotificationsIcon style={{ cursor: "pointer" }} className="icon" />
        </IconHolder>
        {count ? (
          <Count
            style={{
              color: "#ff3c3c",
              backgroundColor: "rgba(255, 60, 60, 0.2)",
            }}
          >
            {count}
          </Count>
        ) : null}
      </Row>
      <StyledMenu
        onScroll={loadMoreItems}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          {activities?.length ? (
            <div>
              {activities.map((el, index) => (
                <StyledMenuItem
                  key={index}
                  onClick={() => navigateToAction(el)}
                >
                  <Items
                    type={NOTIFICATION_ACTIVITY[el?.kind]?.type}
                    message={`${el.message} ${
                      PAYMENT_PENDING.includes(el.kind)
                        ? labels.pay_here
                        : labels.view_here
                    }`}
                    time={el?.created_at}
                  />
                </StyledMenuItem>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          <div>
            {loading ? (
              <StyledMenuItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <CircularProgress size={20} />
                </div>
              </StyledMenuItem>
            ) : null}
          </div>
        </div>
      </StyledMenu>
    </div>
  );
};

export default Notification;
