import React, { useEffect } from "react";
import { Header } from "../../internal/Header/Header";
import { H2, P, AboutUsBody, GreySection, WhiteSection } from "./Styles";
import labels from "../../../helpers/labels.json";
import Footer from "../LandingPage/Footer";
import OurValues from "./OurValues";
import HowItWorks from "../LandingPage/Sections";
import BoardOfDirectors from "./BoardOfDirectors";
import ManagementTeam from "./TheManagementTeam";
import Partnerships from "./Partnerships";
import { scroller } from "react-scroll";
import OurAdvantages from "./OurAdvantages";

const AboutUs = (props) => {
  console.log(props);
  const section = props.location.hash.slice(1);
  console.log(section);
  useEffect(() => {
    scroller.scrollTo(section, {
      duration: 500,
      offset: -70,
      smooth: true,
    });
  }, [props.location]);

  return (
    <div>
      <Header>
        <AboutUsBody>
          <GreySection id="about-justact">
            <H2 style={{ textAlign: "center" }}>
              {labels["landing.what_is_justact"]}
            </H2>
            <P
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{
                __html: labels["landing.what_content"],
              }}
            />
          </GreySection>

          <WhiteSection id="our-advantages">
            <OurAdvantages />
          </WhiteSection>

          <GreySection id="how-it-works" style={{ paddingTop: "20px" }}>
            <HowItWorks />
          </GreySection>

          <WhiteSection id="our-values">
            <OurValues />
          </WhiteSection>

          {/* <GreySection id="founders">
            <FoundingTeam />
          </GreySection> */}
  
          <WhiteSection id="board-of-directors" style={{ paddingTop: "0" }}>
            <BoardOfDirectors />
          </WhiteSection>
          
          <WhiteSection id="management-team" style={{ paddingTop: "0" }}>
              <ManagementTeam />
          </WhiteSection>

          <GreySection id="partnerships">
            <Partnerships />
          </GreySection>

          {/* <WhiteSection id="contact-us">
            <ContactUs />
          </WhiteSection> */}
        </AboutUsBody>

        <Footer />
      </Header>
    </div>
  );
};
export default AboutUs;
