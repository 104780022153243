import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const AlertDialog = ({
  heading = "Quit Case",
  desc = "Are you sure you want to Quit this case? All the negotiation progress will be lost if you quit case at this point",
  primaryBtnText = "Submit",
  secondaryBtnText = "Close",
  clickSecondarybtn = () => {},
  clickPrimaryBtn = () => {},
  isOpen = false,
  onDialogClose = () => {},
  descriptionTextStyle = {},
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Heading>{heading}</Heading>
        <Description
          style={{
            ...descriptionTextStyle,
          }}
        >
          {desc}
        </Description>
        <ButtonWrapper>
          {secondaryBtnText && (
            <div className="btn">
              <PrimaryOutlinedCTAButton onClick={clickSecondarybtn}>
                {secondaryBtnText}
              </PrimaryOutlinedCTAButton>
            </div>
          )}
          {primaryBtnText && (
            <div className="btn">
              <PrimaryCTAButton onClick={clickPrimaryBtn}>
                {primaryBtnText}
              </PrimaryCTAButton>
            </div>
          )}
        </ButtonWrapper>
      </Dialog>
    </div>
  );
};
export default AlertDialog;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  & .btn {
    width: 100%;
    padding: 10px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    margin: 20px auto 30px;
    flex-direction: row;
    justify-content: center;
    & .btn {
      width: 192px;
      padding: 10px;
    }
  }
`;

const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.COLOR_DARK};
  margin: 20px;
  font-size: 16px;
  text-align: center;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 30px 0px;
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.71;
  color: ${COLORS.COLOR_DARK};
  text-align: center;
  padding: 10px 20px;
  width: 100%;
  min-width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 20px 62px;
    font-size: 14px;
    min-width: 440px;
  }
`;
