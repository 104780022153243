import React, { useEffect } from "react";
import { Content, HeadWrap, SubHeadWrap, BlogPage, BlogImage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function OnlineDisputeBlogSeven(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Blog</HeadWrap>
          <br />
          <SubHeadWrap>Evaluating ADR for resolving women-related disputes  (Part 3 of 3)</SubHeadWrap>
          <br />
          <Content>
            <b style={{ textAlign: "center" }}> Authored by: Nitika </b>
            <br />
            <p>
              Arbitration, mediation, or consultation exemplify Alternative
              Conflict Resolution (ADR) procedures; to resolve conflicts without
              resorting to litigation. ADR methods are typically less expensive
              and quicker. They are becoming more common in cases that would
              otherwise go to court, such as high- profile labor disputes,
              divorce actions, and personal injury proceedings.
            </p>
            <br />
            <p>
              One of the key reasons that parties can favor ADR procedures to
              adversarial litigation is that, unlike adversarial litigation, ADR
              procedures are always constructive and allow the parties to
              consider each other's roles. ADR also encourages the sides to come
              up with more imaginative options that a judge would not be able to
              enforce legally.
            </p>
            <br />
            <SubHeadWrap>
              Advantages of ADR in women related disputes.
            </SubHeadWrap>
            <br />
            <BlogImage
              src={require("../../../assets/images/Nitika.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Time Efficient</SubHeadWrap>
            <br />
            <p>
              The idea that it typically takes much less time to achieve a final
              settlement than if the lawsuit went to trial is one of the
              advantages. An individual who is an authority in the subject area
              may even arbitrate or mediate the parties' conflict. In a typical
              trial containing complex and technical problems that few people
              outside of the related industry understand, a significant amount
              of time must be spent preparing the judge and jury only so they
              can make an educated decision. However, if the arbitrator has an
              experience in the subject area, much less time will be wasted on
              this, and the parties will be able to move on to the substantive
              problems of the case even faster.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Nitika_1.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>Money Efficient</SubHeadWrap>
            <br />
            <p>
              It often usually (but not always) costs considerably less money.
              This considerable time commitment often transforms into a
              significant financial investment. All parties may be forced to
              call specialist experts, who may demand exorbitant payments for
              their time
            </p>
            <br />
            <p>
              Autonomy- Furthermore, in the case of arbitration, the parties
              have much more autonomy in deciding which laws will be applicable
              to their dispute.
            </p>
            <br />
            <p>
              ADRs are being used in a growing number of situations, and the
              public has selected them for their simple and cost-effective
              advantages. Online Dispute Resolution and Gram Nyaylayas are two
              recent inventions in the field of alternative dispute resolution.
              These alternatives will expand ADR's reach in India. Mediation is
              expected to become the most effective in the coming years, as it
              promises a quicker resolution.
            </p>
            <br />
            <ul style={{ paddingLeft: "40px" }}>
              <li>
                Mediation aids with recognizing the point at which the conflict
                occurred and the ways in which each side wishes it to be settled
                in some jurisdictions.
              </li>
              <li>
                Online Dispute Resolution and Gram Nyaylayas are two recent
                inventions in the field of alternative dispute resolution.
              </li>
            </ul>
            <br />
            <SubHeadWrap>
              {" "}
              Disadvantages of ADR in women related disputes
            </SubHeadWrap>
            <br />
            <p>
              It is often claimed that informal justice systems do not
              adequately protect women's rights. In various countries, the
              community leaders involved in informal dispute resolution are
              overwhelmingly older males, prompting criticism that “social
              hierarchies and inequalities are often reflected and reinforced”
              in dispute resolution systems.
            </p>
            <br />
            <p>
              This is especially troublesome if the emphasis on compromise and
              agreement leads to pressure on weaker parties (in this case,
              vulnerable women) to accept settlements that do not actually serve
              their best interests or may prevent them from acquiring their full
              legal rights.
            </p>
            <br />
            <p>
              While an increasing number of studies show that this is often the
              case, it is critical to understand the particulars of how
              different systems operate in different situations – especially for
              those considering whether or not to support various types of
              reforms aimed at helping the most vulnerable.
            </p>
            <br />
            <p>
              According to the Mediation Boards Act of 1987 and subsequent
              amendments, 8 women and men are legally obligated to use these
              processes to resolve a wide range of civil and criminal disputes,
              including assault, family disputes (including domestic violence),
              and more. primarily physical and often sexual violence), land and
              property disputes, and commercial transaction disputes, and to try
              to reach voluntary agreements before the cases are brought to a
              formal court of law.
            </p>
            <br />
            <p>
              Mediators seek to get to the "root cause" of the dispute and try
              to promote a mutually agreeable settlement that respects the
              interests of all disputing parties, according to the
              interest-based mediation procedure used by mediation boards. In
              contrast to rights, needs, or power-based approaches to conflict
              resolution, this method aims to be non-adversarial and
              collaborative.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Nitika_2.png")}
              alt="Independent"
            />
            <br />
            <SubHeadWrap>
              The Potential for Gender Bias Power in Mediation
            </SubHeadWrap>
            <br />
            <p>
              The parties to the mediation must start from equal positions of
              authority in order for the mediation to be successful. Economic,
              intellectual, physical, mental, and procedural power are all forms
              of power. However, many women are stuck in power imbalanced
              relationships, whether they are family, work-related, or
              contractual. Mediation in the face of these power imbalances calls
              into question the claim that mediation allows parties more control
              and self-determination than conventional lawsuits. The effect of
              power imbalances on mediation has received the most attention in
              the context of intimate relationships. The relationship between an
              abused woman and her abuser, for example, revolves around themes.
            </p>
            <br />
            <p>
              of power and control. The abuser uses a variety of tactics to
              create and maintain control over his victim throughout their
              relationship, ranging from isolation and mental and financial
              abuse to intimidation, coercion, threats, and physical violence.
              Furthermore, the abuser will regularly blame the victim for his
              abuse, leading her to think that if she conforms to his
              expectations, the abuse will cease. In an abusive relationship,
              the abuser has all of the power: legal, psychological, physical,
              and mental. It's impossible to expect him to give up that
              authority during the mediation process. As her abuser glares at
              her from across the table, the abused woman's concern for her
              physical safety may prevent her from feeling comfortable
              mediating.
            </p>
            <br />
            <p>
              The victim may feel pressured to settle or negotiate, believing
              that if she lowers her demands, the abuse (this time, the abuse
              associated with the mediation process) will stop. The abuser may
              change his demands repeatedly during negotiations to show the
              victim that he still has the authority to shape the agreement, and
              thus her life, according to his wishes. And here the objective of
              mediation gets fully destroyed.
            </p>
            <br />
            <ul style={{ listStyleType: "square", paddingLeft: "40px" }}>
              <li>
                When there is a fear of getting attacked in the process of
                mediation then what is the significance of it.
              </li>
              <li>
                When the victim needs to compromise out of fear then what is the
                use of this so-called mediation process.
              </li>
              <li>
                When the victim doesn’t get the chance to mediate fairly, then
                how can be this process useful. This is denying the human and
                natural rights of women.
              </li>
            </ul>
            <br />
            <SubHeadWrap>Conclusion</SubHeadWrap>
            <br />
            <p>
              Instead, the aim of mediation is to find areas of agreement
              between the parties; indignation is seen as unnecessary when the
              goal is to reach a consensus. However, the start of mediation may
              be the first time the woman in the mediation has been able to
              express her displeasure at her husband, her boss, or her
              adversary. When conventional societal prohibitions on female anger
              are combined with mediation's devaluation of anger, it's possible
              that mediators could silence women who are expressing these
              feelings for the first time, preventing women from achieving their
              goals through mediation.
            </p>
            <br />
            <p>
              Judges are responsible for ensuring that women are treated equally
              in mediation. First, judges, like all system actors, must be aware
              of the possibility of gender bias not only in their own courts,
              but also in the systems to which they refer female litigants. It
              is critical to receive training on women's issues, particularly
              the difficulties that mediation can cause for some women.
            </p>
            <br />
            <p>
              Alternative dispute resolution (ADR), which promises faster,
              cheaper, and more empowering interactions with the legal system,
              is required in a number of situations involving women. Although
              some women will be able to mediate efficiently, and some men
              engaged in mediation will be sensitive to the concerns raised in
              this article, the possibility of gender bias in the media is
              undoubtedly present. Failure to ensure that mediation not only
              treats women equally but also meets their specific needs would
              lead to alienation from alternative dispute resolution and,
              eventually, the legal system.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogSeven;
