import React from "react";
import { Router } from "@reach/router";
import SignupPage from "./components/pages/SignupPage";
import LoginPage from "./components/pages/LoginPage";
import OtpPage from "./components/pages/OtpPage";
import AccountVerificationPage from "./components/pages/AccoutVerificationPage";
import Createprofile from "./components/pages/CreateProfile";
import Welcome from "./components/pages/Welcome";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import ChangePassword from "./components/pages/ChangePassword";
import Parties from "./components/pages/Parties";
import Cases from "./components/pages/Cases";
import MyProfile from "./components/pages/MyProfile";
import PaymentsPage from "./components/pages/PaymentsPage";
import HelpPage from "./components/pages/HelpPage";
import MyParties from "./components/internal/MyParties/";
import MyCases from "./components/pages/MyCases/";
import CreatePartyPage from "./components/pages/CreatePartyPage/";
import PartyDetailsPage from "./components/pages/PartyDetailsPage";
import Dashboard from "./components/pages/Dashboard";
import MyDashboard from "./components/internal/MyDashboard/";
import DashboardScreens from "./components/pages/DashboardScreens";
import AccountVerificationParent from "./components/pages/AccoutVerificationPage/AccountVerificationParent";
import NotVerifiedPage from "./components/pages/NotVerifiedPage";
import LoginPath from "./components/pages/LoginPage/LoginPath";
import LoginOTP from "./components/pages/LoginOTP";
import CreateCase from "./components/internal/CreateCase";
import CreateCase2 from "./components/internal/CreateCase2";
import CreateCase3 from "./components/pages/CreateCase3";
import CaseRequests from "./components/internal/CaseRequests";
import CaseCart from "./components/internal/CaseCart";
import CaseInvites from "./components/internal/CaseInvites";
import Help from "./components/internal/Help";
import ProfileParent from "./components/pages/MyProfile/components/Parent";
import CasesBulkCreate from "./components/pages/CasesBulkCreate";
import DemoPage from "./components/pages/DemoPage";
import RatePlatform from "./components/pages/RatePlatform";
import NegotiationPages from "./components/pages/NegotiationPages";
import AwaitingRespondantPage from "./components/pages/NegotiationPages/AwaitingRespondantPage";
import LandingPage from "./components/pages/LandingPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import ProfilePage from "./components/pages/ProfilePage/ProfilePage";
import ProfileScreen from "./components/pages/ProfileScreen";
// import Training from "./components/pages/Training/Training";
// import Resources from "./components/pages/Resources/Resources";
import AboutUs from "./components/pages/AboutUs/AboutUs";
// import NegotiationRules from "./components/pages/Rules/NegotiationRules";
// import MediationRules from "./components/pages/Rules/MediationRules";
// import NegotiationClauseRules from "./components/pages/Rules/NegotiationClauseRules.js";
// // import ModelClauseRules from "./components/pages/Rules/ModelClauseRules.js";
// import MediationClauseRules from "./components/pages/Rules/MediationClauseRules.js";
// import ArbitrationClauseRules from "./components/pages/Rules/ArbitrationClauseRules.js";
// import ArbitRules from "./components/pages/Rules/ArbitRules.js";
import Directors from "./components/pages/AboutUs/Directors";
import ManagementTeam from "./components/pages/AboutUs/ManagementTeam";
import OnlineDisputeBlog from "./components/pages/Resources/OnlineDisputeBlog";
import OnlineDisputeBlogTwo from "./components/pages/Resources/OnlineDisputeTwo";
import OnlineDisputeBlogThree from "./components/pages/Resources/OnlineDisputeThree";
import OnlineDisputeBlogFour from "./components/pages/Resources/OnlineDisputeFour";
import OnlineDisputeBlogFive from "./components/pages/Resources/OnlineDisputeBlogFive";
import OnlineDisputeBlogSix from "./components/pages/Resources/OnlineDisputeBlogSix";
import OnlineDisputeBlogSeven from "./components/pages/Resources/OnlineDisputeBlogSeven";
import OnlineDisputeBlogEight from "./components/pages/Resources/OnlineDisputeBlogEight";
import OnlineDisputeBlogNine from "./components/pages/Resources/OnlineDisputeBlogNine";
import OnlineDisputeBlogTen from "./components/pages/Resources/OnlineDisputeBlogTen";
import TermsAndCondition from "./components/pages/TermsAndConditions/TermsAndCondition";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import PaymentHistoryPage from "./components/internal/PaymentHistoryPage";
import ProfomaCaseListing from "./components/internal/ProfomaCaseListing";
import NotificationPage from "./components/pages/NotificationPage";
import Notification from "./components/internal/Notification/MobileNotification";
import CreateCasePartyPage from "./components/pages/CreateCasePartyPage";
import GuestUserMeeting from "./components/pages/JoinMeeting.js";
import GuestUserEndMeeting from "./components/pages/EndMeeting.js";
import EndGenericMeeting from "./components/pages/EndGenericMeeting";

const AppRouter = () => {
  return (
    <Router>
      <NotFoundPage default />
      <NotFoundPage
        errorCode="Error Code : 500"
        errorMessage="The server encountered an internal error or misconfiguration and was unable to complete your request."
        path="/internal-error"
      />
      <NotFoundPage
        errorCode="Error Code : 401"
        errorMessage="You do not have the permission to view the pages"
        path="/un-authorized"
        url="/login"
        buttonLabel="Go to Login"
      />
      <LandingPage path="/" />
      <ProfileScreen path="/neutral/:neutral" />
      <ProfilePage path="/neutral/:neutral/:id" />
      {/* <Training path="/training/:id" />
      <Resources path="/resources/:id" /> */}
      <AboutUs path="/aboutus" />
      {/* <NegotiationRules path="/negotiation-rules" />
      <ArbitRules path="/arbitration-rules" />
      <MediationRules path="/mediation-rules" /> */}
      {/* <ModelClauseRules path="/modelclause-rules" /> */}
      {/* <NegotiationClauseRules path="/negotiation-modelclause-rules" />
      <MediationClauseRules path="/mediation-modelclause-rules" />
      <ArbitrationClauseRules path="/arbitration-modelclause-rules" /> */}
      <Directors path="/directors" />
      <ManagementTeam path="/management-team" />
      <OnlineDisputeBlog path="/resources/online-dispute-blog" />
      <OnlineDisputeBlogTwo path="/resources/online-dispute-blog-two" />
      <OnlineDisputeBlogThree path="/resources/online-dispute-blog-three" />
      <OnlineDisputeBlogFour path="/resources/online-dispute-blog-four" />
      <OnlineDisputeBlogFive path="/resources/online-dispute-blog-five" />
      <OnlineDisputeBlogSix path="/resources/online-dispute-blog-six" />
      <OnlineDisputeBlogSeven path="/resources/online-dispute-blog-seven" />
      <OnlineDisputeBlogEight path="/resources/online-dispute-blog-eight"/>
      <OnlineDisputeBlogNine path="/resources/online-dispute-blog-nine"/>
      <OnlineDisputeBlogTen path="/resources/online-dispute-blog-ten" />
      <TermsAndCondition path="/terms-and-conditions" />
      <PrivacyPolicy path="/privacy-policy" />
      <SignupPage path="/signup" />
      <SignupPage isGoogleSignUp path="/google-signup" />
      <LoginPath path="/login">
        <NotFoundPage default />
        <LoginPage path="/" />
        <LoginOTP path="/otp" />
      </LoginPath>
      <ResetPassword path="reset-password/:resetToken" />
      <ForgotPassword path="forgot-password" />
      <OtpPage path="/otp" />
      <Createprofile path="/create-profile" />
      <AccountVerificationParent path="/verify-email">
        <AccountVerificationPage path="/" />
        <AccountVerificationPage path="/:email_token" />
      </AccountVerificationParent>
      <NotVerifiedPage path="/not-verifed" />
      {process.env.NODE_ENV === "development" ? (
        <DemoPage path="/demo-components" />
      ) : null}
      <DashboardScreens path="/dashboard">
        <NotFoundPage default />
        <Welcome path="/welcome" />
        <Dashboard path="/">
          <NotFoundPage default />
          <MyDashboard path="/" />
          <CaseRequests path="/requests" />
          <CaseInvites path="/invites" />
        </Dashboard>
        {/* demo page for components */}
        <Cases path="/cases">
          <NotFoundPage default />
          {/* All Cases ("/"), Create Case ("/create") Routes to Go Here (Similar to parties) */}
          <MyCases path="/" />
          <CasesBulkCreate path="/bulk-create" />
          <CreateCase path="/create" />
          <CreateCase path="/create/:caseId" />
          <CreateCase2 path="/create/:caseId/step-2" />
          <CreateCase3 path="/create/:caseId/step-3" />

          <NegotiationPages path="/:id">
            <NotFoundPage default />
            <AwaitingRespondantPage path="/" />
          </NegotiationPages>
        </Cases>
        <CaseCart path="/cart" />
        <ProfomaCaseListing path="/cart/selected-cases" />
        <Parties path="/parties">
          <NotFoundPage default />
          <MyParties path="/" />
          <PartyDetailsPage path="/:partyId" />
          <CreatePartyPage path="/create" />
          <CreatePartyPage path="/draft/:draftId" />
          <CreateCasePartyPage path="/create/:resolutionKind" />
        </Parties>
        <ProfileParent path="/profile">
          <NotFoundPage default />
          <MyProfile path="/" />
          <ChangePassword path="/change-password" />
        </ProfileParent>
        <PaymentsPage path="/payments">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <PaymentHistoryPage path="/" />
        </PaymentsPage>

        <NotificationPage path="/notification">
          <NotFoundPage default />
          <Notification path="/" />
        </NotificationPage>

        <HelpPage path="/help">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <Help path="/" />
        </HelpPage>
        <RatePlatform path="/rate-platform/:id" />
      </DashboardScreens>
      <GuestUserMeeting path="/joinMeeting/:code" />
      <GuestUserEndMeeting path="/endMeeting/:caseId" />
      <EndGenericMeeting path="/endMeeting" />
    </Router>
  );
};

export default AppRouter;
