import React from "react";
import {
  H2,
  P,
  Section,
  ProductVideos,
  ProductVideoWrapper,
} from "./Styles";
import labels from "../../../helpers/labels.json";

const HowItWorks = () => {
  return (
    <>
      <Section id={`howItWorks`}>
        <H2>{labels["landing.how_title"]}</H2>
        <P style={{textAlign:"justify"}}
          dangerouslySetInnerHTML={{ __html: labels["landing.how_content"] }}
        />
        <ProductVideos>
          <ProductVideoWrapper>
            <iframe
              title="Product Video – English"
              height="286px"
              width="512px"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                border: "solid 0.3px #979797",
              }}
              src="https://www.youtube.com/embed/Ra5eZIe5_80?rel=0&showinfo=0&start=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* <ProductVideoText>Product Video – English</ProductVideoText> */}
          </ProductVideoWrapper>
          <ProductVideoWrapper>
            <iframe
              title="Product Video – Hindi"
              height="286px"
              width="512px"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                border: "solid 0.3px #979797",
              }}
              src="https://www.youtube.com/embed/dsYc3_w0ln8?rel=0&showinfo=0&start=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* <ProductVideoText>Product Video – Hindi</ProductVideoText> */}
          </ProductVideoWrapper>
        </ProductVideos>
      </Section>
    </>
  );
};
export default HowItWorks;
