import React from "react";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { Button, StyledFormElementsWrapper } from "../Styles";

const CaseActionCTA = ({ isBigScreen, onClick, handleSubmit }) => {
  return (
    <>
      <StyledFormElementsWrapper
        style={{
          display: "flex",
          justifyContent: isBigScreen ? "space-between" : "flex-start",
        }}
      >
        <Button>
          <PrimaryOutlinedCTAButton
            style={{
              marginLeft: "unset",
            }}
            onClick={onClick}
          >
            Save As Draft
          </PrimaryOutlinedCTAButton>
        </Button>
        <Button
          style={{
            marginLeft: "22px",
          }}
        >
          <PrimaryCTAButton onClick={handleSubmit}>Proceed</PrimaryCTAButton>
        </Button>
      </StyledFormElementsWrapper>
    </>
  );
};

export default CaseActionCTA;
