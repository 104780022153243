import React, { useEffect } from "react";
import {
  Content,
  HeadWrap,
  SubHeadWrap,
  BlogPage,
  BlogImage,
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function OnlineDisputeBlogTwo(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Blog</HeadWrap>
          <br />
          <SubHeadWrap>
            The scope and relevance of ODR
          </SubHeadWrap>
          <br />
          <Content>
            <i style={{ textAlign: "center" }}>
              Authored by : Shivani Verma, Head of Legal Operations at JustAct.
            </i>
            <br />
            <b style={{ textAlign: "center" }}>
              “There was a time when people felt the internet was another world,
              but now people realise it’s a tool that we use in this world.”
            </b>
            <br />
            <div style={{ textAlign: "center" }}>Tim Berners- Lee </div>
            <br />
            <p>
              These are the wise words of the man who is credited with inventing
              the world wide web, and a rather telling observation by him. We
              can hardly imagine a world now, where technology is viewed as a
              separate discipline, and not part of our daily lives.
            </p>
            <br />
            <p>
              It is safe to say that there has been a pre- internet world and a
              post-internet world, and using technology, the post internet world
              has seen innumerable and unimaginable breakthroughs, Online
              Dispute Resolution (ODR) being one of them. ODR has the power to
              change the landscape of the dispute resolution mechanisms, across
              territories. It has the power to change how humans view resolving
              disputes altogether. It already has, and it further will.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic1.png")}
              alt="Independent"
            />
            <br />
            <p>
              Online Dispute Resolution is different from the traditional court
              systems, and is in fact a parallel justice system, which
              successfully blends information technology and dispute resolution
              mechanisms together in order to resolve disputes efficiently. ODR
              is not just dispute resolution, but dispute prevention process as
              well.
            </p>
            <br />
            <p>
              ODR began its existence as ‘Online ADR’ and was meant to be an
              online equivalent process of offline face to face dispute
              resolution mechanisms such as negotiation, mediation and
              arbitration.[1] Therefore, it is very important to understand the
              origin of both technology and the dispute resolution mechanisms,
              the two pillars on which ODR has developed.
            </p>
            <br />
            <b style={{ textAlign: "center" }}>
              In this context, ‘how’ and ‘why’ questions become relevant.
            </b>
            <br />
            <b style={{ textAlign: "center" }}>
              How has ODR come into picture? Why is it needed at all?
            </b>
            <br />
            <p>
              For understanding ‘How ODR came into being’, it is relevant to
              also understand how the world we are living in has changed since
              the arrival of technology called the Internet and How the concept
              of Alternate Dispute Resolution came into picture, making Online
              ADR a reality.
            </p>
            <br />
            <p>
              As far as development of the Internet goes, early on in 1468 the
              Department of Defence, United Nations had established a Federal
              Advanced Research Projects Agency (ARPA)which was mainly
              responsible for developing technologies for military use.
            </p>
            <br />
            <p>
              The military network was called the ARPANET, and was restricted to
              use only by military.[2] On October 29, 1969, ARPANET delivered
              its first message: a “node-to-node” communication from one
              computer to another, thus getting October 29, 1969 widely known as
              the “Internet Day.”
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic2.png")}
              alt="Independent"
            />
            <br />
            <p>
              In the year 1980, access to ARPANET was expanded to several
              universities by the National Science Foundation which funded the
              establishment of supercomputing centres in these universities.
              Thus ARPANET was now in use not just by the military, but also by
              academia.
            </p>
            <br />
            <p>
              Given the restricted use of ARPANET by military and academia, the
              Internet for the first 20-25 years of its existence saw very few
              disputes. And for the few disputes there were no online formal
              institutions for resolving them.
            </p>
            <br />
            <p>
              After the exclusive use by military and academia, ARPANET, was
              later formally decommissioned in 1990, having partnered with the
              telecommunication and computer industry for commercialization and
              an expanded worldwide network, known from then on as INTERNET came
              into being.
            </p>
            <p>
              However, after the decommissioning in 1990, the world saw the
              evolution of the Internet and its unimaginable increased use,
              which meant the world of Internet would not be dispute free. And
              that is what exactly happened.
            </p>
            <br />
            <p>
              From there to now, we have witnessed how the Internet has changed
              the landscape for communications, associations and interactions.
              It has connected people disregarding all kinds of boundaries,
              culture, territories, so much so that the online world did not
              just begin to connect using the internet, but it began disputing
              on the internet too, just like in the offline world.
            </p>
            <br />
            <p>
              1990s was the time when the online environment for commerce,
              education, employment, and entertainment was on a boom. Suddenly
              the commercialization of the Internet was a ready tool in
              everyone’s hand to start interacting for anything big and small.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic3.png")}
              alt="Independent"
            />
            <br />
            <p>
              This gave them power, this gave their businesses the power too.
              However, similar to the offline world, where increased interaction
              often results in an increase of disputes, the online world saw
              increased disputes ranging from spamming, phishing, illegal
              downloading, piracy, to normal commerce of buying and selling
              online. Cyberspace was no longer a harmonious place.
            </p>
            <br />
            <p>
              The Internet as a 21st century medium has revolutionised almost
              all areas of life. From serving as a common source of information,
              communications tool, global platform for trade and commerce, it
              has become the engine for introducing modern technological
              solutions for almost every activity. Needless to say that the
              Internet has made an impact on many areas of private and public
              life, one such area being law.
            </p>
            <br />
            <p>
              Parallelly, when the world was getting introduced to Internet in
              the 1960’s and 1970s, the world was also witnessing
              dissatisfaction with the Court systems all over the world. The
              caseloads were increasing manifolds with each passing day and much
              more was expected from the Institution responsible for delivering
              justice.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic4.png")}
              alt="Independent"
            />
            <br />
            <p>
              This discontent with the formal Court systems led to the Convening
              of Pound Conference 1976 where several of the legal stalwarts
              discussed the current state and the drudgery of the judicial
              system, not just in terms of efficiency but relating to cost,
              time, quality of outcome reached, party satisfaction, impact of
              resolution, interest based needs, creative solutions etc.
            </p>
            <br />
            <p>
              Pound Conference has given huge impetus to the growth in
              popularity to the process of Mediation, which is one of the
              mechanisms under Alternate Dispute Resolution. Pound Conference
              pressed for having the mechanisms of Alternate Dispute Resolution
              (ADR). ADR, though seen as a recently developed mechanism, is an
              old trick/way of resolving disputes between parties. While Courts
              are the adversarial form of justice system, ADR aims at resolving
              the dispute in an amicable manner.
            </p>
            <br />
            <p>
              Amongst the processes of ADR i.e. negotiation, mediation and
              arbitration, negotiation and mediation are the informal processes
              which aims to settle the matter between two or more disputing
              parties, and are more driven by needs and satisfaction of parties
              than their rights and obligation. In negotiation, parties try to
              discuss their problems and resolve them amongst each other.
            </p>
            <br />
            <p>
              Our everyday lives are filled with negotiation, from negotiating
              for vegetable price to negotiating salaries. The parties realise
              the cost of dragging a dispute for long and taking it to the
              court, and understand the benefits of negotiating and reaching a
              solution that will benefit both parties, thus creating a win-win
              situation.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic5.png")}
              alt="Independent"
            />
            <br />
            <p>
              The process of Mediation is facilitated by a third party neutral
              who assists two or more disputing parties to reach a solution.
              Mediators does not take sides and their only aim is to resolve the
              issue. This is again a very real life situation wherein a third
              person intervenes between two feuding parties and explains to them
              the long term benefits of ending the dispute and making the
              parties reach a solution. Several husband wife matrimonial
              disputes have been resolved by a third party like this, with or
              without terming it as mediation. The process is entirely
              voluntary.
            </p>
            <br />
            <p>
              In fact the concept of arbitration, which is very much a formal
              process like courts, can also be traced back to ancient Greek
              mythology, wherein an arbitrator was chosen to resolve dispute
              between two parties through amicable settlement (much like
              negotiation and mediation). If this didn’t work out then the
              arbitrator was to adduce evidence of parties, and later on deliver
              a judgement based on the facts and evidence. This decision could
              later be appealed against by parties.
            </p>
            <br />
            <p>
              Thus, it is very clear that these processes have been part of our
              systems for very long and the Pound Conference 1976 was
              instrumental in formally pushing the idea of ADR mechanism, as a
              respite for parties from the Court systems and lessening the
              burdens for Courts as well. The Conference was followed by a
              series of conferences about Alternative Dispute Resolutions.
            </p>
            <br />
            <p>
              The idea of ADR has gained momentum since then throughout the
              world. ADR was not just an efficient way of resolving disputes, it
              has also come as a tool to change people’s mindset about disputes
              and resolving it, by looking at the bigger picture.
            </p>
            <br />
            <p>
              ODR began its existence as Online ADR, an amalgamation of
              technology and ADR. It is a network based equivalent of offline
              face-to-face dispute resolution processes like negotiation,
              mediation and arbitration.[3] The idea of Online Dispute
              Resolution emerged out of the understanding that disputes will
              grow as the range of online activities grew. In fact, it was very
              well understood that the Internet was part of the problem in
              generating disputes. However, it was also to be part of the
              solution in garnering resources to resolve it online.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic6.png")}
              alt="Independent"
            />
            <br />
            <p>
              As already pointed out that the first few years of the internet
              witnessed few cases. In fact, after the decommissioning of the
              Internet in 1990 for commercial activity few disputes relating to
              online commerce began to surface.
            </p>
            <br />
            <p>
              In April, 1994, the first commercial spam occurred when two
              lawyers tried to recruit clients to participate in an immigration
              scam.[4] Sometime later, in September, 1994, the U.S. Federal
              Trade Commission filed its first case of online fraud.
            </p>
            <br />
            <p>
              The case involved an American Online subscriber who advertised
              that for $99.00 he could show people how to create new credit
              files at all the major credit bureaus. However the process was not
              considered to be legal and he had to provide compensation to
              consumers. There were other instances like this.
            </p>
            <br />
            <p>
              Around the same time in 1996, first articles on ODR began to
              appear in a Law Review[5] and the National Centre for Automated
              Information Research (NCAIR) sponsored the first ever conference
              on ODR, which also funded the ODR projects like the Virtual
              Magistrate, Online Ombuds at University of Massachusetts.
            </p>
            <br />
            <p>
              A year after this Hewlett Foundation established the Centre for
              Information Technology and Dispute Resolution with the goal of
              supporting development of the field of ODR by various
              initiatives.[6] Since then several conferences, seminars around
              the world have happened with ODR as the central topic.
            </p>
            <br />
            <p>
              A big breakthrough happened in the field of ODR when e-bay founded
              in 1995 by Pierre Omidyar, put a feedback system where buyers
              could know more about the seller, in order to generate trust and
              risk reduction. The feedback was a big step in generating
              confidence in the buyers and also eliminating disputes between
              buyers and sellers.
            </p>
            <br />
            <p>
              Taking this further, in 1999 e-bay asked University of
              Massachusetts to conduct a pilot project to mediate disputes
              between buyers and sellers.[7] The pilot project was a huge
              success as it handled two hundred disputes in a two week period,
              by far the largest number of disputes ever handled online till
              then.
            </p>
            <br />
            <p>
              This prompted e-Bay to include dispute resolution as an option for
              buyers and sellers in the event a transaction were unsuccessful,
              making it the first company ever to do this. Initially, eBay’s
              dispute resolution process was contracted out to an Internet
              start-up, Square Trade. Square Trade which used new methods like
              employing web rather than email as means of communication, and
              also technology supported negotiation before requesting for a
              mediator.
            </p>
            <br />
            <p>
              Several years later the resolution process was taken over by eBay
              itself. By 2010, the number of disputes handled by eB-ay reached
              the extraordinary figure of sixty million.[8] According to a BBC
              report in 2015, e-bay has the most advanced and workable online
              dispute resolution system that jurisdictions such as Wales and
              England should adopt to evade the various civil court proceedings.
              The ODR conference (2016) also ranked e-Bay’s ODR as the largest
              in the world.
            </p>
            <br />
            <p>
              The methodology used by the company has prevented 90% of the
              disputes from making it to the courts. It is very clear the e-bay
              is a trendsetter in the field of Online Dispute Resolution. Not
              just other companies, but other countries have also taken
              inspiration from e-bay and understood what a long leap dispute
              resolution can take with the help of technology
            </p>
            <br />
            <p>
              Some countries such as U.K have somewhere taken inspiration from
              this and tried to imbibe the ODR systems in their territories, in
              similar or different formats. United Kingdom has tried to embrace
              the e-Bay approach, but they have opted to use judges to resolve
              the conflicts due to the existing laws. It is apparent that such a
              methodology is expensive and time-consuming, and can be made more
              dynamic and easy for the users of the ODR system.
            </p>
            <br />
            <p>
              ODR in the current state is also being used as a hybrid mechanism
              between online and offline by various institutions. E.g. UDRP
              dispute resolution happened without face to face meetings except
              sometimes through telephones. Some ODR providers use limited
              internet. One of the earlier dispute resolution providers
              eResolution.com employed a completely online system but stopped
              handling cases in 2001. Currently World Intellectual Property
              Organisation (“WIPO”) and National Arbitration Forum (“NAF”) have
              online systems where emails are sometimes used as well.
            </p>
            <br />
            <div style={{ textAlign: "center", color: "blue" }}>
              FUTURE OF ODR
            </div>
            <br />
            <BlogImage
              src={require("../../../assets/images/Pic7.png")}
              alt="Independent"
            />
            <br />
            <p>
              Technology is undergoing a change rapidly. What is the best
              technology today might get outdated tomorrow. It will become
              faster and cheaper. There was a time when ADR was introduced and
              the world took time to adapt to it, and when it did, it realised
              what power it brought to the whole process of dispute resolution
              as well as to the parties.
            </p>
            <br />
            <p>
              In ADR parties are in charge of their cases. ODR furthers this
              power of resolving disputes without putting a pause in one’s life.
              A party who was once entangled in a case would have innumerable
              things to worry about, cost, travel, time, attending courts, so
              much so that the dispute would become central to his/ her
              existence.
            </p>
            <br />
            <p>
              ODR, in its present form, takes away all the above mentioned
              hassles. The ongoing pandemic has suddenly made us face a
              situation where the world would have been lost, if not for
              technology and Internet. The need for ODR hasn’t been felt
              more.ODR in future, is believed to be not just an extension of
              ADR, but bringing in whole different dynamics altogether. ODR
              tomorrow might not just be about making the technology speedier or
              less costly but also smarter in data processing.
            </p>
            <br />
            <p>
              If that happens, the trajectory of ODR, popularly known as the
              “Fourth Party” would be very different from what we fathom today.
              The wider acceptance of ODR would also mean bringing on board very
              many pre litigation stage cases where parties are comfortable to
              get their future disputes resolved. Organizations in the future
              would be adopting business policies for inclusion of ODR system.
            </p>
            <br />
            <p>
              Although online mediation has received criticisms because of its
              impersonal attribute, in future it will not be perceived so
              because we are looking at a world where every transaction and
              communication will be happening online, and that is going to be
              the normal.
            </p>
            <br />
            <p>
              In future the court may also realise the value addition of ODR as
              part of the system rather than different from it. In fact,
              economies might realise the benefits of an ODR system over the
              current Court system. There will always be a need for Courts to
              resolve certain kinds of matters but there will also be matters
              which would not need the adversarial approach of the Courts, and
              the parties would be happy to resolve it using technology. This
              balancing act would be great in resolving as much number of
              disputes as we can.
            </p>
            <br />
            <p>
              [1] Orna Rabinovich-Einy & Ethan Katsh, Digital Justice: Reshaping
              Boundaries in Online Dispute Resolution Environment, International
              Journal of Online Dispute Resolution, Eleven international
              publishing, Vol 1, 2014, pp.6
            </p>
            <br />
            <p>
              [2] Karolina Mania, Online dispute resolution: The future of
              justice, International Comparative Jurisprudence, Vol 1 2015,
              pp76-86
            </p>
            <br />
            <p>
              [3] Orna Rabinovich-Einy & Ethan Katsh, Digital Justice: Reshaping
              Boundaries in Online Dispute Resolution Environment, International
              Journal of Online Dispute Resolution, Eleven international
              publishing, Vol 1, 2014, pp.7
            </p>
            <br />
            <p>
              [4] Ethan Katsh, Bringing Online Dispute Resolution to Virtual
              Worlds: Creating Processes Through Code, New York Law School Law
              Review, Vol. 49 Issue 1, January 2004, pp 271- 291
            </p>
            <br />
            <p>
              [5] E.Katsh, “Dispute Resolution in Cyberspace”, 28 Conn. L.
              Rev.(1996) 953; E.C. Lide,“ADR and Cyberspace: The Role of
              Alternative Dispute Resolution in Online Commerce, Intellectual
              Property and Defamation”, 12 Ohio St. J. On Disp. Resol. (1996)
              193.
            </p>
            <br />
            <p>[6] www.odr.info</p>
            <br />
            <p>
              [7] E. Katsh, J. Rifkin and A. Gaitenby, “E-Commerce, E-Disputes,
              and E-Dispute Resolution: In the Shadow of eBay Law”, 15 Ohio St.
              J. On Disp. Resol. (2000), pp. 705, 708-709.
            </p>
            <br />
            <p>
              [8] Ethan Katsh, ODR: A Look at History: A Few Thoughts About the
              present and Some Speculation About the Future
            </p>
            <br />
            <p>
              <a href="https://juriscentre.com/2021/04/27/online-dispute-resolution-relevance-and-future/">
                See Original
              </a>
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogTwo;
