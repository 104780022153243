import React from "react";
import { Header } from "../../internal/Header/Header";
import {
  HeroContainerWrap,
  HeroContainer,
  HeroHeading,
  H1,
  H3,
  // ImgWrapper,
  // ButtonCont,
  WidthMax,
  // PrimaryStyledButton,
  // CarouselWrap,
  // CarousalText,
} from "./Styles";
// import AboutUs from "./AboutUs";
import Footer from "./Footer";
import Cookies from "./Cookies.js";
import useIfLoggedIn from "../../../hooks/useIfLoggedIn";
import labels from "../../../helpers/labels.json";
// import { Link } from "react-scroll";


const Index = () => {
  useIfLoggedIn();

  return (
    <Header LandingPage NavPages>
      <WidthMax>
        <Cookies />
        <HeroContainerWrap id="hero">
          <HeroContainer>
            <div className="hero-container">
              <img
                className="hero-image"
                src={require("../../../assets/images/Ph 60 Chancery-1.jpg")}
                alt="hero-image"
              />
            </div>
            <HeroHeading>
              <H1 style={{ fontSize: 44 }} dangerouslySetInnerHTML={{ __html: labels.landing_title }} />
              <H3 style={{ paddingLeft: 90 }}>"{labels["landing.subheading"]}"</H3>
              {/* <H3 style={{ marginBottom: 20 }}>
                {labels["landing.subheading2"]}
              </H3>
              <div style={{ maxWidth: 150, marginTop: 10 }}>
                <PrimaryCTAButton
                  style={{
                    marginLeft: "unset",
                  }}
                  onClick={() => navigate("/neutral/mediators")}
                >
                  Browse
                </PrimaryCTAButton>
              </div> */}

              {/* <div style={{ marginTop: "130px" }}>
                <Carousel
                  interval="8000"
                  animation="slide"
                  navButtonsAlwaysVisible="true"
                >
                  <CarouselWrap>
                    <Link
                      to="contact_us"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <CarousalText> Want to talk to experts?</CarousalText>

                      <ButtonCont>
                        <PrimaryStyledButton>
                          {labels["landing.herocta"]}
                        </PrimaryStyledButton>
                      </ButtonCont>
                    </Link>
                  </CarouselWrap>

                  <CarouselWrap>
                    <div onClick={() => navigate("/signup")}>
                      <CarousalText>
                        {" "}
                        Want to register your first case?
                      </CarousalText>
                      <ButtonCont>
                        <PrimaryStyledButton>Sign Up</PrimaryStyledButton>
                      </ButtonCont>
                    </div>
                  </CarouselWrap>

                  <CarouselWrap>
                    <div onClick={() => navigate("/training/40")}>
                      <CarousalText>Want to become a Mediator?</CarousalText>
                      <ButtonCont>
                        <PrimaryStyledButton>Training</PrimaryStyledButton>
                      </ButtonCont>
                    </div>
                  </CarouselWrap>
                </Carousel>
              </div> */}
            </HeroHeading>
            {/* <ImgWrapper>
              <img
                src={require("../../../assets/images/HeroImg@3x.png")}
                style={{ marginTop: 40 }}
                width="100%"
                alt="HeroImg"
              />
            </ImgWrapper> */}
          </HeroContainer>
        </HeroContainerWrap>
        {/* <div id="contact_us">
          <ScheduleForm />
        </div> */}

        {/* <OnlineDispute /> */}

        <div style={{ backgroundColor: "white", paddingBottom: 40 }}>
          {/* <HowItWorks /> */}
        </div>

        {/* <Blogs /> */}

        {/* <AboutUs /> */}
        <Footer />
      </WidthMax>
    </Header>
  );
};
export default Index;
