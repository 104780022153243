import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  TableWrapper,
  HyperLink,
  StyledAmount,
  TopCenter,
  Center,
  DeleteButton
} from "../PaymentHistory/Styles";
import _ from "lodash";
import queryString from "query-string";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import {
  getErrorMessage,
  getFileName,
  parseTimeStamp
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import { ActionButton } from "../../common/Buttons";
import CartService from "../../../services/CartService";
import useRazorPay from "../../../hooks/useRazorPay";
import useLoader from "../../../hooks/useLoader";
import useUser from "../../../hooks/useUser";
import useAlert from "../../../hooks/useAlert";
import useCartCount from "../../../hooks/useCartCount";
import styled from "styled-components";
import { navigate } from "@reach/router";

async function getOrders(query = "") {
  try {
    const response = await CaseService.getOrders(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const ProfomaHistory = () => {
  const [state, setState] = useState({});
  const openRazorPay = useRazorPay();
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const placeholderText = "Search Invoice";
  const { userObject } = useUser();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const { showAlert, hideAlert } = useAlert();
  const { setTriggerCount } = useCartCount();

  useEffect(() => {
    if (triggerRefresh) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefresh]);

  const columns = [
    {
      field: "caseId",
      title: labels["table.case_id"],
      sorting: false,
      render: rowData =>
        rowData?.caseId === "Multiple" ? (
          <HyperLink>
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ) : (
          <HyperLink
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData?.caseId}?caseType=${rowData?.resolutionKind}`
              )
            }
          >
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ),
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`
      }
    },
    {
      field: "id",
      title: labels["table.order_id"],
      tooltip: "Payment order identifier",
      sorting: true,
      render: rowData => (
        <HyperLink>
          <TopCenter>{rowData?.id}</TopCenter>
        </HyperLink>
      ),
      headerStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      },
      cellStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      }
    },
    {
      field: "id",
      title: labels["table.profoma_invoice_id"],
      sorting: true,
      render: rowData => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit"
          }}
        >
          {rowData?.id}
        </HyperLink>
      )
    },
    {
      field: "parties.name",
      title: labels["table.party_name"],
      sorting: false,
      render: rowData => rowData?.party?.name,
      headerStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      },
      cellStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`
      }
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: rowData => (
        <StyledAmount>INR {rowData?.amount?.toFixed(2)}</StyledAmount>
      )
    },
    {
      field: "created_at",
      title: labels["table.date"],
      tooltip: "Date of transaction",
      sorting: true,
      render: rowData => (
        <>{moment(parseTimeStamp(rowData?.created_at)).format("DD/MM/YYYY")}</>
      )
    },
    {
      field: "invoiceUrl",
      title: labels["table.proforma_invoice"],
      tooltip: "Proforma invoice of the transaction ",
      sorting: false,

      render: rowData =>
        rowData?.proformaInvoiceUrl ? (
          <HyperLink
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => window.open(rowData?.proformaInvoiceUrl)}
          >
            {rowData?.proformaInvoiceUrl
              ? getFileName(rowData?.proformaInvoiceUrl)?.split(".pdf")[0]
              : "Download"}
          </HyperLink>
        ) : (
          <Center>-</Center>
        )
    },
    {
      field: "action",
      title: labels["table.action"],
      sorting: false,
      render: rowData => (
        <Buttons>
          <ActionButton
            style={{ margin: "0 10px 0 5px", width: 80 }}
            key={rowData?.id}
            onClick={() => handlePaynow(rowData)}
          >
            {labels["table.pay_now"]}
          </ActionButton>
          <DeleteButton>
            <HyperLink
              isError
              onClick={() => deleteProformaConfirmation(rowData)}
            >
              {labels["table.delete"]}
            </HyperLink>
          </DeleteButton>
        </Buttons>
      ),
      headerStyle: {
        width: "150px !important"
      }
    }
  ];

  const deleteProformaConfirmation = rowData => {
    showAlert({
      heading: "Delete Confirmation",
      desc: `Are you sure you want to delete this Proforma invoice?`,
      primaryBtnText: "Ok",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        deleteInvoice(rowData);
        hideAlert();
      },
      isOpen: true,
      descriptionTextStyle: {
        marginBottom: 30
      },
      onDialogClose: () => hideAlert()
    });
  };

  const deleteInvoice = async ({ id }) => {
    try {
      setLoader({ state: true, message: "deleting the Proforma invoice..." });
      const result = await CaseService.deleteProforma(id);
      if (result?.message) {
        enqueueSnackbar(result?.message, {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setTriggerRefresh(true);
    }
  };

  const handlePaynow = async rowData => {
    try {
      const payload = {
        paymentId: rowData?.id
      };
      setLoader({ state: true, message: "Initiating Payment..." });
      const res = await CartService.payPerformaInvoice(payload);
      if (res) {
        setLoader({ state: false });
        if (res?.status === "capturedOffline" || res?.status === "captured") {
          enqueueSnackbar("Payment has already made for this invoice", {
            variant: "error"
          });
          setTriggerRefresh(true);
        } else {
          openRazorPay({
            order_id: res.orderId,
            name: userObject?.name,
            email: userObject?.email,
            contact: userObject?.mobile,
            handler: capturePayment
          });
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  };

  async function capturePayment(rzp_res) {
    document.body.style.overflow = null;
    try {
      console.log(rzp_res);
      setLoader({ state: true, message: "Capturing Payment..." });
      const capture_res = await CartService.capture(rzp_res);
      if (capture_res) {
        enqueueSnackbar(capture_res.message, {
          variant: "success"
        });
        setTriggerRefresh(true);
        setTriggerCount(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const data = query =>
    new Promise(resolve => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: "status"
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      const filterSting = queryString.stringify(
        { filterValues: ["proformaGenerated"] },
        { arrayFormat: "index" }
      );
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}&${filterSting}`;
      }
      getOrders(stringParams)
        .then(async result => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total
            });
            setState(result);
          }
        })
        .catch(error => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error"
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0
          });
        });
    });

  return (
    <Fragment>
      <Container className="CreateCase" style={{ overflow: "hidden" }}>
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.proforma_invoice}
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              MTRef
            }}
            //noToolbar
            hideFilterBar
            state={state}
          />
        </TableWrapper>
      </Container>
    </Fragment>
  );
};

export default ProfomaHistory;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;
