import React, { useState } from "react";
import { navigate, useParams } from "@reach/router";
import CaseService from "../../services/CaseService";
import theme from "../../assets/theme";
import { getErrorMessage } from "../../helpers/functions";
import useLoader from "../../hooks/useLoader";
import { useSnackbar } from "notistack";
import COLORS from "../../assets/Colors";
import styled from "styled-components";
import { CustomInputField } from "../common/FormInputs";
import { Modal } from "@material-ui/core";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../common/Buttons";

export default function Index() {
  const params = useParams();
  const [open, setOpen] = useState(true);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const meetingCode = params?.code;

  const getGuestUserMeeting = async meetingCode => {
    setLoader({ state: true, message: "Updating Meeting Name..." });
    try {
      const payload = {
        guestUserName: name
      };
      const res = await CaseService.getGuestUserMeeting(payload, meetingCode);
      window.open(res, "_self");
      if (res?.message) {
        enqueueSnackbar("Meeting Open", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  return (
    <Modal open={open}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Join the meeting room</Heading>
            <CloseModal
              onClick={() => navigate("/")}
              src={require("../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <Flex style={{ flexWrap: "wrap" }}>
              <Label>Enter Your Name</Label>
              <CustomInputField
                variant="outlined"
                value={name}
                required={true}
                onChange={e => setName(e.target.value)}
              />
            </Flex>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={() => navigate("/")}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                style={{ width: "45%" }}
                disabled={!name}
                onClick={() => getGuestUserMeeting(meetingCode)}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;
