import React, { useEffect } from "react";
import {
  Content,
  HeadWrap,
  SubHeadWrap,
  BlogPage,
  BlogImage
} from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function OnlineDisputeBlogFive(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Blog</HeadWrap>
          <br />
          <SubHeadWrap>
            Resolving women-related disputes (Part 1 of 3)  
          </SubHeadWrap>
          <br />
          <Content>
            <b style={{ textAlign: "center" }}> Authored by: Albina Jordy </b>
            <br />
            <p>
              Women are victims of different social evils. They are subjected to
              problems at their homes, at the workplace, in society, etc. One of
              the significant examples is Domestic Violence. Domestic violence
              is any form of maltreatment in a domestic setting, such as
              marriage or cohabitation. Such violence is one of the significant
              health problems faced by around 2 million women and 8,00,000 men,
              resulting in physical injury or mental health injury, death, or
              divorce<sup>1</sup>
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W1.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              In the present situation where the world is struggling to combat
              Covid-19, there is a surge in the number of domestic violence
              cases in India. This has brought out the question of women's
              safety in her own house into concern. This national lockdown has
              given a chance for everyone to take a break from their hustle life
              and settle down at peace and work from home.
            </p>
            <br />
            <p>
              But this has also increased the burden of the women in the house.
              Just like the doctors, the police officers, and the other
              front-line workers, women in their own households are fighting to
              serve the family members and to maintain peace at home. But in the
              past year, between March 23 and April 16, 2020, there are almost
              587 Domestic Violence cases registered across the country
              <sup>2</sup>.
            </p>
            <br />
            <p>
              These are only the cases that are registered; there may be women
              who are unable to come and talk about the problems faced by them
              to keep up their family's and partner's reputation. The protection
              of a woman's dignity is also a big challenge in the current
              situation.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W2.png")}
              alt="Independent"
            />
            <br />
            <p>
              Being one of the world's oldest judicial systems, the Indian
              Judiciary is now burdened with several unsettled disputes and
              cases. Even as fast-track courts are being set up, pending cases
              continue to rise. In such situations, Alternative Dispute
              Resolution plays an essential role by its various techniques.
            </p>
            <br />
            <p>
              This method resolves the conflict between parties in a peaceful
              manner and reduces the courts' burden. Alternative Dispute
              Resolution has its advantages like it is less time consuming; it
              is free from all the procedures followed in a court; people can
              freely express themselves without fear of court of law; it
              preserves the best interests of the parties, and in most of the
              situations, there are chances of restoring the relationship as the
              parties discuss their issues together. It is also a cost-effective
              method.
            </p>
            <br />
            <p>
              Marriage is an institution that brings a man and a woman together
              in a special social and legal relationship to make a home and
              build a family together. Marriage is a relationship that needs
              equality and respect individually for both partners. While the
              courts are busy dealing with the other pending cases, it is a need
              of the hour to adopt an alternative approach to solve the problems
              between families.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W3.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              One of the suitable ways to settling matrimonial disputes is a
              mediation that comes under Alternative Dispute Resolution. In
              mediation, the advocates use favorable mechanisms as it safeguards
              the family relationships, more importantly, the children from
              having to witness the disputes and provide speedy justice
              <sup>3</sup>. One of the modes of alternate dispute resolution is
              mediation.
            </p>
            <br />
            <p>
              A neutral third party assists the complainant and the other
              parties related to a dispute to reach an agreement where all of
              them are satisfied. It is an easy and uncomplicated party-centered
              negotiation process where a third party acts as a mediator to
              resolve a dispute harmoniously by using the right techniques of
              proper communication and negotiation. Mediation is entirely
              controlled by the parties involved in the dispute.
            </p>
            <br />
            <p>
              The work of the mediator is to facilitate the parties to settle
              their dispute. A mediator doesn’t impose his views and does not
              decide what a fair settlement should be. Mediation gives a sense
              of confidentiality to the couple, gives them the freedom to
              express themselves, mutuality, etc. However, mediation can be
              inappropriate if it is used in cases where there is a clear power
              imbalance or where is a societal stake in developing law through
              the adversarial process.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W4.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              Mediation can work to the disadvantage of women if the authorities
              are not sensitive to issues that are gender-biased<sup>4</sup>.
              Even if a mediator is trying to create a neutral environment, the
              mediator's own perspectives and biases will show how he or she
              will perceive the position of the parties related to the case.
            </p>
            <br />
            <p>
              Under section 12 of the Protection of Women from Domestic Violence
              Act, 2005<sup>5</sup>, it is clearly stated that a magistrate must
              dispose of cases related to this Act within 60 days, although this
              provision is rarely met. The Arbitration and Conciliation Act,
              1996 was the first statute to introduce Mediation. Section 30(1)
              of the Act encourages the parties involved in a dispute to take up
              mediation and conciliation as a means of dispute resolution.
            </p>
            <br />
            <p>
              But because of the lack of proper enforcement of the provision,
              the concept of mediation somehow was not known to many people. In
              2013, in the case of B. S. Krishnamurthy v B. S. Nagaraj
              <sup>6</sup>, the Supreme Court directed the Family Court to
              settle matrimonial disputes through mediation, especially matters
              concerning maintenance, child custody, etc.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W5.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              A woman who faces such atrocities in an intimate partner
              relationship can be heard through Alternative Dispute Resolution
              <sup>7</sup>. Initial report and gathering information- Once a
              report is filed, the authority must gather information from the
              woman (the survivor of the Domestic violence) herself to establish
              her testimony before involving any other individual. The Summons
              and hearing- Once the authority decides to take up a case, a date
              will be set for the hearing and summon of the parties involved in
              the case.
            </p>
            <br />
            <p>
              The survivor will be heard first before any other party without
              any interruption and will be treated with complete trust and
              respect by all the members involved in the procedures.
            </p>
            <br />
            <p>
              <b>Decision or punishment-</b> The hearing will aim at concluding
              with mediation, arbitration, or otherwise agreed-upon decision,
              potentially involving punishment or order for restitution.
            </p>
            <br />
            <p>
              <b>Enforcement-</b> Once the decision or the punishment is
              enforced, the Alternative Dispute Resolution community members
              would ensure that the terms are followed. The terms are made
              according to the woman's choices, with the primary goal to prevent
              any form of further violence.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W6.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              The setting up of women's courts in India is also one step closer
              to empowering them. The first Indian Women's Court was opened in
              West Bengal in 2013<sup>8</sup>. This court was set up to hear all
              the atrocities faced by a woman and to deliver speedy justice.
              This court was only to have women staff in it.
            </p>
            <br />
            <p>
              "When there are men around, the female victim can't speak up and
              give details of the atrocity committed against her. They feel shy.
              In these courts, the victim will get a women-friendly atmosphere,"
              general secretary of All India Legal Aid Forum Joydeep Mukherjee
              said. Male judges are often not "sensitive towards crimes against
              women and are biased towards the male gender when hearing a
              woman's plea," said senior advocate Bharati Mutsuddi<sup>9</sup>.
            </p>
            <br />
            <p>
              The main goal of the women's courts is to avoid matrimonial
              litigation and to solve problems of the couple, and keep the
              institution of the family intact. This is achieved through
              counseling or through mediation between the complainant and the
              husband or the other relatives. They try to convince the couple to
              settle their disputes and to accept each other, and give time for
              the betterment of their relationship, and make changes in their
              behaviors that have actually created a misunderstanding between
              them.
            </p>
            <br />
            <BlogImage
              src={require("../../../assets/images/W7.jpg")}
              alt="Independent"
            />
            <br />
            <p>
              Alternative Dispute Resolution, especially mediation, has to be
              used as the most common solution for any dispute. Awareness for
              the same has to be spread to common people. For this, it is
              necessary to identify people or community-based initiatives for
              taking up the cause of spreading awareness about mediation across
              the country.
            </p>
            <br />
            <p>
              It now seems that we need new ways of looking at conflict
              resolution and the legal profession and hopes to discover a new
              way to help bridge bonds between practicing and respecting the law
              and also to the demands of the Public. Creating awareness in the
              society of the mediation process and its benefits and developing
              capacities for the same will help expedite the shift from
              adversarial litigation to methods of alternate dispute resolution
              in a big way. This will also help in reducing the backlog of long-
              pending cases in Indian courts and usher in a new era<sup>10</sup>
              .
            </p>
            <br />
            <p>
              The promise of justice to the citizens of India that was made
              under the Indian Constitution is at stake. These constitutional
              provisions can be fulfilled through the mechanisms of Alternative
              Dispute Resolution. Alternative Dispute Resolution is a method
              that is simple, less time-consuming, has fewer technicalities,
              etc. that promises a solution to ensure justice to the parties
              involved in a dispute.
            </p>
            <br />
            <p>
              Rather than always going before the court, civil disputants should
              have the option of solving the dispute by an agreement, whether it
              is directly or a mediator who will help as a third- party. In
              certain cases, the choice of Alternative Dispute Resolution must
              be given in the civil justice system because it is an essential
              mechanism to provide access to individualized justice for the
              parties.
            </p>
            <br />
            <p>
              Separation is not always a solution for problems that can be
              solved when communicated among families. Mediation is a help for
              the same. Alternate Dispute Resolution's motive is to provide
              social economic justice system to the people and also reduce the
              burden of the Indian Courts. So it is time to adapt and get used
              to the services that are provided for the benefit of the community
              and individuals.
            </p>
            <br />
            <b style={{ textAlign: "center", fontSize: "20px" }}>
              {" "}
              References{" "}
            </b>
            <br />
            <p style={{ textAlign: "flex" }}>
              1. Roxanne Dryden-Edwards, Domestic Violence, MedicineNet, (March
              20, 2021, 11:00 AM),
            </p>
            <p>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    " https://www.medicinenet.com/domestic_violence/article.htm"
                  )
                }
              >
                https://www.medicinenet.com/domestic_violence/article.htm
              </div>{" "}
            </p>
            <br />
            <p>2. Economic Times,</p>
            <p>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://economictimes.indiatimes.com/news/politics-and-nation/india-witnesses-steep-rise-in-crime-against-women-amid-lockdown-587-complaints-received-ncw/articleshow/75201412.cms"
                  )
                }
              >
                https://economictimes.indiatimes.com/news/politics-and-nation/india-witnesses-steep-rise-in-crime-against-women-amid-lockdown-587-complaints-received-ncw/articleshow/75201412.cms
              </div>
              , (March 20, 2021, 11:30 AM)
            </p>
            <br />
            <p>
              3. Anubhav Pandey, All you need to know about Alternative Dispute
              Resolution (ADR), iPleaders, (May 09, 2017),
            </p>
            <p>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://blog.ipleaders.in/adr-alternative-dispute-resolution/"
                  )
                }
              >
                https://blog.ipleaders.in/adr-alternative-dispute-resolution
              </div>{" "}
            </p>
            <br />
            <p>
              4. Leigh Goodmark, Alternative Dispute Resolution and the
              Potential for Gender Bias, 39 Judges J. 21 2000, 25,
            </p>
            <p>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://digitalcommons.law.umaryland.edu/cgi/viewcontent.cgi?article=2468&context=fac_pubs"
                  )
                }
              >
                https://digitalcommons.law.umaryland.edu/cgi/viewcontent.cgi?article=2468&context=fac_pubs
              </div>{" "}
            </p>
            <br />
            <p>
              5. Indian Kanoon, (March 20, 2021, 20:30 PM) <br />
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://indiankanoon.org/doc/542601/")
                }
              >
                https://indiankanoon.org/doc/542601/
              </div>{" "}
            </p>
            <br />
            <p>
              6. Indian Kanoon, (March 21, 2021, 10:00 AM) <br />
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://indiankanoon.org/doc/1633972/")
                }
              >
                https://indiankanoon.org/doc/1633972/
              </div>{" "}
            </p>
            <br />
            <p>
              7. Brian Heilman, Nastasia Paul-Gera, Tina Musuya, and Sara
              Siebert, Whose Justice, Whose Alternative? ,
              <p>International Center for Research on Women,</p>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.icrw.org/wp-content/uploads/2016/10/ICRW-Mediation-Paper-FINAL.PDF"
                  )
                }
              >
                https://www.icrw.org/wp-content/uploads/2016/10/ICRW-Mediation-Paper-FINAL.PDF
              </div>
            </p>
            <br />
            <p>
              8. First Post, March 21, 2021, 20:00 PM) <br />
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.firstpost.com/india/indias-first-court-only-for-women-launched-in-bengal-599369.html"
                  )
                }
              >
                https://www.firstpost.com/india/indias-first-court-only-for-women-launched-in-bengal-599369.html
              </div>
            </p>
            <br />
            <p>
              9. Amitabha Bhattasali, India’s First Women’s Court opens in West
              Bengal, BBC News, (January 24, 2013),
              <p>
                <div
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.bbc.com/news/world-asia-india-21175738"
                    )
                  }
                >
                  https://www.bbc.com/news/world-asia-india-21175738
                </div>
              </p>
            </p>
            <br />
            <p>
              10. The impact of Mediation in India, iPleaders,{" "}
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://blog.ipleaders.in/the-impact-of-mediation-in-india/"
                  )
                }
              >
                https://blog.ipleaders.in/the-impact-of-mediation-in-india/
              </div>
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeBlogFive;
