import { FormLabel } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { CustomInputField } from "../../../common/FormInputs";
import { StyledFormElementsWrapper } from "../Styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import { SecondaryCTAButton } from "../../../common/Buttons";
import _ from "lodash";
import { formatNumberToMetrics } from "../../../../helpers/functions";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0
    }
  },
  tableHead: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0
  },
  row: {
    borderBottom: `1px solid ${COLORS.INPUT_BORDER}`
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN
    }
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 5
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN
  }
});

function TotalClaimValue({
  values,
  handleChange,
  errors,
  addClaimItems,
  enqueueSnackbar,
  setFieldValue,
  removeClaimItem,
  setFieldTouched,
  touched,
  handleBlur,
  caseObj
}) {
  const classes = useStyles();
  function renderClaimValue(values) {
    try {
      const sum = _.sum(
        values?.claim_items_data
          // ?.filter(f => f.unit === "INR")
          ?.map(f => {
            if (f?.claimValue)
              return Number(
                f?.claimValue
                  ?.toString()
                  ?.split(",")
                  ?.join("")
              );
          })
      );
      if (sum)
        return formatNumberToMetrics(
          parseFloat(sum)
            .toFixed(2)
            .toString(),
          "INR"
        );
      else return 0;
    } catch (error) {
      return 0;
    }
  }

  return (
    <>
      <StyledFormElementsWrapper>
        <FormContainer className="claim-table">
          <TableContainer>
            <TableWrapper>
              <BigTable>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: window.innerWidth <= 1441 ? 183 : 253
                        }}
                        className={classes.tableHead}
                      >
                        Claim Item
                        <span className="required-star"> *</span>
                      </TableCell>
                      <TableCell
                        style={{ width: 150 }}
                        className={classes.tableHead}
                      >
                        Claim Value <span className="required-star"> *</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.claim_items_data?.map((claim_item, index) => (
                      <TableRow
                        // className={
                        //   index < values?.claim_items_data?.length - 1
                        //     ? classes.row
                        //     : "table-row"
                        // }
                        key={index}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ position: "relative" }}
                        >
                          <CustomInputField
                            className={"input-white"}
                            value={claim_item.title}
                            name={`claim_items_data[${index}].title`}
                            onChange={handleChange}
                            style={{
                              width: window.innerWidth <= 1441 ? 183 : 253
                            }}
                            style={{ width: 150 }}
                            variant="outlined"
                            onBlur={handleBlur}
                            error={
                              errors?.claim_items_data?.[index]?.title &&
                              touched?.claim_items_data?.[index]?.title
                            }
                            helperText={
                              errors?.claim_items_data?.[index]?.title &&
                              touched?.claim_items_data?.[index]?.title
                            }
                          />
                          <HelperText>
                            {touched?.claim_items_data?.[index]?.title &&
                              errors?.claim_items_data?.[index]?.title}
                          </HelperText>
                        </TableCell>
                        <TableCell
                          className="disabledArrows"
                          style={{ position: "relative" }}
                        >
                          <InputContainer>
                            <NumberFormat
                              thousandSeparator
                              thousandsGroupStyle="lakh"
                              isNumericString={true}
                              decimalScale={2}
                              fixedDecimalScale
                              allowNegative={false}
                              className={"input-white"}
                              displayType={"input"}
                              value={claim_item?.claimValue}
                              type={"text"}
                              customInput={CustomInputField}
                              name={`claim_items_data[${index}].claimValue`}
                              onChange={handleChange}
                              style={{ width: 140 }}
                              variant="outlined"
                              error={
                                errors?.claim_items_data?.[index]?.claimValue &&
                                touched?.claim_items_data?.[index]?.claimValue
                              }
                              onBlur={e => {
                                setFieldTouched(
                                  `claim_items_data[${index}].claimValue`,
                                  true
                                );
                              }}
                            />
                          </InputContainer>
                          {touched?.claim_items_data?.[index]?.claimValue &&
                            errors?.claim_items_data?.[index]?.claimValue && (
                              <HelperText style={{ whiteSpace: "nowrap" }}>
                                {errors?.claim_items_data?.[index]?.claimValue}
                              </HelperText>
                            )}
                        </TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          {values.claim_items_data.length > 1 && (
                            <DeleteIcon
                              onClick={() =>
                                removeClaimItem(index, values, setFieldValue)
                              }
                              src={require("../../../../assets/images/removeIcon.svg")}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </BigTable>
              <SmallTable>
                {values?.claim_items_data?.map((claim_item, index) => (
                  <Details>
                    <LabelValueContainer>
                      <Label>Claim Item</Label>
                      <Value>
                        <div className="element">
                          <CustomInputField
                            className={"input-white"}
                            value={claim_item.title}
                            name={`claim_items_data[${index}].title`}
                            onChange={handleChange}
                            style={{
                              paddingRight: 15
                            }}
                            variant="outlined"
                            onBlur={handleBlur}
                            error={
                              errors?.claim_items_data?.[index]?.title &&
                              touched?.claim_items_data?.[index]?.title
                            }
                            helperText={
                              errors?.claim_items_data?.[index]?.title &&
                              touched?.claim_items_data?.[index]?.title
                            }
                          />
                          <HelperText>
                            {touched?.claim_items_data?.[index]?.title &&
                              errors?.claim_items_data?.[index]?.title}
                          </HelperText>
                        </div>
                      </Value>
                    </LabelValueContainer>
                    <LabelValueContainer>
                      <Label>Claim Value</Label>
                      <Value>
                        <div className="element">
                          <InputContainer>
                            <NumberFormat
                              thousandSeparator
                              thousandsGroupStyle="lakh"
                              isNumericString={true}
                              decimalScale={2}
                              fixedDecimalScale
                              allowNegative={false}
                              className={"input-white"}
                              displayType={"input"}
                              value={claim_item?.claimValue}
                              type={"text"}
                              customInput={CustomInputField}
                              name={`claim_items_data[${index}].claimValue`}
                              onChange={handleChange}
                              style={{
                                paddingRight: 15
                              }}
                              variant="outlined"
                              error={
                                errors?.claim_items_data?.[index]?.claimValue &&
                                touched?.claim_items_data?.[index]?.claimValue
                              }
                              onBlur={e => {
                                setFieldTouched(
                                  `claim_items_data[${index}].claimValue`,
                                  true
                                );
                              }}
                            />
                          </InputContainer>
                          {touched?.claim_items_data?.[index]?.claimValue &&
                            errors?.claim_items_data?.[index]?.claimValue && (
                              <HelperText style={{ whiteSpace: "nowrap" }}>
                                {errors?.claim_items_data?.[index]?.claimValue}
                              </HelperText>
                            )}
                        </div>
                      </Value>
                    </LabelValueContainer>
                    {values.claim_items_data.length > 1 && (
                      <Delete
                        onClick={() =>
                          removeClaimItem(index, values, setFieldValue)
                        }
                      >
                        Remove
                      </Delete>
                    )}
                  </Details>
                ))}
              </SmallTable>
              <ButtonWrapper>
                <SecondaryCTAButton
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    if (
                      errors?.claim_items_data?.[
                        values.claim_items_data.length - 1
                      ]?.title ||
                      errors?.claim_items_data?.[
                        values.claim_items_data.length - 1
                      ]?.claimValue
                    )
                      return enqueueSnackbar(
                        "Enter claim items in order to add more claim items",
                        { variant: "error" }
                      );

                    addClaimItems(values, setFieldValue);
                  }}
                >
                  + Add Claim Items
                </SecondaryCTAButton>
              </ButtonWrapper>
            </TableWrapper>
            <MonetaryText>
              Total Monetary Claim Value :{" "}
              <span>INR {renderClaimValue(values)}</span>
            </MonetaryText>
            <div style={{ paddingLeft: "120px" }}>
              <span className="waring-msg">
                {" "}
                (Leave claim value as zero if your claim is non-monetary)
              </span>
            </div>
          </TableContainer>
        </FormContainer>
      </StyledFormElementsWrapper>
    </>
  );
}

export default TotalClaimValue;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;
export const TableContainer = styled.div`
  margin-top: 20px;
  // background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 37px 25px 27px;
    border-radius: 5px;
    border: solid 0.5px ${COLORS.INPUT_BORDER};
  }
`;

export const BigTable = styled.div`
  display: none;
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;

export const SmallTable = styled.div`
  display: block;
  @media ${theme?.breakpoints?.md_up} {
    display: none;
  }
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #f9f9f9;
    border-radius: 7px;
  }
`;

const InputContainer = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    & input {
      text-align: right !important;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const DeleteIcon = styled.img`
  margin-left: -12px;
  user-select: none;
  object-fit: contain;
  cursor: pointer;
`;

const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: 2px;
  position: absolute;
  font-size: 9px;
  padding-left: 16px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 15px;
  }
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

export const Value = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: #293461;
  & span {
    padding: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
    position: absolute;
  }
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  padding-left: 5px;
  flex-wrap: wrap;
  height: auto;
  padding: 10px 3.3px 10px 9px;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  position: relative;
`;

export const Delete = styled.div`
  font-family:${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.WARNING_COLOR};
  position absolute;
  bottom:0;
  right:0;
  margin-bottom:10px;
  margin-right:5px;
`;

export const MonetaryText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 14px;
  span {
    font-size: 16px;
  }

  @media ${theme?.breakpoints?.md_up} {
    align-self: flex-end;
    margin-left: 30px;
    margin-top: 17px;
  }
`;
