import { FieldArray } from "formik";
import React from "react";
import { SecondaryCTAButton } from "../../../common/Buttons";
import FormField from "../../../common/FormField/FormField";
import { CustomFileUpload } from "../../../common/FormInputs";
import { dummyFileUpload } from "../function";
import {
  DeleteIcon,
  Row,
  StyledFormElementsWrapper,
  StyledLabel,
} from "../Styles";

const MultipleUpload = ({
  values,
  setFieldValue,
  getFileName,
  setFieldError,
  handleDeleteAgreement,
  touched,
  errors,
  menuItems,
  handleBlur,
  s3Upload,
}) => {
  return (
    <StyledFormElementsWrapper
      style={{
        marginBottom: 3,
      }}
    >
      <FieldArray
        name="agreement_urls"
        render={({ push }) => (
          <>
            {values?.agreement_urls?.map((url, index) => (
              <>
                <Row
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <StyledLabel>
                    Case Related Documents{" "}
                    {values?.agreement_urls.length > 1 ? index + 1 : ""} (pdf,
                    images, mp4, mov, docx or mp3) (OPTIONAL)
                  </StyledLabel>
                  <>
                    {values?.agreement_urls.length > 1 && (
                      <DeleteIcon
                        src={require("../../../../assets/images/removeIcon.svg")}
                        onClick={() =>
                          handleDeleteAgreement(
                            index,
                            values?.agreement_urls,
                            setFieldValue
                          )
                        }
                      />
                    )}
                  </>
                </Row>
                <div style={{ marginBottom: 13 }}>
                  {values?.resolutionKind === "arbitration" && (
                    <div style={{ marginRight: 10, width: "100%" }}>
                      <FormField
                        white
                        input={{
                          type: "select",
                          name: `uploadType`,
                        }}
                        menuItems={menuItems}
                        values={values?.agreement_urls[index]}
                        touched={
                          touched?.agreement_urls?.length &&
                          touched?.agreement_urls[index]?.uploadType
                            ? touched?.agreement_urls[index]
                            : {}
                        }
                        errors={
                          errors?.agreement_urls?.length &&
                          errors?.agreement_urls[index]?.uploadType
                            ? errors?.agreement_urls[index]
                            : {}
                        }
                        handleChange={(e) => {
                          setFieldValue(
                            `agreement_urls[${index}].uploadType`,
                            e?.target?.value
                          );
                        }}
                        {...{
                          handleBlur,
                          setFieldValue,
                        }}
                      ></FormField>
                    </div>
                  )}
                  {/* File upload extra field */}
                  <CustomFileUpload
                    error={
                      touched?.agreement_urls?.length &&
                      touched?.agreement_urls[index]?.url &&
                      errors?.agreement_urls?.length &&
                      errors?.agreement_urls[index]?.url
                    }
                    setFile={(file) =>
                      s3Upload(
                        file,
                        `agreement_urls[${index}].url`,
                        setFieldValue,
                        setFieldError
                      )
                    }
                    style={{
                      marginTop: 10,
                      width: "100%",
                    }}
                    accept=".pdf, .mp3, .mp4, .docx, image/*"
                    file={{ name: getFileName(url?.url) }}
                  />
                </div>
              </>
            ))}
            <SecondaryCTAButton
              onClick={() => {
                push(dummyFileUpload);
              }}
              style={{
                marginTop: "0px",
                margin: "unset",
                marginBottom: 15,
              }}
            >
              +Add More Case Related Documents
            </SecondaryCTAButton>
          </>
        )}
      />
    </StyledFormElementsWrapper>
  );
};

export default MultipleUpload;
