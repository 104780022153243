import { makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/Colors';
import themes from '../../../assets/theme';
import {H2, PrimaryStyledButton} from './Styles'
import { navigate } from '@reach/router';



const useStyles = makeStyles((theme)=>({

    subheading:{
        color:COLORS.BTN_GREEN,
        fontSize:"18px"
    },
    content:{
        fontFamily:themes.fonts.primaryFontBold,
        color:COLORS.COLOR_DARK,
        fontSize:"14px",
    }
}))
function BoardOfDirectors(props) {
    const classes=useStyles();
    return (
        
        <ContainerPage>
            <H2 style={{textAlign:"center",paddingTop:"50px"}}>
                Board of Directors
            </H2>
                <ProfileContainer>
                <ProfileBox>
                <ProfileImage>
                <DirectorImg src={require("../../../assets/images/director1.jpg")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Justice K. Kannan</h4> <span className={classes.content}>Independent Director</span>
                </ProfileName>
                </ProfileBox> 
                
                <ProfileBox>
                <ProfileImage>
                <DirectorImg src={require("../../../assets/images/director2.5,cg_true")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>N L Rajah </h4><span className={classes.content}>Founder & Director</span> 
                </ProfileName>
                </ProfileBox>

                <ProfileBox>
                <ProfileImage>
                <DirectorImg src={require("../../../assets/images/director3.5,cg_true")} alt="Director"/>
                </ProfileImage>
                <ProfileName>
                <h4 className={classes.subheading}>Karthik Krishna</h4> <span className={classes.content}>Founder & Director</span>
                </ProfileName>
                </ProfileBox> 
                </ProfileContainer>  
                <PrimaryStyledButton style={{width:"100px", margin:"auto"}} onClick={()=> navigate("/directors")}> See more</PrimaryStyledButton>
                </ContainerPage>

    );
}

export default BoardOfDirectors;

const DirectorImg=styled.img`
height:200px;
border-radius:50%;
margin:auto;
`
const ProfileBox=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
width:350px;

@media(max-width:800px){
    flex-direction:column;
    text-align:center;
}
`
const ProfileImage=styled.div`
display:flex;
height:250px;
width:250px;
margin:auto;
align-items:center;
`
const ProfileName=styled.div`
display:flex;
flex-direction:column;
font-size:18px;
padding:20px;
text-align:center;
`
const ProfileContainer=styled.div`
display:flex;
flex-wrap:wrap;
justify-content:center;
`
const ContainerPage=styled.div`
display:flex;
flex-direction:column;

`