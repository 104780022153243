import { Tooltip } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import { BulkUploadSchema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import AWSService from "../../../services/AWSService";
import CaseService from "../../../services/CaseService";
import PartyService from "../../../services/PartyService";
import ActionBar from "../../common/ActionBar";
import { PrimaryCTAButton } from "../../common/Buttons";
import FormField from "../../common/FormField/FormField";
import { CustomFileUpload } from "../../common/FormInputs";
import { HyperLink } from "../../common/styled-components";
import _ from "lodash";
import BulkCaseTypeRadioButton from "../../pages/CasesBulkCreate/BulkCaseTypeRadioButton";
import SystemService from "../../../services/SystemService";

export default function CasesBulkCreate(caseType) {
  const resolutionType = caseType?.location?.search;
  const resolutionKind = resolutionType.split("=").pop();
  const [data, setData] = useState("");
  const breadcrumbs = [
    "My Cases",
    "Bulk Upload",
    _.capitalize(data.resolutionKind)
  ];
  const [menuItems, setMenuItems] = useState([]);
  const [success, setSuccess] = useState(false);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [systemConfig, setSystemConfig] = useState();

  const onBreadcrumbClick = selected => {
    switch (_.snakeCase(selected)) {
      case "bulk_upload":
        navigate(`/dashboard/cases?caseType=${data?.resolutionKind}`);
        break;
      case "my_cases":
        navigate(`/dashboard/cases?caseType=${data?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function getSystemConfig() {
      setLoader({ state: true, message: "Please wait. Loading..." });
      try {
        const response = await SystemService.getConfig();
        setSystemConfig(response.systemConfig);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getParties(params) {
      try {
        setLoader({ state: true });
        const response = await PartyService.getParties(
          "?perPage=50&status=active"
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id }) => ({ label: name, value: id }))
          );
        } else {
          enqueueSnackbar(
            "You need to create a party inorder to create a case",
            {
              variant: "error"
            }
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error"
        });
        // Handle Error
      } finally {
        setLoader({ state: false });
      }
    }
    getParties();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        const types = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv"
        ];
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        const errorMessage = "Invalid File Type";
        if (!types.includes(file?.type)) throw errorMessage;
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function onFormSubmit(values) {
    try {
      setLoader({
        state: true,
        message: `Uploading ${getFileName(values.url)}...`
      });
      const payload = { url: values.url };
      let urlKind = "bulkCaseUploadMediation";
      if (data.resolutionKind === "arbitration")
        urlKind = "bulkCaseUploadArbitration";
      const response = await CaseService.caseBulkCreate(
        payload,
        values.partyId,
        urlKind
      );
      if (response) {
        setSuccess(true);
        enqueueSnackbar("Cases uploaded successfully", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Container>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Formik
        initialValues={{
          partyId: "",
          url: "",
          resolutionKind: resolutionKind || "mediation"
        }}
        validationSchema={BulkUploadSchema}
        enableReinitialize
        onSubmit={onFormSubmit}
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
          setFieldError
        }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Heading>
              Bulk Case Upload - {_.capitalize(data.resolutionKind)}
              {success && " Success"}
            </Heading>
            {success ? (
              ""
            ) : (
              <BulkCaseTypeRadioButton
                values={values}
                handleChange={(e, type) => {
                  handleChange(e);
                }}
                resolutionKind={resolutionKind}
              />
            )}
            {setData(values)}
            <Subheading>
              {!success ? (
                <>
                  <div>
                    Bulk cases have to be uploaded in a specified template
                    format.
                  </div>
                  <div>
                    If you find it difficult to upload the file from your
                    mobile, please use a desktop/laptop to upload.
                  </div>
                  {data?.resolutionKind === "mediation" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={systemConfig?.bulkUploadMedTemplateUrl}
                    >
                      Please download the template.
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={systemConfig?.bulkUploadArbTemplateUrl}
                    >
                      Please download the template.
                    </a>
                  )}
                </>
              ) : (
                `You can see all the bulk uploaded cases under MyCases with status as Drafts.`
              )}
            </Subheading>
            {!success && (
              <>
                <Button>
                  <FormField
                    white
                    input={{
                      type: "select",
                      name: "partyId",
                      label: "SELECT A CLAIMANT PARTY",
                      required: true
                    }}
                    menuItems={menuItems}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                      showId: true
                    }}
                  />
                  <HyperLink
                    style={{
                      marginTop: 10,
                      float: "right",
                      width: "auto"
                    }}
                    onClick={() => navigate("/dashboard/parties/create")}
                  >
                    Setup a Party
                  </HyperLink>
                </Button>

                <StyledLabel
                  style={{ width: "auto" }}
                  tooltip={"Case template for doing bulk upload"}
                >
                  Bulk Case xls/CSV file
                  <span className="required-star"> *</span>
                </StyledLabel>
                <Button>
                  <CustomFileUpload
                    key={values.url}
                    error={touched.url && errors.url}
                    file={{ name: getFileName(values.url) }}
                    setFile={file =>
                      s3Upload(file, "url", setFieldValue, setFieldError)
                    }
                    accept=".xlsx"
                    style={{ width: "100%" }}
                  />
                </Button>
                <Button>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ marginTop: 25 }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </Button>
              </>
            )}
          </FormContainer>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
`;

const FormContainer = styled.form`
  padding: 20px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 53px;
  }
`;

const Button = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

const Heading = styled.div`
  font-size: 18px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.COLOR_DARK};
  /* text-transform: uppercase; */
`;

const Subheading = styled.div`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontBold};
  margin-top: 23px;
  color: ${COLORS.COLOR_DARK};
  margin-bottom: 20px;
  & div {
    margin-bottom: 20px;
  }
  & a {
    color: ${COLORS.BTN_GREEN};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Flex = styled.div`
  display: flex;
  margin-top: 45px;
  margin-bottom: 6px;
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 19px;
  }
`;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

const TooltipIcon = styled.img`
  width: 17px;
  height: 17px;
  object-fit: contain;
  cursor: pointer;
`;

const StyledLabel = ({ tooltip, spacing, style, ...props }) => (
  <Flex spacing={spacing} style={style}>
    <FormLabel {...props} />
    {tooltip && (
      <Tooltip title={tooltip} placement="top">
        <TooltipIcon src={require("../../../assets/images/toolTip.png")} />
      </Tooltip>
    )}
  </Flex>
);
