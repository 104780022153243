import React, { useEffect } from "react";
import useFreshChat from "../../../hooks/useFreshChat";
import useUser from "../../../hooks/useUser";


const FreshChat = () => {
  const { userObject } = useUser();
  const { setIsInitialised, setChatDetail } = useFreshChat({ userObject }, true);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialised(true);
      setChatDetail({});
      // if (_.isEmpty(userObject)) {
      //   handlePopup();
      // }
    }, 0);
  }, [userObject, setIsInitialised, setChatDetail]);

  return <div id="freshchat-js-sdk" />;
};

export default FreshChat;
