const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      verifications: "verifications",
      validate_mobile: "validate-mobile",
      validate_email: "validate-email",
      profile: "me",
      login: "sessions",
      loginWithGoole: "/sessions/google",
      otp_login: "otp-login",
      email: "resend-verify-email",
      forgot_password: "forgot-password",
      reset_password: "reset-password",
      change_password: "change-password",
    },
    party: {
      party: "parties",
      stats: "parties/stats",
      create: "parties/create",
      draft: "parties/update",
      uploads: "uploads/party",
    },
    case: {
      case: "cases",
      bulk: "bulkCaseUpload",
      stats: "stats",
      count: "stats",
      create: "cases/update",
      submit: "cases/create",
      invite_action: "invites",
      invites: "received-invites",
      sent_invites: "sent-invites",
      uploadCase: "uploads/case",
      rating: "ratings/case",
      respondent: "/cases/respondents",
      comments: "/comments",
      select: "/selects/case",
      draft: "/cases/drafts",
      selectDraft: "/selects/drafts",
      submitAllDraft: "/cases/submit-all-selected-drafts",
      notes: "/notes",
      validatePaymentCode:"/validatePaymentCode",
      docWriter: "/docWriter",
    },
    bundle: {
      customBundle: "/customBundle",
      bundleDocList: "/documentList"
    },
    dashboard: {
      pendingAction: "/activities",
    },
    cart: {
      index: "cart",
      capture: "payment-capture",
      orders: "/orders",
      order: "/cart/order",
    },
    payment: {
      payments: "/payments",
    },
    chat: {
      index: "/chats",
    },
    meeting: {
      meetings: "/meetingJoin",
      guestUserMeeting:"/guestUserMeeting",
      endMeeting:"/endMeeting",
      meetingRecordings: "/meetingRecordings",
    },
    user: {
      index: "casemanager/users",
    },
    meetingRequest: "meeting-requests",
    agent: "agents",
    s3: "s3-url",
    systemConfig: "system-configs",
    notificationCount: "/notification-count",
  },
};

export default ApiConfig;
