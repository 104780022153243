import React from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
  CaseManagerIcon,
} from "../styles";
import DrawerModal from "./DrawerModal/DrawerModal";
import { useState } from "react";

export default function CaseManager({
  title = "Case Manager",
  hideChat = false,
  disabled = false,
  handlePopup = () => {},
  caseManager,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>{title}</CaseManagerTitle>
        <Flex>
          <CaseManagerProfile
            src={
              caseManager?.avatarUrl
                ? caseManager?.avatarUrl
                : require("../../../../assets/images/userPlaceholder.svg")
            }
          />
          <CaseManagerName onClick={(e) => setModal(true)}>
            {caseManager?.name}
          </CaseManagerName>
        </Flex>
      </Column>
      {!hideChat && !caseManager?.disabled && (
        <CaseManagerIcon onClick={() => handlePopup()}>
          <img
            alt="chat"
            src={require("../../../../assets/images/chatIcon.svg")}
          />
        </CaseManagerIcon>
      )}
      <DrawerModal
        {...{
          modal,
          setModal,
        }}
        type={title}
        id={caseManager?.id}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
