import _ from "lodash";

/**
 * @description Function to set the inital value for the form
 * @param {*} caseObj
 * @param {*} menuItems
 * @param {*} searchParams
 */
export const setInitalFormValue = (caseObj, menuItems, searchParams) => {
  const initialState = {
    title: "",
    claimValue: ""
  };
  return {
    requestedMediators: setInitialMediator(caseObj),
    agreement: false,
    claimantPartyId: menuItems.length
      ? caseObj?.claimantPartyId
        ? caseObj.claimantPartyId
        : menuItems[0].value
      : "" || "",
    title: caseObj?.title || "",
    description: caseObj?.description || "",
    totalClaimValue: caseObj?.totalClaimValue || 0,
    resolutionKind:
      caseObj?.resolutionKind || searchParams.get("caseType") || "mediation",
    agreement_urls:
      caseObj?.agreement_urls && caseObj.agreement_urls.length
        ? setInitialAgreementUrl(caseObj.agreement_urls)
        : [dummyFileUpload],
    subscriptionKind: caseObj?.subscriptionKind || "normal",
    arbitrationNotice:
      caseObj.agreement_urls?.find(
        el => el?.uploadType === "Arbitration Notice"
      )?.url || "",
    claim_items_data: caseObj?.caseItems?.length
      ? _.map(caseObj?.caseItems, ci => ({
          ...ci,
          claimValue: parseFloat(ci.claimValue).toFixed(2)
        }))
      : [initialState] || [initialState]
  };
};

/**
 *@description Function to set the initial agremment url
 * @param {*} urls
 */
export const setInitialAgreementUrl = (urls = []) => {
  if (urls?.length) {
    const agrementUrl = urls
      ?.filter(el => el?.uploadType !== "Arbitration Notice")
      ?.map(url => {
        if (typeof url === "string") {
          return {
            uploadType: "",
            url: url || ""
          };
        } else {
          return {
            uploadType: url?.uploadType || "",
            url: url?.url || ""
          };
        }
      });
    return agrementUrl?.length ? agrementUrl : [dummyFileUpload];
  }
  return [dummyFileUpload];
};

/**
 * @description Function to set the initial mediator object
 * @param {*} caseObj
 */
export function setInitialMediator(caseObj) {
  if (caseObj?.requestedMediators?.length) {
    return caseObj?.requestedMediators?.map(el => {
      return {
        mediatorName: el?.name || "",
        mediatorEmail: el?.email || "",
        mediatorMobile: el?.mobile || ""
      };
    });
  } else {
    return [
      {
        mediatorName: caseObj?.requestedMediator?.name || "",
        mediatorEmail: caseObj?.requestedMediator?.email || "",
        mediatorMobile: caseObj?.requestedMediator?.mobile || ""
      }
    ];
  }
}

/**
 * @description Function to set the dummy file upload object
 * @param {*} values
 */
export const dummyFileUpload = {
  uploadType: "",
  url: ""
};

/**
 * @description Function to set the post payload
 * @param {*} payload
 */
export const setPostPayload = payload => {
  //Total claim value
  payload.totalClaimValue = Number(
    payload.totalClaimValue
      ?.toString()
      ?.split(",")
      ?.join("")
  );

  //Set agreement url
  payload.agreement_urls = payload.agreement_urls
    ?.filter(el => el?.url)
    ?.map(el => {
      return {
        uploadType:
          payload?.resolutionKind === "arbitration" ? el?.uploadType : "",
        url: el?.url
      };
    });

  if (payload?.resolutionKind === "arbitration" && payload?.arbitrationNotice) {
    payload.agreement_urls.push({
      uploadType: "Arbitration Notice",
      url: payload?.arbitrationNotice
    });
  }

  // set the requested mediator object
  if (
    payload.requestedMediators?.length &&
    payload.requestedMediators[0]?.mediatorName
  ) {
    payload.requestedMediators = payload?.requestedMediators?.map(mediator => {
      return {
        name: mediator?.mediatorName,
        mobile: mediator?.mediatorMobile,
        email: mediator?.mediatorEmail?.toString().toLowerCase()
      };
    });
  } else {
    payload.requestedMediators = [];
  }
  return { ...payload };
};
