import { CompanyLogo } from "../../../styles/component/style";
import { Images } from "../../../assets/images";
import {
  LogoContainer,
  LinksContainer,
  ProfileImage,
  ProfileName,
  LogoutText,
  BigScreen,
  SmallScreenMenu,
  SmallScreen,
  Row,
  Count,
  HelpIcon,
  LogoutIcon,
  CartIcon,
  H2,
  MobileScreen,
  IconHolder,
} from "./Styles";
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import COLORS from "../../../assets/Colors";
import useUser from "../../../hooks/useUser";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import { PrimaryOutlinedCTAButton } from "../../common/Buttons";
import useCartCount from "../../../hooks/useCartCount";
import labels from "../../../helpers/labels.json";
import useAlert from "../../../hooks/useAlert";
import { scroller } from "react-scroll";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import DropDownMenu from "../../common/DropDownMenu";
import TemporaryDrawer from "./Drawer";
import Notification from "../Notification";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Button, Drawer } from "@material-ui/core";
import MenuModel from "./MenuModel";
import useSettings from "../../../hooks/useSettings";
import useFreshChat from "../../../hooks/useFreshChat";

const EXCLUDE_URL = ["create-profile"];

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 74,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #e1e3ee",
  },
  appSpacedBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: COLORS.PRIMARY_WHITE,
    height: 74,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    borderBottom: "1px solid #e1e3ee",
    marginTop: 40,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

export default function StyledAppBar({
  NavPages,
  hideLinks,
  LandingPage,
  ArbitPages,
  cartCount,
  setShowDrawer,
  stats,
  hideIcon,
}) {
  const classes = useStyles();
  const { userObject, setToken } = useUser();
  const { setCartCount } = useCartCount();
  const { showAlert, hideAlert } = useAlert();
  const { translaterOpen } = useContext(TranslaterContext);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const { setting, settingsLoading } = useSettings();
  const [notificationCount, setNotificationCount] = useState();
  const { setTag } = useFreshChat({ userObject }, true);

  const handleLogout = () => {
    showAlert({
      heading: "Logout?",
      desc: "Are you sure you want to logout?",
      primaryBtnText: "Logout",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        setToken("logged_out");
        setTag(["home", "sign_up"]);
        setCartCount(0);
        hideAlert();
      },
      isOpen: true,
      onDialogClose: () => hideAlert(),
    });
  };

  return (
    <AppBar
      position="fixed"
      style={{ boxShadow: "5px 5px 8px #00000011" }}
      className={translaterOpen ? classes.appSpacedBar : classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        {_.isEmpty(userObject) || !userObject?.emailVerified
          ? ""
          : !hideIcon && (
              <SmallScreen>
                <IconButton
                  edge="start"
                  color="black"
                  aria-label="menu"
                  onClick={() => setShowDrawer(true)}
                >
                  <MenuIcon style={{ color: `${COLORS.COLOR_DARK}` }} />
                </IconButton>
              </SmallScreen>
            )}
        <LogoContainer>
          {settingsLoading ? null : (
            <CompanyLogo
              onClick={() =>
                LandingPage
                  ? scroller.scrollTo("hero", {
                      duration: 500,
                      offset: -70,
                      smooth: true,
                    })
                  : _.isEmpty(userObject) || !userObject?.emailVerified
                  ? navigate("/")
                  : navigate("/dashboard")
              }
              src={setting?.logoUrl || Images.companyLogo}
            />
          )}
        </LogoContainer>

        <BigScreen>
          {hideLinks ? null : (
            <LinksContainer>
              {_.isEmpty(userObject) ||
              !userObject?.emailVerified ||
              EXCLUDE_URL.some((path) => location?.pathname?.includes(path)) ? (
                <>
                  {NavPages ? (
                    <>
                      {/* <H2>
                        <DropDownMenu
                          title={labels["appbar.resources"]}
                          options={[
                            {
                              name: "Blog",
                              link: "/resources/60",
                            },
                            {
                              name: "Webinar",
                              link: "/resources/80",
                            },
                            {
                              name: "Model Clauses",
                              link: "/resources/100",
                            },
                          ]}
                        />
                      </H2> */}

                      <H2>
                        <DropDownMenu
                          title={labels["appbar.neutral"]}
                          options={[
                            // {
                            //   name: "Arbitrators",
                            //   link: "/neutral/arbitrators",
                            // },
                            // {
                            //   name: "Mediators",
                            //   link: "/neutral/mediators",
                            // },
                            {
                              name: "Login as Neutral",
                              link: process.env.REACT_APP_NEUTRAL_BASE_URL,
                            },
                          ]}
                        />
                      </H2>

                      {/* <H2>
                        <DropDownMenu
                          title={labels["appbar.training"]}
                          options={[
                            {
                              name: "Corporate Short Programme",
                              link: "/training/20",
                            },
                            {
                              name: "Mediation Training Programme",
                              link: "/training/40",
                            },
                            {
                              name: "World of Mediation",
                              link: "/training/60",
                            },
                          ]}
                        />
                      </H2> */}
                      <H2>
                        <DropDownMenu
                          title={labels["appbar.about_us"]}
                          options={[
                            // {
                            //   name: "About JustAct",
                            //   link: "/aboutus#about-justact",
                            //   section: "about-justact",
                            // },
                            // {
                            //   name: "Our Advantages",
                            //   link: "/aboutus#our-advantages",
                            //   section: "our-advantages",
                            // },
                            // {
                            //   name: "How JustAct works",
                            //   link: "/aboutus#how-it-works",
                            //   section: "how-it-works",
                            // },
                            // {
                            //   name: "Our Values",
                            //   link: "/aboutus#our-values",
                            //   section: "our-values",
                            // },
                            // {
                            //   name: "Founders",
                            //   link: "/aboutus#founders",
                            //   section: "founders",
                            // },
                            // {
                            //   name: "Board of Directors",
                            //   link: "/aboutus#board-of-directors",
                            //   section: "board-of-directors",
                            // },
                            // {
                            //   name: "Management Team",
                            //   link: "/aboutus#management-team",
                            //   section: "management-team",
                            // },
                            // {
                            //   name: "Our Partners",
                            //   link: "/aboutus#partnerships",
                            //   section: "partnerships",
                            // },
                            {
                              name: "Terms And Conditions",
                              link: "/terms-and-conditions",
                            },
                            {
                              name: "Privacy Policy",
                              link: "/privacy-policy",
                            },
                          ]}
                        />
                      </H2>
                    </>
                  ) : (
                    <>
                      {/* <H2>
                        <DropDownMenu
                          title={labels["appbar.resources"]}
                          options={[
                            {
                              name: "Blog",
                              link: "/resources/60",
                            },
                            {
                              name: "Webinar",
                              link: "/resources/80",
                            },
                            {
                              name: "Model Clauses",
                              link: "/resources/100",
                            },
                          ]}
                        />
                      </H2> */}

                      <H2>
                        <DropDownMenu
                          title={labels["appbar.neutral"]}
                          options={[
                            // {
                            //   name: "Arbitrators",
                            //   link: "/neutral/arbitrators",
                            // },
                            // {
                            //   name: "Mediators",
                            //   link: "/neutral/mediators",
                            // },
                            {
                              name: "Login as Neutral",
                              link: process.env.REACT_APP_NEUTRAL_BASE_URL,
                            },
                          ]}
                        />
                      </H2>
                      {/* <Link>
                        <H2>
                          <DropDownMenu
                            title={labels["appbar.training"]}
                            options={[
                              {
                                name: "Corporate Short Programme",
                                link: "/training/20",
                              },
                              {
                                name: "Mediation Training Programme",
                                link: "/training/40",
                              },
                              {
                                name: "World of Mediation",
                                link: "/training/60",
                              },
                            ]}
                          />
                        </H2>
                      </Link> */}
                      <H2>
                        <DropDownMenu
                          title={labels["appbar.about_us"]}
                          options={[
                            // {
                            //   name: "About JustAct",
                            //   link: "/aboutus#about-justact",
                            //   section: "about-justact",
                            // },
                            // {
                            //   name: "Our Advantages",
                            //   link: "/aboutus#our-advantages",
                            //   section: "our-advantages",
                            // },
                            // {
                            //   name: "How JustAct works",
                            //   link: "/aboutus#how-it-works",
                            //   section: "how-it-works",
                            // },
                            // {
                            //   name: "Our Values",
                            //   link: "/aboutus#our-values",
                            //   section: "our-values",
                            // },
                            // {
                            //   name: "Founders",
                            //   link: "/aboutus#founders",
                            //   section: "founders",
                            // },
                            // {
                            //   name: "Board of Directors",
                            //   link: "/aboutus#board-of-directors",
                            //   section: "board-of-directors",
                            // },
                            // {
                            //   name: "Management Team",
                            //   link: "/aboutus#management-team",
                            //   section: "management-team",
                            // },
                            // {
                            //   name: "Our Partners",
                            //   link: "/aboutus#partnerships",
                            //   section: "partnerships",
                            // },
                            {
                              name: "Terms And Conditions",
                              link: "/terms-and-conditions",
                            },
                            {
                              name: "Privacy Policy",
                              link: "/privacy-policy",
                            },
                          ]}
                        />
                      </H2>
                    </>
                  )}
                  <PrimaryOutlinedCTAButton
                    style={{
                      marginRight: 38,
                      height: 39,
                      width: 124,
                      padding: "11px 38px",
                    }}
                    onClick={() => navigate("/signup")}
                  >
                    {labels["appbar.signup"]}
                  </PrimaryOutlinedCTAButton>
                  <PrimaryOutlinedCTAButton
                    style={{
                      marginRight: 38,
                      height: 39,
                      width: 124,
                      padding: "11px 38px",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    {labels["appbar.login"]}
                  </PrimaryOutlinedCTAButton>
                </>
              ) : (
                <>
                  <div style={{ marginRight: 30 }}>
                    <Notification
                      count={notificationCount}
                      setCount={setNotificationCount}
                    />
                  </div>
                  <Row onClick={() => navigate("/dashboard/cart")}>
                    <CartIcon
                      src={require("../../../assets/images/cartIcon.svg")}
                    />
                    <ProfileName style={{ marginRight: 11 }}>
                      {labels["appbar.case_cart"]}
                    </ProfileName>
                    {cartCount ? <Count>{cartCount}</Count> : null}
                  </Row>
                  <Row onClick={() => navigate("/dashboard/help")}>
                    <HelpIcon
                      src={require("../../../assets/images/HelpHeader.svg")}
                    />
                    <ProfileName>{labels["appbar.help"]}</ProfileName>
                  </Row>
                  <Row onClick={handleLogout}>
                    <LogoutIcon
                      src={require("../../../assets/images/logout.svg")}
                    />
                    <LogoutText>{labels["appbar.logout"]}</LogoutText>
                  </Row>
                  <ProfileImage
                    src={
                      userObject.avatarUrl
                        ? userObject.avatarUrl
                        : require("../../../assets/images/userPlaceholder.svg")
                    }
                    onClick={() => navigate("/dashboard/profile")}
                  />
                  <ProfileName onClick={() => navigate("/dashboard/profile")}>
                    {userObject.name}
                  </ProfileName>
                  •
                </>
              )}
            </LinksContainer>
          )}
        </BigScreen>

        <SmallScreenMenu>
          {_.isEmpty(userObject) || !userObject?.emailVerified ? (
            <>
              <TemporaryDrawer />
            </>
          ) : (
            <SmallScreen>
              <Row>
                <div style={{ marginRight: 15 }}>
                  <Notification
                    count={notificationCount}
                    setCount={setNotificationCount}
                  />
                </div>
                <Row
                  onClick={() => navigate("/dashboard/cart")}
                  style={{ marginRight: 15 }}
                >
                  <CartIcon
                    src={require("../../../assets/images/cartIcon.svg")}
                  />
                  {cartCount ? <Count>{cartCount}</Count> : null}
                </Row>
                <div
                  style={{ margin: "0px 20px", cursor: "pointer" }}
                  onClick={() => navigate("/dashboard/help")}
                >
                  <img
                    style={{ width: 18, height: 18, display: "flex" }}
                    alt="help-icon"
                    src={require("../../../assets/images/HelpHeader.svg")}
                  />
                </div>
                <LogoutText onClick={handleLogout}>
                  {labels["appbar.logout"]}
                </LogoutText>
                <ProfileImage
                  src={
                    userObject.avatarUrl
                      ? userObject.avatarUrl
                      : require("../../../assets/images/userPlaceholder.svg")
                  }
                  onClick={() => navigate("/dashboard/profile")}
                />
              </Row>
            </SmallScreen>
          )}
        </SmallScreenMenu>

        {!_.isEmpty(userObject) || userObject?.emailVerified ? (
          <MobileScreen>
            <Row>
              <Row onClick={() => navigate("/dashboard/cart")}>
                <CartIcon
                  src={require("../../../assets/images/cartIcon.svg")}
                />
                {cartCount ? <Count>{cartCount}</Count> : null}
              </Row>
              <IconHolder
                style={{ marginLeft: 15, position: "relative" }}
                onClick={() => navigate("/dashboard/notification")}
              >
                <NotificationsIcon className="icon" />
                {notificationCount ? <span className="badge"></span> : null}
              </IconHolder>
              <Button
                onClick={() => {
                  setModal(!modal);
                }}
              >
                {modal ? <CloseIcon /> : <MenuIcon />}
              </Button>
            </Row>
            <Drawer
              anchor={"top"}
              open={modal}
              onClose={() => setModal(false)}
              style={{ zIndex: "1" }}
            >
              <MenuModel
                {...{
                  modal,
                  setModal,
                  NavPages,
                  hideLinks,
                  LandingPage,
                  cartCount,
                  userObject,
                  EXCLUDE_URL,
                  handleLogout,
                  stats,
                }}
              />
            </Drawer>
          </MobileScreen>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
