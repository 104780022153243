import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../../common/Drawer/AgentListDrawerModal";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles, Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import _ from "lodash";

const Index = ({ opendrawer, setOpenDrawer, MTRef, partyList, displayAuthUrl }) => {
  return (
    <Drawer state={opendrawer} setState={setOpenDrawer} label="Drawer">
      <Table {...{ setOpenDrawer, MTRef, displayAuthUrl }} data={partyList} />
    </Drawer>
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({ setOpenDrawer, MTRef, displayAuthUrl, data }) => {
  return (
    <div>
      <Header>
        <Heading style={{ marginBottom: "unset" }}>Agent Details</Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => setOpenDrawer(false)}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        {data.map((item, index) => (
          <TableWithAccordion
            key={index}
            title={item?.partyRole ? `${item?.party?.name}'s Agent details (${_.capitalize(
              item?.partyRole
            )})` : `${item?.party?.name}'s Agent details`}
            {...{ MTRef, item, displayAuthUrl }}
          />
        ))}
      </AccordionContainer>
    </div>
  );
};

function TableWithAccordion({ MTRef, title, item, displayAuthUrl }) {
  const classes = useStyles();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(780));

  const columns = [
    {
      field: "user.name",
      title: "Agent Name",
      sorting: false,
      render: (rowData) => <p>{rowData.user?.name}</p>,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      render: (rowData) => (
        <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      ),
    },
    {
      field: "user.mobile",
      title: "Phone",
      sorting: false,
      render: (rowData) => <p>{rowData.user?.mobile}</p>,
    },
  ];
  if (displayAuthUrl) {
    columns.push({
      field: "user",
      title: "Authorization Letter",
      sorting: false,
      render: (rowData) => (
        rowData.authorizationLetterUrl ?
          <Tooltip
            title={rowData?.authorizationLetterUrl?.split("/")[rowData?.authorizationLetterUrl?.split("/").length - 1]}
            placement="top"
          >
            <HyperLink
              onClick={() => window.open(rowData.authorizationLetterUrl)}
              style={{
                maxWidth: isBigScreen ? "150px" : "auto",
              }}
            >
              {rowData?.authorizationLetterUrl?.split("/").length > 0
                ? decodeURI(
                  rowData?.authorizationLetterUrl?.split("/")[rowData?.authorizationLetterUrl?.split("/").length - 1]
                )
                : ""}
            </HyperLink>
          </Tooltip> : <p>-</p>

      ),
    })
  }

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading style={{ marginLeft: 24 }}>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {item?.party?.agents?.length ? (
            <CustomTable
              pluralTitle="Agents"
              singularTitle="Agent"
              {...{
                columns,
                MTRef,
              }}
              state={{
                data: item?.party?.agents,
              }}
              data={item?.party?.agents}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No Agents Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;
