import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paperAnchorRight": {
      zIndex: 2001,
      boxShadow: "1px 0px 20px 0px rgba(0,0,0,0.2)",
    },
  },
  list: {
    width: "100vw",
    [theme.breakpoints.up(640)]: {
      width: "800px",
    },
  },
}));

const CustomDrawer = ({
  state,
  setState,
  children = "drawer content",
  drawerVariant = "temporary",
}) => {
  const classes = useStyles();

  return (
    <div>
      <React.Fragment>
        <Drawer
          className={classes.root}
          anchor={"right"}
          variant={drawerVariant}
          open={state}
          onClose={() => setState(false)}
        >
          <div className={classes.list} role="presentation">
            {children}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default CustomDrawer;
